<template>
  <form v-on="$listeners" v-bind="$attrs">
    <slot :disabled="hasError"></slot>
  </form>
</template>

<script>
  import Vue from 'vue'

  export default Vue.extend({
    data() {
      return {
        inputs: {},
        hasError: false,
      }
    },
    provide() {
      return {
        registerInput: this.registerInput,
        unregisterInput: this.unregisterInput,
      }
    },
    methods: {
      registerInput(vm) {
        this.inputs[vm._uid] = vm
        vm.form = this
      },
      unregisterInput(vm) {
        delete this.inputs[vm._uid]
        vm.form = null
      },
      handleSubmit(event) {
        if (this.hasError) {
          event.preventDefault()
        } else {
          this.$emit('submit', event)
        }
      },
      updateError() {
        this.hasError = Object.values(this.inputs).some((input) => input.error)
      },
    },
    beforeDestroy() {
      Object.values(this.inputs).forEach((input) => {
        this.unregisterInput(input)
      })
    },
  })
</script>
