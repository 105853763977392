import { mainnet, decimals } from '../../constants'

const tokens = mainnet.TOKENS
const pendle = mainnet.PENDLE
const expiry = mainnet.EXPIRY

export default [
  {
    address: tokens.YT_AUSDC_30_DEC_2021,
    decimals: decimals.DECIMALS_6,
    name: 'YT-aUSDC-30Dec2021',
    symbol: 'YT-aUSDC',
    logo: '/images/coins/mainnet/yt-ausdc.png',
    network: 'mainnet',
    genericName: 'YT',
    protocol: 'Aave-v2',
    expiry: expiry.TIME_30_DEC_2021,
    yieldRepresentation: {
      amount: '1',
      unit: 'USDC',
      protocol: 'Aave',
    },
    tokenCategories: ['yt'],
    ignoreApproval: [pendle.ROUTER],
  },
  {
    address: tokens.YT_AUSDC_29_DEC_2022,
    decimals: decimals.DECIMALS_6,
    name: 'YT-aUSDC-29Dec2022',
    symbol: 'YT-aUSDC',
    logo: '/images/coins/mainnet/yt-ausdc.png',
    network: 'mainnet',
    genericName: 'YT',
    protocol: 'Aave-v2',
    expiry: expiry.TIME_29_DEC_2022,
    yieldRepresentation: {
      amount: '1',
      unit: 'USDC',
      protocol: 'Aave',
    },
    tokenCategories: ['yt'],
    ignoreApproval: [pendle.ROUTER],
  },
  {
    address: tokens.YT_CDAI_30_DEC_2021,
    decimals: decimals.DECIMALS_8,
    name: 'YT-cDAI-30Dec2021',
    symbol: 'YT-cDAI',
    logo: '/images/coins/mainnet/yt-cdai.png',
    network: 'mainnet',
    genericName: 'YT',
    protocol: 'Compound',
    expiry: expiry.TIME_30_DEC_2021,
    yieldRepresentation: {
      amount: '0.0214751',
      unit: 'DAI',
      protocol: 'Compound',
    },
    tokenCategories: ['yt'],
    ignoreApproval: [pendle.ROUTER],
  },
  {
    address: tokens.YT_CDAI_29_DEC_2022,
    decimals: decimals.DECIMALS_8,
    name: 'YT-cDAI-29Dec2022',
    symbol: 'YT-cDAI',
    logo: '/images/coins/mainnet/yt-cdai.png',
    network: 'mainnet',
    genericName: 'YT',
    protocol: 'Compound',
    expiry: expiry.TIME_29_DEC_2022,
    yieldRepresentation: {
      amount: '0.0214751',
      unit: 'DAI',
      protocol: 'Compound',
    },
    tokenCategories: ['yt'],
    ignoreApproval: [pendle.ROUTER],
  },
  {
    address: tokens.YT_SLP_PENDLE_WETH_29_DEC_2022,
    decimals: decimals.DECIMALS_18,
    name: 'YT-SLP(PENDLE/ETH)-29Dec2022',
    symbol: 'YT-PE',
    logo: '/images/coins/mainnet/yt-pe.png',
    network: 'mainnet',
    genericName: 'YT',
    protocol: 'Sushiswap',
    expiry: expiry.TIME_29_DEC_2022,
    yieldRepresentation: {
      amount: '1',
      unit: 'Liquidity[PENDLE/ETH]',
      protocol: 'SushiSwap',
    },
    tokenCategories: ['yt'],
    ignoreApproval: [pendle.ROUTER],
  },
  {
    address: tokens.YT_SLP_USDC_WETH_29_DEC_2022,
    decimals: decimals.DECIMALS_18,
    name: 'YT-SLP(ETH/USDC)-29Dec2022',
    symbol: 'YT-ETHUSDC',
    logo: '/images/coins/mainnet/yt-ethusdc.png',
    network: 'mainnet',
    genericName: 'YT',
    protocol: 'Sushiswap',
    expiry: expiry.TIME_29_DEC_2022,
    yieldRepresentation: {
      amount: '1',
      unit: 'Liquidity[ETH/USDC]',
      protocol: 'SushiSwap',
    },
    tokenCategories: ['yt'],
    ignoreApproval: [pendle.ROUTER],
  },
  {
    address: tokens.YT_WXBTRFLY_21_APR_2022,
    decimals: decimals.DECIMALS_9,
    name: 'YT-wxBTRFLY-21APR2022',
    symbol: 'YT-wxBTRFLY',
    logo: '/images/coins/mainnet/yt-wxbtrfly.png',
    network: 'mainnet',
    genericName: 'YT',
    protocol: 'Redacted Cartel',
    expiry: expiry.TIME_21_APR_2022,
    yieldRepresentation: {
      amount: '1',
      unit: 'BTRFLY',
      protocol: 'Redacted Cartel',
    },
    tokenCategories: ['yt'],
    ignoreApproval: [pendle.ROUTER],
  },
]
