export default class Wallet {
  static className = 'Wallet'

  get className() {
    return 'Wallet'
  }

  constructor({ address }) {
    this.address = address
  }
}
