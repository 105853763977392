import Token from './Token'
import YTCollection from '../collections/YTCollection'

export default class YT extends Token {
  static query() {
    return new YTCollection(
      ...Token.query().where('tokenCategories', (val) => (val || []).includes('yt'))
    )
  }
}
