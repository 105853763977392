<template>
  <transition
    v-on="$listeners"
    enter-active-class="transition ease-out duration-75 transform"
    leave-active-class="transition ease-out duration-100 transform"
    :enter-class="enterClass"
    enter-to-class="opacity-100"
    leave-class="opacity-100"
    :leave-to-class="leaveToClass"
  >
    <slot></slot>
  </transition>
</template>

<script>
  const DirectionMap = {
    up: 'translate-y-3',
    down: '-translate-y-3',
    left: 'translate-x-3',
    right: '-translate-x-3',
  }

  import Vue from 'vue'
  export default Vue.extend({
    props: {
      direction: {
        type: String,
        default: 'down',
        validator: (value) => Object.keys(DirectionMap).indexOf(value) !== -1,
      },
    },
    computed: {
      enterClass() {
        return ['opacity-0', DirectionMap[this.direction]].join(' ')
      },
      leaveToClass() {
        return ['opacity-0', DirectionMap[this.direction]].join(' ')
      },
    },
  })
</script>
