<template>
  <pp-card class="flex-row items-stretch overflow-hidden rounded-md">
    <div v-if="color" :class="`bg-${color}-500`" class="w-2"></div>

    <pp-card-body class="flex flex-wrap flex-grow items-center">
      <div :class="`text-${color || 'water'}-800`">
        <slot></slot>
      </div>

      <div class="flex-grow ml-2"></div>

      <slot name="actions"></slot>
    </pp-card-body>
  </pp-card>
</template>

<script>
  import Vue from 'vue'

  export default Vue.extend({
    props: {
      color: { type: String },
    },
  })
</script>
