<template>
  <transition
    v-on="$listeners"
    :enter-active-class="`transition ease-in-out duration-150 transform ${transformOrigin}`"
    :leave-active-class="
      hideOnLeave ? 'hidden' : `transition ease-in-out duration-150 transform ${transformOrigin}`
    "
    enter-class="scale-0"
    enter-to-class="opacity-100 scale-100"
    leave-class="opacity-100 scale-100"
    leave-to-class="scale-0"
  >
    <slot></slot>
  </transition>
</template>

<script>
  const OriginMap = {
    center: 'origin-center',
    top: 'origin-top',
    bottom: 'origin-bottom',
    left: 'origin-left',
    right: 'origin-right',
  }

  import Vue from 'vue'
  export default Vue.extend({
    props: {
      origin: {
        type: String,
        default: 'center',
        validator: (value) => Object.keys(OriginMap).indexOf(value) !== -1,
      },
      hideOnLeave: { type: Boolean, default: false },
    },
    computed: {
      transformOrigin() {
        return OriginMap[this.origin]
      },
    },
  })
</script>
