import { avalanche } from '../../constants'

const pools = avalanche.POOLS
const tokens = avalanche.TOKENS
const expiry = avalanche.EXPIRY
const pendle = avalanche.PENDLE

export default [
  {
    address: pools.YT_JLP_WAVAX_PENDLE_28_DEC_2023_X_PENDLE,
    pair: [tokens.YT_JLP_WAVAX_PENDLE_28_DEC_2023, tokens.PENDLE],
    created: 1636540995, // 10 Nov 2021
    expires: expiry.TIME_28_DEC_2023,
    tradablePlatforms: ['pendle'],
    network: 'avalanche',
    spenderAddress: pendle.ROUTER,
  },
  {
    address: pools.YT_QIUSDC_28_DEC_2023_X_USDC,
    pair: [tokens.YT_QIUSDC_28_DEC_2023, tokens.USDC],
    created: 1636465032, // 9 Nov 2021
    expires: expiry.TIME_28_DEC_2023,
    tradablePlatforms: ['pendle'],
    network: 'avalanche',
    spenderAddress: pendle.ROUTER,
  },
  {
    address: pools.YT_QIAVAX_28_DEC_2023_X_USDC,
    pair: [tokens.YT_QIAVAX_28_DEC_2023, tokens.USDC],
    created: 1636465047, // 9 Nov 2021
    expires: expiry.TIME_28_DEC_2023,
    tradablePlatforms: ['pendle'],
    network: 'avalanche',
    spenderAddress: pendle.ROUTER,
  },
  {
    address: pools.YT_XJOE_30_JUN_2022_X_USDC,
    pair: [tokens.YT_XJOE_30_JUN_2022, tokens.USDC],
    created: 1636540772, // 10 Nov 2021
    expires: expiry.TIME_30_JUN_2022,
    tradablePlatforms: ['pendle'],
    network: 'avalanche',
    spenderAddress: pendle.ROUTER,
  },
  {
    address: pools.YT_WMEMO_24_FEB_2022_X_MIM,
    pair: [tokens.YT_WMEMO_24_FEB_2022, tokens.MIM],
    created: 1639478788, // 14 Dec 2021
    expires: expiry.TIME_24_FEB_2022,
    tradablePlatforms: ['pendle'],
    network: 'avalanche',
    spenderAddress: pendle.ROUTER,
  },
]
