export default {
  name: 'Ethereum',
  icon: require('@/assets/images/coins/ETH.png'),
  VUE_APP_ALLOWED_NETWORK_ID: 1,
  VUE_APP_BLOCK_NATIVE: '67bf8c35-a57b-4313-aab7-faa573f552d3',
  VUE_APP_RPC_NODE_1: 'https://mainnet.infura.io/v3/b8a2078a3a02431089ab90a83db62144',
  VUE_APP_RPC_NODE_2: 'https://mainnet.infura.io/v3/b8a2078a3a02431089ab90a83db62144',
  VUE_APP_RPC_NODE_3: 'https://mainnet.infura.io/v3/b8a2078a3a02431089ab90a83db62144',
  VUE_APP_NETWORK_ID: 1,
  VUE_APP_SUBGRAPH_URL: 'https://api.thegraph.com/subgraphs/name/ngfam/pendle',
  VUE_APP_SUBGRAPH_2_URL: 'https://api.thegraph.com/subgraphs/name/ngfam/pendle',
  VUE_APP_SUSHI_SUBGRAPH_URL: 'https://api.thegraph.com/subgraphs/name/sushiswap/exchange',
  VUE_APP_NETWORK_ENV: 'mainnet',
  VUE_APP_ENV: 'production',
}
