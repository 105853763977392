<template>
  <div class="flex flex-row justify-between">
    <div class="inline-flex items-center">
      <slot name="image">
        <img ref="tokenImage" class="h-8 rounded-full" :alt="token.name" :src="token.img" />
      </slot>

      <div class="ml-2">
        <div class="font-semibold" v-text="token.symbol" data-test="symbol"></div>
      </div>
    </div>

    <div>
      <pp-skeleton-loader v-if="balanceState.is('loading')" class="w-12 h-4"></pp-skeleton-loader>
      <pp-formatted-number
        v-if="balanceState.is('resolved')"
        :value="balance"
        class="text-sm"
        :min-decimal="2"
      ></pp-formatted-number>
    </div>
  </div>
</template>

<script>
  import PromiseHandler, { createState } from '@/domains/PromiseHandler'
  import TokenAmount from '@/domains/entities/TokenAmount'
  import fallbackImg from '@/assets/images/unknown.png'

  export default {
    props: {
      token: { type: Object, required: true },
    },
    data() {
      return {
        balanceState: createState({
          response: new TokenAmount(this.$props.token, 0),
        }),
      }
    },
    computed: {
      fallbackImg() {
        return fallbackImg
      },
      balance() {
        return this.balanceState.response.formattedAmount()
      },
    },
    methods: {
      fetchTokenBalance() {
        const { signer, provider, address } = this.$store.getters['wallet/user']
        new PromiseHandler(
          () => this.token.contract({ signer, provider }).balanceOf(address),
          this.balanceState
        ).execute()
      },
    },
    async created() {
      this.fetchTokenBalance()
    },
    mounted() {
      const tokenImage = this.$refs.tokenImage
      tokenImage.onerror = () => {
        tokenImage.src = require('@/assets/images/unknown.png')
      }
    },
  }
</script>
