import { TokenAmount } from '@pendle/sdk'
import { sdkChainId } from '@/app-config/network'

export default class TokenCollectionContract {
  constructor({ tokenCollection, provider, signer }) {
    this.tokenCollection = tokenCollection
    this.provider = provider
    this.signer = signer
  }

  balancesOf(walletAddress) {
    return TokenAmount.methods({
      signer: this.signer,
      provider: this.provider,
      chainId: sdkChainId,
    }).balancesOf({
      tokens: this.tokenCollection.toArray(),
      user: walletAddress,
    })
  }
}
