<template>
  <tr>
    <pp-td colspan="100" class="border-t-0">
      <slot></slot>
    </pp-td>
  </tr>
</template>

<script>
  import Vue from 'vue'

  export default Vue.extend({})
</script>
