import TokenIssuerContract from '@/domains/contracts/TokenIssuerContract'
import network from '@/app-config/network'
import TokenIssuerCollection from '@/domains/collections/TokenIssuerCollection'
import Token from './Token'
import OT from './OT'
import YT from './YT'

export default class TokenIssuer {
  constructor({
    address,
    forgeId,
    expiryEpoch,
    yieldBearingTokenAddress,
    underlyingTokenAddress,
    network,
    otAddress,
    ytAddress,
  }) {
    this.forgeId = forgeId
    this.expiryEpoch = expiryEpoch
    this.yieldBearingTokenAddress = yieldBearingTokenAddress
    this.underlyingTokenAddress = underlyingTokenAddress
    this.network = network
    this.otAddress = otAddress
    this.ytAddress = ytAddress
    this.address = address
  }

  get id() {
    return `${this.yieldBearingTokenAddress}-${this.expiryEpoch}`
  }

  get ot() {
    return OT.query().find(this.otAddress)
  }

  get yt() {
    return YT.query().find(this.ytAddress)
  }

  get chainId() {
    return network[this.network]
  }

  get expiresAt() {
    return new Date(this.expiryEpoch * 1000)
  }

  get yieldBearingToken() {
    return Token.query().find(this.yieldBearingTokenAddress)
  }

  get underlyingToken() {
    return Token.query().find(this.underlyingTokenAddress)
  }

  static query() {
    return TokenIssuerCollection.all()
  }

  contract({ signer, provider }) {
    return new TokenIssuerContract({ signer, provider, tokenIssuer: this })
  }
}
