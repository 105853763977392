<template>
  <div>
    <div class="max-w-[32rem] mx-auto">
      <div class="relative" style="padding-top: 100%">
        <div
          class="
            absolute
            inset-0
            h-full
            w-full
            text-center
            flex
            items-center
            justify-center
            p-5
            rounded-full
            bg-p-blue-100
            overflow-hidden
          "
        >
          <div>
            <h1 v-if="!hide404" class="text-6xl font-bold">404</h1>
            <div class="mt-8">
              <slot>
                <p>The content you are looking for does not exist.</p>
              </slot>

              <img class="mt-4 max-w-64 mx-auto" :src="require('@/assets/images/404.png')" alt="" />
            </div>
          </div>
        </div>
      </div>

      <div class="text-center mt-8" v-if="!hideLink">
        <pp-btn tag="router-link" to="/" variant="text">Back to Home</pp-btn>
      </div>
    </div>
  </div>
</template>

<script>
  import Vue from 'vue'

  export default Vue.extend({
    props: {
      hide404: { type: Boolean, default: false },
      hideLink: { type: Boolean, default: false },
    },
  })
</script>
