<template>
  <tr>
    <td class="font-mono text-p-blue-700">
      <b v-text="name"></b>
    </td>

    <td class="font-mono text-p-green-700">
      <template v-if="type">
        <span v-if="Array.isArray(type)" v-text="type.join(' | ')"></span>
        <span v-else v-text="type"></span>
      </template>

      <span v-else v-text="defaultPropType"></span>
    </td>

    <td class="font-mono whitespace-nowrap">
      <span v-if="required" class="text-p-red-700">REQUIRED</span>
      <span
        v-else-if="$props.default !== 'blank'"
        class="text-indigo-700"
        v-text="$props.default === undefined ? 'undefined' : JSON.stringify($props.default)"
      ></span>
    </td>

    <td>
      <slot></slot>
    </td>
  </tr>
</template>

<script>
  import Vue from 'vue'
  export default Vue.extend({
    props: {
      name: { type: String, required: true },
      type: { type: [String, Array], default: undefined },
      default: { type: undefined, default: 'blank' },
      required: { type: Boolean, default: false },
    },
    computed: {
      defaultPropType() {
        return typeof this.default
      },
    },
  })
</script>
