<template>
  <span>
    <pp-formatted-number :value="value" v-bind="$attrs"></pp-formatted-number>
    <span v-text="` ${token.symbol}`"></span>
  </span>
</template>

<script>
  export default {
    props: {
      token: { type: Object, required: true },
      value: { type: [Number, String], default: 0 },
    },
  }
</script>
