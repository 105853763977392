import TokenCollection from '@/domains/collections/TokenCollection'
import { TokenAmount } from '@pendle/sdk'
import TokenContract from '../contracts/TokenContract'
import network from '@/app-config/network'

export default class Token {
  static className = 'Token'

  get className() {
    return 'Token'
  }

  constructor({
    name,
    symbol,
    address,
    decimals,
    protocol,
    logo,
    expiry,
    img,
    network,
    tokenCategories,
    yieldRepresentation,
    sources,
    ignoreApproval,
  }) {
    this.name = name
    this.symbol = symbol
    this.address = address
    this.decimals = decimals
    this.protocol = protocol
    this.logo = logo
    this.img = img
    this.expiry = expiry
    this.network = network
    this.tokenCategories = tokenCategories || []
    this.yieldRepresentation = yieldRepresentation
    this.sources = sources
    this.ignoreApproval = ignoreApproval
  }

  static query() {
    return TokenCollection.all()
  }

  toAmount({ amount, isRaw = true }) {
    return new TokenAmount(this, amount, isRaw)
  }

  contract({ provider, signer }) {
    return new TokenContract({ provider, signer, token: this })
  }

  get chainId() {
    return network[this.network]
  }

  get expiresAt() {
    return this.expiry ? new Date(this.expiry * 1000) : this.expiry
  }

  isSameAs(token) {
    return this.address === token.address
  }
}
