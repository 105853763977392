import { avalanche, decimals } from '../../constants'

const tokens = avalanche.TOKENS
const expiry = avalanche.EXPIRY
const pendle = avalanche.PENDLE

export default [
  {
    address: tokens.YT_QIUSDC_28_DEC_2023,
    decimals: decimals.DECIMALS_6,
    name: 'YT-qiUSDC-28Dec2023',
    symbol: 'YT-qiUSDC',
    logo: '/images/coins/avalanche/yt-qiusdc.png',
    network: 'avalanche',
    genericName: 'YT',
    protocol: 'BENQI',
    expiry: expiry.TIME_28_DEC_2023,
    yieldRepresentation: {
      amount: '1',
      unit: 'USDC',
      protocol: 'BENQI',
    },
    tokenCategories: ['yt'],
    ignoreApproval: [pendle.ROUTER],
  },
  {
    address: tokens.YT_QIAVAX_28_DEC_2023,
    decimals: decimals.DECIMALS_18,
    name: 'YT-qiAVAX-28Dec2023',
    symbol: 'YT-qiAVAX',
    logo: '/images/coins/avalanche/yt-qiavax.png',
    network: 'avalanche',
    genericName: 'YT',
    protocol: 'BENQI',
    expiry: expiry.TIME_28_DEC_2023,
    yieldRepresentation: {
      amount: '1',
      unit: 'AVAX',
      protocol: 'BENQI',
    },
    tokenCategories: ['yt'],
    ignoreApproval: [pendle.ROUTER],
  },
  {
    address: tokens.YT_JLP_WAVAX_PENDLE_28_DEC_2023,
    decimals: decimals.DECIMALS_18,
    name: 'YT-JLP(AVAX/PENDLE)-28Dec2023',
    symbol: 'YT-PA',
    logo: '/images/coins/avalanche/yt-joe-pa.png',
    network: 'avalanche',
    genericName: 'YT',
    protocol: 'TraderJoe',
    expiry: expiry.TIME_28_DEC_2023,
    yieldRepresentation: {
      amount: '1',
      unit: 'Liquidity[PENDLE/AVAX]',
      protocol: 'TraderJoe',
    },
    tokenCategories: ['yt'],
    ignoreApproval: [pendle.ROUTER],
  },
  {
    address: tokens.YT_XJOE_30_JUN_2022,
    decimals: decimals.DECIMALS_18,
    name: 'YT-xJOE-30Jun2022',
    symbol: 'YT-xJOE',
    logo: '/images/coins/avalanche/yt-xjoe.png',
    network: 'avalanche',
    genericName: 'YT',
    protocol: 'TraderJoe',
    expiry: expiry.TIME_30_JUN_2022,
    yieldRepresentation: {
      amount: '1',
      unit: 'JOE',
      protocol: 'TraderJoe',
    },
    tokenCategories: ['yt'],
    ignoreApproval: [pendle.ROUTER],
  },
  {
    address: tokens.YT_WMEMO_24_FEB_2022,
    decimals: decimals.DECIMALS_9,
    name: 'YT-wMEMO-24FEB2022',
    symbol: 'YT-wMEMO',
    logo: '/images/coins/avalanche/yt-wmemo.png',
    network: 'avalanche',
    genericName: 'YT',
    protocol: 'Wonderland',
    expiry: expiry.TIME_24_FEB_2022,
    yieldRepresentation: {
      amount: '1',
      unit: 'MEMO',
      protocol: 'Wonderland',
    },
    tokenCategories: ['yt'],
    ignoreApproval: [pendle.ROUTER],
  },
]
