<template>
  <div class="relative" @focusout="handleFocusOut">
    <pp-tab
      :class="btnClass"
      :color="color"
      :variant="variant"
      :size="size"
      ref="trigger"
      :disabled="disabled"
      @click="expanded = !expanded"
      @keydown.esc.prevent="handleEscape"
      @keydown.down.prevent="focusNext"
      @keydown.up="focusPrev"
      type="button"
      class="flex w-full"
    >
      <div>
        <slot :expanded="expanded">
          <span v-if="selectedOptionText" v-text="selectedOptionText"></span>
          <span v-else class="opacity-80" v-text="placeholder"></span>
        </slot>
      </div>

      <div class="flex-grow"></div>
      <div class="ml-2">
        <chevron-down-icon
          class="h-5 w-5 transform transition-transform ease-in-out duration-100"
          :class="expanded ? 'rotate-180' : ''"
        ></chevron-down-icon>
      </div>
    </pp-tab>

    <pp-slide-transition>
      <ul
        v-show="expanded"
        class="absolute top-full z-dropdown max-h-50 p-1 mt-1 space-y-1 shadow-pendle overflow-y-auto w-full bg-white rounded-lg border"
      >
        <template v-if="options.length">
          <li
            v-for="(option, index) in options"
            :key="option.key"
            :ref="`option-${index}`"
            @focus="active = index"
            @keydown.down.prevent="focusNext"
            @keydown.up.prevent="focusPrev"
            @keydown.esc="handleEscape"
          >
            <slot
              name="option"
              :option="option"
              :active="option.value === value"
              :on="{ click: () => handleInput(option.value) }"
            >
              <pp-select-option
                @input="handleInput(option.value)"
                :active="option.value === value"
                :value="option.value"
                :text="option.text"
                :img="option.img"
                :alt="option.alt"
                :disabled="option.disabled"
                :color="color"
                :variant="variant"
              ></pp-select-option>
            </slot>
          </li>
        </template>

        <li v-else key="no-key">
          <div class="text-p-gray-400 text-sm p-2">No options available</div>
        </li>
      </ul>
    </pp-slide-transition>
  </div>
</template>

<script>
  import ButtonColorMap from '@/ui-config/button-color-map'
  import HorizontalSizeMap from '@/ui-config/horizontal-size-map'
  import TabColorMap from '@/ui-config/tab-color-map'
  import ChevronDownIcon from '@/components/svg/outline/ChevronDownIcon.vue'
  import Vue from 'vue'

  export default Vue.extend({
    props: {
      options: { type: Array, default: () => [] }, // { img?: '', alt?: '', text: '', value: '' }
      value: { type: undefined, default: undefined },
      disabled: { type: Boolean, default: false },
      btnClass: { type: String, default: '' },
      color: { type: String, default: 'p-blue' },
      variant: { type: String, default: 'fill' },
      size: { type: String, default: 'md' },
      placeholder: { type: String, default: 'Select' },
    },
    components: {
      ChevronDownIcon,
    },
    data() {
      return {
        expanded: false,
        active: undefined,
      }
    },
    watch: {
      expanded(newVal) {
        if (newVal) {
          this.active = undefined
        }
      },
      active(newVal) {
        if (typeof newVal === 'number') {
          ;[
            ...this.$refs[`option-${newVal}`][0].querySelectorAll(
              'a, button, input, textarea, select, details,[tabindex]:not([tabindex="-1"])'
            ),
          ]
            .filter((el) => !el.hasAttribute('disabled'))[0]
            ?.focus()
        }
      },
    },
    computed: {
      selectedOptionText() {
        return this.options.find((option) => option.value === this.value)?.text
      },
      focusableOptions() {
        return this.options.filter((option) => option.value !== this.value)
      },
      btnColorClasses() {
        const { color, variant } = this
        const colorStyles = ButtonColorMap[color][variant]
        return [colorStyles.default, colorStyles.interactive].join(' ')
      },
      btnSizeClasses() {
        return HorizontalSizeMap[this.size]
      },
    },
    methods: {
      optionClasses() {
        return TabColorMap[this.color]
      },
      focusShift(next = true) {
        if (this.focusableOptions.length === 0) return
        const clone = this.focusableOptions.slice()

        if (this.active !== undefined) {
          while (clone[0] !== this.options[this.active]) {
            clone.push(clone.shift())
          }

          if (next) {
            clone.push(clone.shift())
          } else {
            clone.unshift(clone.pop())
          }
        } else {
          if (!next) clone.unshift(clone.pop())
        }

        this.active = this.options.findIndex((option) => option === clone[0])
      },
      focusPrev() {
        this.focusShift(false)
      },
      focusNext() {
        this.focusShift()
      },
      handleFocusOut(event) {
        const { currentTarget, relatedTarget } = event
        if (currentTarget.contains(relatedTarget)) return
        this.expanded = false
      },
      handleInput(value) {
        this.$emit('input', value)
        this.expanded = false
        this.$refs.trigger.$el.focus()
      },
      handleEscape() {
        this.expanded = false
        this.$refs.trigger.$el.focus()
      },
    },
  })
</script>
