import Vue from 'vue'
class AppState {
  constructor() {
    this.state = Vue.observable({
      openedDialogs: [],
      time: new Date(),
      slippage: '0.1',
      showEstimateGasErrorDialog: false,
    })
  }

  toggleLogoutDialog() {
    if (arguments[0]) {
      this.state.showLogoutDialog = !!arguments[0]
    } else {
      this.state.showLogoutDialog = !this.state.showLogoutDialog
    }
  }

  toggleMobileNavDialog() {
    if (arguments[0]) {
      this.state.showMobileNavDialog = !!arguments[0]
    } else {
      this.state.showMobileNavDialog = !this.state.showMobileNavDialog
    }
  }

  toggleEstimateGasErrorDialog() {
    if (arguments[0]) {
      this.state.showEstimateGasErrorDialog = !!arguments[0]
    } else {
      this.state.showEstimateGasErrorDialog = !this.state.showEstimateGasErrorDialog
    }
  }
}

export { AppState }
export default new AppState()
