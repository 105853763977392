<template>
  <div class="items-center space-x-3">
    <div>
      <router-link to="/">
        <img
          class="h-10 w-10"
          :src="require('@/assets/brand/brandmark.svg')"
          title="Pendle"
          alt="Pendle's logo"
        />
      </router-link>
    </div>

    <div class="space-x-4 flex items-center text-p-gray-300">
      <router-link
        v-for="link in internalLinks"
        :key="link.title"
        :to="link.url"
        v-text="link.title"
        class="inline-block text-water-500 hover:text-water-700 focus:text-water-700 transition"
        :class="$route.path.includes(link.url) ? '!text-water-800 font-bold' : ''"
      ></router-link>
    </div>

    <div class="flex-grow"></div>
    <NavbarActions direction="down" />
  </div>
</template>

<script>
  import NavbarActions from '@/components/singles/navbar/NavbarActions.vue'

  export default {
    props: {
      internalLinks: { type: Array, required: true },
    },
    components: {
      NavbarActions,
    },
    data() {
      return {
        showNotifications: false,
        showSocials: false,
      }
    },
    methods: {
      handleNotificationFocusOut(event) {
        const { currentTarget, relatedTarget } = event
        if (currentTarget.contains(relatedTarget)) return
        this.showNotifications = false
      },
      handleSocialsFocusOut(event) {
        const { currentTarget, relatedTarget } = event
        if (currentTarget.contains(relatedTarget)) return
        this.showSocials = false
      },
    },
  }
</script>
