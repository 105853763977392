<template>
  <pp-skeleton-loader v-if="valuation.is('loading')" class="w-20 inline-block"></pp-skeleton-loader>

  <div v-else-if="valuation.is('resolved')" class="inline-flex items-center">
    ~
    <pp-formatted-price
      :value="valuation.response.amount"
      :currency="valuation.response.currency"
    ></pp-formatted-price>
  </div>

  <span
    v-else-if="valuation.is('rejected')"
    @click="fetchValuation"
    class="inline-block text-p-red-600"
  >
    (Error)
  </span>
</template>

<script>
  import Vue from 'vue'
  import TokenAmount from '@/domains/entities/TokenAmount'
  import PromiseHandler, { createState } from '@/domains/PromiseHandler'

  export default Vue.extend({
    props: {
      tokenAmount: { type: Object, required: true },
    },
    data() {
      return {
        valuation: createState(),
      }
    },
    computed: {
      transformedTokenAmount() {
        return TokenAmount.fromSdkTokenAmount(this.tokenAmount)
      },
    },
    methods: {
      fetchValuation() {
        new PromiseHandler(
          this.transformedTokenAmount.contract(
            this.$store.getters['wallet/identity']
          ).fetchValuation,
          this.valuation
        ).execute()
      },
    },
    watch: {
      transformedTokenAmount: {
        immediate: true,
        handler() {
          this.fetchValuation()
        },
      },
    },
  })
</script>
