import Web3 from 'web3'
import NetworkParams from '@/app-config/metamask'
import networkEnv from '@/app-config/networks'

const switchNetwork = async (): Promise<void> => {
  const envChaindId = networkEnv.VUE_APP_NETWORK_ID || 1
  const chainId = Web3.utils.toHex(envChaindId)

  try {
    //@ts-ignore
    await window.ethereum.request({
      method: 'wallet_switchEthereumChain',
      params: [{ chainId }],
    })
  } catch (switchError) {
    // This error code indicates that the chain has not been added to MetaMask.
    if (switchError.code === 4902) {
      try {
        const param = Object.values(NetworkParams).find(
          (param) => param.chainId.toLowerCase() === chainId.toLowerCase()
        )

        // @ts-ignore
        await window.ethereum.request({
          method: 'wallet_addEthereumChain',
          params: [param],
        })
      } catch (addError) {
        console.error('Error adding ethereum mainnet', addError)
      }
    }

    console.error('Error switching networks', switchError)
  }
}

export default switchNetwork
