export default {
  fill: {
    default: 'bg-p-blue-600 text-white shadow-sm disabled:bg-gray-200 disabled:text-gray-400',
    interactive:
      'hover:bg-p-blue-700 hover:text-white group-hover:bg-p-blue-700 group-hover:text-white focus:ring-p-blue-500',
  },
  gradient: {
    default:
      'duration-500 bg-gradient-to-r from-p-blue-500 via-p-blue-700 to-p-blue-500 bg-pos-0 bg-size-200 disabled:from-gray-200 disabled:via-gray-200 disabled:to-gray-200 text-white shadow-sm  disabled:text-gray-400',
    interactive: 'hover:bg-pos-100 focus:bg-pos-100 group-hover:bg-pos-100 focus:ring-p-blue-500',
  },
  text: {
    default: 'text-p-blue-700 disabled:text-gray-400 disabled:bg-transparent',
    interactive:
      'hover:bg-p-blue-100 hover:shadow group-hover:bg-p-blue-100 group-hover:shadow focus:ring-p-blue-500',
  },
  accent: {
    default: 'bg-p-blue-100 text-p-blue-700 shadow-sm disabled:bg-gray-200 disabled:text-gray-400',
    interactive: 'hover:bg-p-blue-200 group-hover:bg-p-blue-200 focus:ring-p-blue-500',
  },
  outline: {
    default:
      'border-p-blue-700 text-p-blue-700 border shadow-sm disabled:border-gray-400 disabled:text-gray-400 disabled:bg-transparent',
    interactive:
      'hover:bg-p-blue-200 hover:text-p-blue-900 group-hover:bg-p-blue-200 group-hover:text-p-blue-900 focus:ring-p-blue-500',
  },
}
