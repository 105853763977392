<template>
  <BaseDialog
    @input="(newVal) => $app.toggleEstimateGasErrorDialog(newVal)"
    :value="$app.state.showEstimateGasErrorDialog"
    width="360px"
  >
    <template v-slot:default="{ toggle }">
      <base-card class="p-4 bg-white w-full rounded-5px">
        <div class="flex items-center text-primary">
          <div class="text-2xl">Error</div>
          <div class="flex-grow"></div>

          <div>
            <button class="focus:outline-none" @click="toggle(false)">
              <CloseIcon />
            </button>
          </div>
        </div>

        <div class="my-6 text-p-red-400">
          <ErrorIcon class="w-1/4 mx-auto fill-current" />
          <div class="w-4/5 sm:w-3/4 mx-auto mt-2 text-center">
            <p>Unexpected issue with gas estimation. Refresh your page and try again.</p>
            <p class="text-sm mt-2">
              If problem persists, contact us in our
              <a
                href="https://discord.gg/EAujvncY2R"
                target="_blank"
                class="underline hover:font-bold"
              >
              Discord</a>
              channel
            </p>
          </div>
        </div>

        <div class="mt-2">
          <pp-btn class="w-full" color="p-red" variant="accent" size="lg" @click="toggle(false)">
            Dismiss
          </pp-btn>
        </div>
      </base-card>
    </template>
  </BaseDialog>
</template>

<script>
  import BaseDialog from '@/components/BaseDialog.vue'
  import BaseCard from '@/components/BaseCard.vue'
  import CloseIcon from '@/components/svg/CloseIcon.vue'
  import ErrorIcon from '@/components/svg/ErrorIcon.vue'

  export default {
    props: {
      value: {
        type: Boolean,
        default: false,
      },
    },
    components: {
      BaseDialog,
      BaseCard,
      CloseIcon,
      ErrorIcon,
    },
  }
</script>
