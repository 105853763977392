<template>
  <div class="flex items-center space-x-2">
    <pp-btn
      v-if="isMetamaskConnection"
      variant="text"
      icon
      class="!h-auto !w-auto !p-1.5 text-water-800 bg-p-gray-100 border-p-gray-200 rounded-xl shadow-pendle hidden sm:inline-flex items-center"
      @click="importPendleLogoToWallet"
    >
      <img class="h-5 w-5" :src="require('@/assets/images/coins/pendle.png')" alt="Pendle Logo" />
    </pp-btn>

    <div class="relative" @focusout="handleNetworkFocusOut">
      <pp-btn
        data-test="network-selector"
        variant="text"
        size="sm"
        class="text-water-800 bg-p-gray-100 border-p-gray-200 !p-2 rounded-full sm:rounded-xl shadow-pendle inline-flex items-center"
        @click="showNetworks = !showNetworks"
        @keydown.esc="showNetworks = false"
      >
        <div class="rounded-full w-6 -my-1 inline-flex items-center justify-center">
          <img :src="networkEnv.icon" :alt="`${networkEnv.name} logo`" />
        </div>

        <span class="ml-1 hidden sm:inline">{{ networkEnv.name }}</span>

        <chevron-down-icon
          class="h-4 w-4 ml-0.5 transform transition-transform ease-in-out duration-100"
          :class="{ 'rotate-180': showNetworks }"
        ></chevron-down-icon>
      </pp-btn>

      <pp-slide-transition :direction="direction">
        <network-dropdown
          v-show="showNetworks"
          class="absolute sm:right-0 sm:left-auto left-0 w-36"
          :class="direction === 'down' ? 'top-full' : 'bottom-full'"
        ></network-dropdown>
      </pp-slide-transition>
    </div>

    <pp-btn
      v-if="$store.getters['wallet/connected']"
      variant="text"
      size="sm"
      class="text-water-800 bg-p-gray-100 border-p-gray-200 rounded-xl shadow-pendle"
      @click="$store.dispatch('wallet/disconnectWallet')"
    >
      <pp-wallet-address
        v-if="$store.state.wallet.address"
        :value="$store.state.wallet.address"
      ></pp-wallet-address>
      <pp-skeleton-loader v-else class="w-20 h-4"></pp-skeleton-loader>
    </pp-btn>

    <pp-btn
      v-else
      variant="text"
      size="sm"
      class="text-water-800 bg-p-gray-100 border-p-gray-200 rounded-xl shadow-pendle"
      @click="$store.dispatch('wallet/connectWallet')"
      data-test="connect-wallet"
    >
      <span>Connect Wallet</span>
    </pp-btn>

    <div class="relative flex items-center" @focusout="handleNotificationFocusOut">
      <pp-btn
        class="h-9 w-9 md:h-10 md:w-10"
        icon
        variant="text"
        @click="showNotifications = !showNotifications"
        @keydown.esc="showNotifications = false"
      >
        <svg
          class="h-full w-full"
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
        >
          <title>Notifications</title>
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
            d="M15 17h5l-1.405-1.405A2.032 2.032 0 0118 14.158V11a6.002 6.002 0 00-4-5.659V5a2 2 0 10-4 0v.341C7.67 6.165 6 8.388 6 11v3.159c0 .538-.214 1.055-.595 1.436L4 17h5m6 0v1a3 3 0 11-6 0v-1m6 0H9"
          />
        </svg>
      </pp-btn>

      <pp-slide-transition :direction="direction">
        <notification-container
          v-show="showNotifications"
          tabindex="-1"
          :class="
            direction === 'down'
              ? 'top-full absolute right-0 w-96'
              : 'bottom-[4rem] fixed left-0 right-0 w-full'
          "
        ></notification-container>
      </pp-slide-transition>
    </div>

    <div class="relative flex items-center" @focusout="handleSlippageFocusOut">
      <pp-btn
        data-test="cog-btn"
        class="h-9 w-9 md:h-10 md:w-10"
        icon
        variant="text"
        @click="showSlippage = !showSlippage"
        @keydown.esc="showSlippage = false"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          class="h-full w-full"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
        >
          <title>Settings</title>
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
            d="M10.325 4.317c.426-1.756 2.924-1.756 3.35 0a1.724 1.724 0 002.573 1.066c1.543-.94 3.31.826 2.37 2.37a1.724 1.724 0 001.065 2.572c1.756.426 1.756 2.924 0 3.35a1.724 1.724 0 00-1.066 2.573c.94 1.543-.826 3.31-2.37 2.37a1.724 1.724 0 00-2.572 1.065c-.426 1.756-2.924 1.756-3.35 0a1.724 1.724 0 00-2.573-1.066c-1.543.94-3.31-.826-2.37-2.37a1.724 1.724 0 00-1.065-2.572c-1.756-.426-1.756-2.924 0-3.35a1.724 1.724 0 001.066-2.573c-.94-1.543.826-3.31 2.37-2.37.996.608 2.296.07 2.572-1.065z"
          />
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
            d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"
          />
        </svg>
      </pp-btn>

      <pp-slide-transition :direction="direction">
        <pp-card
          v-if="showSlippage"
          class="absolute right-0 w-72 border"
          :class="direction === 'down' ? 'top-full' : 'bottom-full'"
        >
          <pp-card-body>
            <SlippageInput v-model="$app.state.slippage" />
          </pp-card-body>
        </pp-card>
      </pp-slide-transition>
    </div>

    <div class="relative" @focusout="handleSocialsFocusOut">
      <pp-btn
        class="h-9 w-9 md:h-10 md:w-10"
        icon
        variant="text"
        @click="showSocials = !showSocials"
        @keydown.esc="showSocials = false"
      >
        <svg
          class="h-full w-full"
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
        >
          <title>Socials</title>
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
            d="M5 12h.01M12 12h.01M19 12h.01M6 12a1 1 0 11-2 0 1 1 0 012 0zm7 0a1 1 0 11-2 0 1 1 0 012 0zm7 0a1 1 0 11-2 0 1 1 0 012 0z"
          />
        </svg>
      </pp-btn>

      <pp-slide-transition :direction="direction">
        <social-dropdown
          v-show="showSocials"
          class="absolute right-0 w-36"
          :class="direction === 'down' ? 'top-full' : 'bottom-full'"
        ></social-dropdown>
      </pp-slide-transition>
    </div>
  </div>
</template>

<script>
  import NotificationContainer from '@/components/notifications/NotificationContainer.vue'
  import SocialDropdown from '@/components/singles/navbar/SocialDropdown.vue'
  import NetworkDropdown from '@/components/singles/navbar/NetworkDropdown.vue'
  import switchNetwork from '@/utils/switch-network'
  import Token from '@/domains/entities/Token'
  import { mapState } from 'vuex'
  import networkEnv from '@/app-config/networks'
  import SlippageInput from '@/components/forms/SlippageInput.vue'
  import ChevronDownIcon from '@/components/svg/outline/ChevronDownIcon.vue'

  const networkMapping = {
    1: 'Ethereum',
    3: 'Ropsten',
    4: 'Rinkeby',
    42: 'Kovan',
    56: 'Binance Smart Chain',
    97: 'BSC Testnet',
    137: 'Matic',
    80001: 'Matic Mumbai',
  }

  export default {
    components: {
      NotificationContainer,
      SocialDropdown,
      SlippageInput,
      NetworkDropdown,
      ChevronDownIcon,
    },
    props: {
      direction: { type: String, required: true },
    },
    data() {
      return {
        showNotifications: false,
        showSocials: false,
        showSlippage: false,
        showNetworks: false,
      }
    },
    computed: {
      networkEnv() {
        return networkEnv
      },
      userWalletNetwork() {
        return this.networkId
          ? networkMapping[this.networkId] || 'Unknown Network'
          : 'Network not detected'
      },
      isMetamaskConnection() {
        return this.$store.getters['wallet/identity']?.provider?.connection?.url === 'metamask'
      },
      isOnWrongNetwork() {
        return networkEnv.VUE_APP_NETWORK_ID != this.networkId
      },
      ...mapState('wallet', ['networkId']),
    },
    methods: {
      handleNotificationFocusOut(event) {
        const { currentTarget, relatedTarget } = event
        if (currentTarget.contains(relatedTarget)) return
        this.showNotifications = false
      },
      handleSocialsFocusOut(event) {
        const { currentTarget, relatedTarget } = event
        if (currentTarget.contains(relatedTarget)) return
        this.showSocials = false
      },
      handleSlippageFocusOut(event) {
        const { currentTarget, relatedTarget } = event
        if (currentTarget.contains(relatedTarget)) return
        this.showSlippage = false
      },
      handleNetworkFocusOut(event) {
        const { currentTarget, relatedTarget } = event
        if (currentTarget.contains(relatedTarget)) return
        this.showNetworks = false
      },
      importPendleLogoToWallet() {
        const pendleToken = Token.query()
          .where('network', networkEnv.VUE_APP_NETWORK_ENV)
          .findBy('name', 'PENDLE')

        try {
          // wasAdded is a boolean. Like any RPC method, an error may be thrown.
          window.ethereum.request({
            method: 'wallet_watchAsset',
            params: {
              type: 'ERC20', // Initially only supports ERC20, but eventually more!
              options: {
                address: pendleToken.address, // The address that the token is at.
                symbol: pendleToken.symbol, // A ticker symbol or shorthand, up to 5 chars.
                decimals: pendleToken.decimals, // The number of decimals in the token
                image: pendleToken.externalImg, // A string url of the token logo
              },
            },
          })
        } catch (error) {
          console.log(error)
        }
      },
      switchNetwork,
    },
  }
</script>
