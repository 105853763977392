<template>
  <div v-on="$listeners" v-bind="$attrs" class="pp-card" :class="[colorClasses]">
    <slot></slot>
  </div>
</template>

<script>
  import Vue from 'vue'
  import ButtonColorMap from '@/ui-config/button-color-map'

  export default Vue.extend({
    props: {
      color: { type: String, default: undefined },
      variant: { type: String, default: undefined },
    },
    computed: {
      colorClasses() {
        const { color, variant } = this
        return color ? ButtonColorMap[color][variant].default : ''
      },
    },
  })
</script>
