<template>
  <div>
    <a
      v-if="external"
      :href="to"
      target="_blank"
      class="inline-flex items-center text-md outline-none focus:ring-2 ring-offset-4 ring-water-600 rounded transition hover:underline"
    >
      {{ text }}

      <svg
        xmlns="http://www.w3.org/2000/svg"
        class="ml-1 h-5 w-5"
        viewBox="0 0 20 20"
        fill="currentColor"
      >
        <path
          d="M11 3a1 1 0 100 2h2.586l-6.293 6.293a1 1 0 101.414 1.414L15 6.414V9a1 1 0 102 0V4a1 1 0 00-1-1h-5z"
        />
        <path
          d="M5 5a2 2 0 00-2 2v8a2 2 0 002 2h8a2 2 0 002-2v-3a1 1 0 10-2 0v3H5V7h3a1 1 0 000-2H5z"
        />
      </svg>
    </a>

    <router-link v-else :to="to">
      <div
        class="inline-flex items-center text-md outline-none focus:ring-2 ring-offset-4 ring-water-600 rounded transition hover:underline"
      >
        <div>{{ text }}</div>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          class="ml-1 h-5 w-5"
          viewBox="0 0 20 20"
          fill="currentColor"
        >
          <path
            d="M11 3a1 1 0 100 2h2.586l-6.293 6.293a1 1 0 101.414 1.414L15 6.414V9a1 1 0 102 0V4a1 1 0 00-1-1h-5z"
          />
          <path
            d="M5 5a2 2 0 00-2 2v8a2 2 0 002 2h8a2 2 0 002-2v-3a1 1 0 10-2 0v3H5V7h3a1 1 0 000-2H5z"
          />
        </svg>
      </div>
    </router-link>
  </div>
</template>

<script>
  export default {
    props: {
      text: { type: String },
      to: { type: [String, Object] },
      external: { type: Boolean, default: false },
    },
  }
</script>
