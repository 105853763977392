<template>
  <img v-bind="$attrs" v-on="$listeners" :src="token.img" :alt="token.name" ref="tokenImage" />
</template>

<script>
  export default {
    props: {
      token: { type: Object, required: true },
    },
    mounted() {
      const tokenImage = this.$refs.tokenImage
      tokenImage.onerror = () => {
        tokenImage.src = require('@/assets/images/unknown.png')
      }
    },
  }
</script>
