import { avalanche, decimals } from '../../../constants'

const tokens = avalanche.TOKENS

export default [
  {
    address: tokens.JLP_WAVAX_PENDLE,
    decimals: decimals.DECIMALS_18,
    name: 'PENDLE / AVAX',
    symbol: 'PENDLE / AVAX',
    logo: '/images/coins/avalanche/joe-pa.png',
    network: 'avalanche',
    genericName: 'yieldBearing',
    protocol: 'TraderJoe',
    sources: ['https://traderjoexyz.com/pool'],
    // this currently doesn't work from traderjoe's side
    // sources: [`https://traderjoexyz.com/#/pool/${tokens.PENDLE}/AVAX`],
    tokenCategories: ['lp'],
  },
]
