import { avalanche, decimals } from '../../constants'

const tokens = avalanche.TOKENS

export default [
  {
    address: tokens.PENDLE,
    decimals: decimals.DECIMALS_18,
    name: 'PENDLE',
    symbol: 'PENDLE',
    logo: '/images/coins/pendle.svg',
    network: 'avalanche',
    genericName: 'PENDLE',
    protocol: 'Pendle',
    externalImg: 'https://app.pendle.finance/pendle_token.png',
    tokenCategories: ['generic', 'traderJoeDefault'],
    sources: [`https://traderjoexyz.com/trade?outputCurrency=${tokens.PENDLE}`],
  },
  {
    address: tokens.USDC,
    decimals: decimals.DECIMALS_6,
    name: 'USDC.e',
    symbol: 'USDC.e',
    logo: '/images/coins/USDC.svg',
    network: 'avalanche',
    genericName: 'USDC',
    tokenCategories: ['generic', 'traderJoeDefault'],
    sources: [`https://traderjoexyz.com/#/trade?outputCurrency=${tokens.USDC}`],
  },
  {
    address: tokens.QIUSDC,
    decimals: decimals.DECIMALS_8,
    name: 'BENQI USDC',
    symbol: 'qiUSDC',
    logo: '/images/coins/avalanche/qiusdc.png',
    network: 'avalanche',
    genericName: 'yieldBearing',
    protocol: 'BENQI',
    tokenCategories: ['generic'],
    sources: ['https://app.benqi.fi/markets'],
  },
  {
    address: tokens.QIAVAX,
    decimals: decimals.DECIMALS_18,
    name: 'BENQI AVAX',
    symbol: 'qiAVAX',
    logo: '/images/coins/avalanche/qiavax.png',
    network: 'avalanche',
    genericName: 'yieldBearing',
    protocol: 'BENQI',
    tokenCategories: ['generic'],
    sources: ['https://app.benqi.fi/markets'],
  },
  {
    address: tokens.AVAX,
    decimals: decimals.DECIMALS_18,
    name: 'Avax',
    symbol: 'AVAX',
    logo: '/images/coins/avalanche/avax.png',
    network: 'avalanche',
    genericName: 'AVAX',
    tokenCategories: ['generic', 'traderJoeDefault'],
    ignoreApproval: ['all'],
  },
  {
    address: tokens.WAVAX,
    decimals: decimals.DECIMALS_18,
    name: 'Wrapped Avax',
    symbol: 'WAVAX',
    logo: '/images/coins/avalanche/wavax.png',
    network: 'avalanche',
    genericName: 'WAVAX',
    tokenCategories: ['generic', 'traderJoeDefault'],
  },
  {
    address: tokens.JOE,
    decimals: decimals.DECIMALS_18,
    name: 'Joe',
    symbol: 'JOE',
    logo: '/images/coins/avalanche/joe.png',
    network: 'avalanche',
    genericName: 'JOE',
    protocol: 'TraderJoe',
    tokenCategories: ['generic', 'traderJoeDefault'],
    sources: [`https://traderjoexyz.com/trade?outputCurrency=${tokens.JOE}`],
  },
  {
    address: tokens.XJOE,
    decimals: decimals.DECIMALS_18,
    name: 'xJOE',
    symbol: 'xJOE',
    logo: '/images/coins/avalanche/xjoe.png',
    network: 'avalanche',
    genericName: 'xJOE',
    protocol: 'TraderJoe',
    tokenCategories: ['generic', 'traderJoeDefault'],
    sources: ['https://traderjoexyz.com/stake'],
  },
  {
    address: tokens.QI,
    decimals: decimals.DECIMALS_18,
    name: 'Qi',
    symbol: 'Qi',
    logo: '/images/coins/avalanche/qi.png',
    network: 'avalanche',
    genericName: 'Qi',
    protocol: 'BENQI',
    tokenCategories: ['generic', 'traderJoeDefault'],
  },
  {
    address: tokens.MEMO,
    decimals: decimals.DECIMALS_9,
    name: 'MEMO',
    symbol: 'MEMO',
    logo: '/images/coins/avalanche/wmemo.png',
    network: 'avalanche',
    genericName: 'MEMO',
    protocol: 'Wonderland',
    tokenCategories: ['generic'],
    sources: ['https://app.wonderland.money/#/stake'],
  },
  {
    address: tokens.WMEMO,
    decimals: decimals.DECIMALS_18,
    name: 'Wrapped MEMO',
    symbol: 'wMEMO',
    logo: '/images/coins/avalanche/wmemo.png',
    network: 'avalanche',
    genericName: 'wMEMO',
    protocol: 'Wonderland',
    tokenCategories: ['generic'],
    sources: ['https://app.wonderland.money/#/stake'],
  },
  {
    address: tokens.MIM,
    decimals: decimals.DECIMALS_18,
    name: 'Magic Internet Money',
    symbol: 'MIM',
    logo: '/images/coins/avalanche/mim.png',
    network: 'avalanche',
    genericName: 'MIM',
    protocol: 'Wonderland',
    tokenCategories: ['generic', 'traderJoeDefault'],
  },
  {
    address: tokens.TIME,
    decimals: decimals.DECIMALS_9,
    name: 'Time',
    symbol: 'TIME',
    logo: '/images/coins/avalanche/time.png',
    network: 'avalanche',
    genericName: 'TIME',
    protocol: 'Wonderland',
    tokenCategories: ['generic', 'traderJoeDefault'],
  },
]
