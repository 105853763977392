<template>
  <div>
    <div class="inline-flex items-center h-5 bg-water-100 rounded-md px-2 py-0.5 space-x-1">
      <img
        :src="tokenAmount.token.img"
        :alt="tokenAmount.token.name"
        class="h-3 w-3 rounded-full"
        ref="tokenImage"
      />
      <pp-skeleton-loader v-if="loading" class="h-3 w-8"></pp-skeleton-loader>
      <pp-formatted-number
        v-else
        :value="tokenAmount.formattedAmount()"
        class="text-xs"
      ></pp-formatted-number>
    </div>
  </div>
</template>

<script>
  export default {
    props: {
      tokenAmount: { type: Object, required: true },
      loading: { type: Boolean, default: false },
    },
    mounted() {
      const tokenImage = this.$refs.tokenImage
      tokenImage.onerror = () => {
        tokenImage.src = require('@/assets/images/unknown.png')
      }
    },
  }
</script>
