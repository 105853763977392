<template>
  <div>
    <div class="px-4">
      <slot name="header">
        <div class="flex items-center flex-wrap">
          <div class="flex items-center flex-wrap">
            <h2 class="font-semibold text-2xl" v-text="header"></h2>
            <pp-tooltip-trigger
              v-if="tooltip || $slots.tooltip"
              class="ml-2"
              :direction="tooltipDirection"
            >
              <slot name="tooltip">
                {{ tooltip }}
              </slot>
            </pp-tooltip-trigger>
          </div>

          <div class="flex-grow"></div>

          <slot name="right"></slot>
        </div>

        <div class="text-water-600" v-text="subtitle"></div>
      </slot>
    </div>

    <div class="mt-4">
      <slot></slot>
    </div>
  </div>
</template>

<script>
  import Vue from 'vue'

  export default Vue.extend({
    props: {
      header: { type: String, default: '' },
      subtitle: { type: String, default: '' },
      tooltip: { type: String, default: '' },
      tooltipDirection: { type: String, default: 'top' },
    },
  })
</script>
