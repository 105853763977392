<template>
  <div>
    <div class="space-y-2">
      <pp-token-approve-container
        v-for="meta in spendingAmountMetas"
        :key="meta.amount.token.address"
        :spender-address="spenderAddress"
        :spending-amount="meta.amount"
        :approval.sync="meta.approved"
        ref="tokenApproveContainer"
      ></pp-token-approve-container>
    </div>

    <slot :approved="hasApproval"></slot>
  </div>
</template>

<script>
  export default {
    props: {
      spendingAmounts: { type: Array, required: true },
      spenderAddress: { type: String, required: true },
    },
    data() {
      return {
        spendingAmountMetas: [],
      }
    },
    watch: {
      spendingAmounts: {
        handler(val) {
          this.spendingAmountMetas = val.map((amount) => ({
            amount,
            approved: false,
          }))
        },
        immediate: true,
      },
    },
    computed: {
      hasApproval() {
        return this.spendingAmountMetas.every((meta) => meta.approved)
      },
    },
  }
</script>
