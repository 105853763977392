import Token from './Token'
import OTCollection from '../collections/OTCollection'

export default class OT extends Token {
  static query() {
    return new OTCollection(
      ...Token.query().where('tokenCategories', (val) => (val || []).includes('ot'))
    )
  }
}
