<template>
  <div
    v-if="market.statusIs('inactive')"
    class="inline-flex items-center px-2 border rounded-xl"
    :class="colorClass"
  >
    <div class="w-1.5 h-1.5 rounded-full" :class="bgClass"></div>
    <div class="ml-1 text-xs">{{ statusText }}</div>
    <pp-tooltip-trigger
      class="ml-1 myx"
      :class="market.statusIs('frozen') ? 'frozen' : 'expired'"
      :direction="tooltipDirection"
    >
      <div>
        <span class="break-words">{{ market.token.name }}</span>
        pair is in {{ status }} state. Only Unstake, Remove Liquidity (dual tokens) and Redeem
        functions are available.
        <a
          :href="`https://docs.pendle.finance/docs/information/dive-deeper/market-states#${status}-state`"
          target="_blank"
          class="hover:underline"
        >
          <span class="text-p-blue-900">Learn more</span>
        </a>
      </div>
    </pp-tooltip-trigger>
  </div>
</template>

<script>
  import PendleMarket from '@/domains/entities/PendleMarket'

  export default {
    props: {
      address: { type: String, required: true },
      tooltipDirection: { type: String, default: 'top' },
    },
    computed: {
      market() {
        return PendleMarket.query().find(this.address)
      },
      status() {
        return this.market.status
      },
      colorClass() {
        return {
          frozen: 'text-p-yellow-500 border-p-yellow-500',
          expired: 'text-p-red-500 border-p-red-500',
          normal: 'text-p-green-500 border-p-green-500',
        }[this.status]
      },
      bgClass() {
        return {
          frozen: 'bg-p-yellow-500',
          expired: 'bg-p-red-500',
          normal: 'bg-p-green-500',
        }[this.status]
      },
      statusText() {
        return {
          frozen: 'Frozen',
          expired: 'Expired',
          normal: 'Normal',
        }[this.status]
      },
    },
  }
</script>

<style scoped>
  .frozen {
    @apply text-p-yellow-500 hover:bg-p-yellow-200 focus:ring-p-yellow-500;
  }
  .expired {
    @apply text-p-red-500 hover:bg-p-red-200 focus:ring-p-red-500;
  }
</style>
