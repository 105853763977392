<template>
  <component
    :is="tag"
    class="pp-btn px-3 py-1 min-w-[84px] text-white text-sm bg-gradient-to-r"
    :class="[gradientClasses, disabledClasses]"
    v-bind="$attrs"
    :disabled="disabled"
  >
    <slot></slot>
    <img v-if="icon" :src="icon" alt="protocol icon" class="h-4 ml-1" />
  </component>
</template>

<script>
  import sushiIcon from '@/assets/images/coins/sushi.svg'
  import traderjoeIcon from '@/assets/images/coins/avalanche/joe.png'

  const defaultGradient = 'from-[#5B749D] to-[#2F496E]'

  export default {
    props: {
      tag: { type: String, default: 'button' },
      variant: { type: String, default: 'blue' },
      disabled: { type: Boolean, default: false },
    },
    computed: {
      icon() {
        return { sushi: sushiIcon, traderjoe: traderjoeIcon }[this.variant]
      },
      gradientClasses() {
        return (
          {
            sushi: 'from-[#9381C3] to-[#F156A4]',
            traderjoe: 'from-[#F2716A] to-[#BD967F]',
          }[this.variant] || defaultGradient
        )
      },
      disabledClasses() {
        return this.disabled ? 'opacity-40 filter grayscale pointer-events-none cursor-default' : ''
      },
    },
  }
</script>
