<template>
  <div class="container mx-auto flex flex-wrap items-center">
    <div class="mt-10 md:mt-0 order-1 w-full md:w-1/2 p-4">
      <img :src="require('@/assets/images/Captain_Pependle.png')" />
    </div>

    <div class="md:order-1 w-full md:w-1/2 p-4">
      <h2 class="text-3xl font-semibold text-p-yellow-800">
        Your wallet is connected to the wrong network
      </h2>
      <p class="mt-3 text-xl text-p-yellow-700">
        The app is currently set to
        <span class="font-semibold" v-text="networkEnv.name"></span>
        network.
      </p>

      <div class="mt-10">
        <pp-btn v-if="isMetaMask" @click="switchNetwork" size="lg" variant="fill" color="p-yellow">
          Switch to {{ networkEnv.name }}
        </pp-btn>

        <p v-else class="text-p-yellow-700 text-lg">
          Your wallet is on the wrong network. Please
          <span class="font-bold">switch to {{ networkEnv.name }}</span>
          to continue.
        </p>
      </div>
    </div>
  </div>
</template>

<script>
  import switchNetwork from '@/utils/switch-network'
  import networkEnv from '@/app-config/networks'

  export default {
    data() {
      return {}
    },
    computed: {
      networkEnv() {
        return networkEnv
      },
      isMetaMask() {
        return this.$store.getters['wallet/isMetaMask']
      },
    },
    methods: {
      switchNetwork,
    },
  }
</script>
