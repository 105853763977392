import { mainnet, decimals } from '../../../constants'

const pools = mainnet.POOLS
const expiry = mainnet.EXPIRY

export default [
  {
    address: pools.OT_SLP_PENDLE_WETH_29_DEC_2022_X_PENDLE,
    decimals: decimals.DECIMALS_18,
    name: 'OT-SLP(PENDLE/ETH)-29Dec2022/PENDLE',
    symbol: 'OT-PE / PENDLE',
    logo: '/images/coins/mainnet/ot-pe_pendle.png',
    network: 'mainnet',
    genericName: 'Sushi LP',
    protocol: 'Sushi',
    expiry: expiry.TIME_29_DEC_2022,
    tokenCategories: ['lp'],
  },
  {
    address: pools.OT_SLP_USDC_WETH_29_DEC_2022_X_USDC,
    decimals: decimals.DECIMALS_18,
    name: 'OT-SLP(ETH/USDC)-29Dec2022/USDC',
    symbol: 'OT-ETHUSDC / USDC',
    logo: '/images/coins/mainnet/ot-ethusdc_usdc.png',
    network: 'mainnet',
    genericName: 'Sushi LP',
    protocol: 'Sushi',
    expiry: expiry.TIME_29_DEC_2022,
    tokenCategories: ['lp'],
  },
  {
    address: pools.OT_AUSDC_30_DEC_2021_X_USDC,
    decimals: decimals.DECIMALS_18,
    name: 'OT-aUSDC-30DEC2021/USDC',
    symbol: 'OT-aUSDC / USDC',
    logo: '/images/coins/ot-ausdc_usdc.png',
    network: 'mainnet',
    genericName: 'Sushi LP',
    protocol: 'Aave-v2',
    expiry: expiry.TIME_30_DEC_2021,
    tokenCategories: ['lp'],
  },
  {
    address: pools.OT_AUSDC_29_DEC_2022_X_USDC,
    decimals: decimals.DECIMALS_18,
    name: 'OT-aUSDC-29DEC2022/USDC',
    symbol: 'OT-aUSDC / USDC',
    logo: '/images/coins/ot-ausdc_usdc.png',
    network: 'mainnet',
    genericName: 'Sushi LP',
    protocol: 'Aave-v2',
    expiry: expiry.TIME_29_DEC_2022,
    tokenCategories: ['lp'],
  },
  {
    address: pools.OT_CDAI_30_DEC_2021_X_USDC,
    decimals: decimals.DECIMALS_18,
    name: 'OT-cDAI-30DEC2021/USDC',
    symbol: 'OT-cDAI / USDC',
    logo: '/images/coins/ot-cdai_usdc.png',
    network: 'mainnet',
    genericName: 'Sushi LP',
    protocol: 'Compound',
    expiry: expiry.TIME_30_DEC_2021,
    tokenCategories: ['lp'],
  },
  {
    address: pools.OT_CDAI_29_DEC_2022_X_USDC,
    decimals: decimals.DECIMALS_18,
    name: 'OT-cDAI-29DEC2022/USDC',
    symbol: 'OT-cDAI / USDC',
    logo: '/images/coins/ot-cdai_usdc.png',
    network: 'mainnet',
    genericName: 'Sushi LP',
    protocol: 'Compound',
    expiry: expiry.TIME_29_DEC_2022,
    tokenCategories: ['lp'],
  },
  {
    address: pools.OT_WXBTRFLY_21_APR_2022_X_USDC,
    decimals: decimals.DECIMALS_18,
    name: 'OT-wxBTRFLY-21APR2022/USDC',
    symbol: 'OT-wxBTRFLY / USDC',
    logo: '/images/coins/mainnet/ot-wxbtrfly_usdc.png',
    network: 'mainnet',
    genericName: 'Sushi LP',
    protocol: 'Redacted Cartel',
    expiry: expiry.TIME_21_APR_2022,
    tokenCategories: ['lp'],
  },
]
