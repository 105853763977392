import MarketCollection from '@/domains/collections/MarketCollection'
import MarketContract from '@/domains/contracts/MarketContract'
import Token from './Token'
import network from '../../app-config/network'

export default class Market {
  static className = 'Market'

  get className() {
    return 'Market'
  }

  constructor({ address, pair, createdEpoch, expiryEpoch, amms, network, spenderAddress, pool }) {
    this.address = address
    this.pair = pair
    this.createdEpoch = createdEpoch
    this.expiryEpoch = expiryEpoch
    this.amms = amms
    this.network = network
    this.spenderAddress = spenderAddress
    this.pool = pool
  }

  static query() {
    return MarketCollection.all()
  }

  contract({ signer, provider }) {
    return new MarketContract({ signer, provider, market: this })
  }

  get liquidityTypes() {
    return ['dual']
  }

  get chainId() {
    return network[this.network]
  }

  get tokenPair() {
    return Token.query().where('address', this.pair)
  }

  get token() {
    return Token.query().find(this.address)
  }

  get baseToken() {
    return Token.query().find(this.pair[0])
  }

  get quoteToken() {
    return Token.query().find(this.pair[1])
  }

  get pairName() {
    return this.token.symbol
  }

  get isExpired() {
    return new Date() > new Date(this.expiryEpoch * 1000)
  }
}
