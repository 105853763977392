export default {
  fill: {
    default: 'bg-water-600 text-white shadow-sm disabled:bg-gray-200 disabled:text-gray-400',
    interactive:
      'hover:bg-water-700 hover:text-white group-hover:bg-water-700 group-hover:text-white focus:ring-water-500',
  },
  gradient: {
    default:
      'duration-500 bg-gradient-to-r from-water-500 via-water-700 to-water-500 bg-pos-0 bg-size-200 disabled:from-gray-200 disabled:via-gray-200 disabled:to-gray-200 text-white shadow-sm  disabled:text-gray-400',
    interactive: 'hover:bg-pos-100 focus:bg-pos-100 group-hover:bg-pos-100 focus:ring-water-500',
  },
  text: {
    default: 'text-water-700 disabled:text-gray-400 disabled:bg-transparent',
    interactive:
      'hover:bg-water-100 hover:shadow group-hover:bg-water-100 group-hover:shadow focus:ring-water-500',
  },
  accent: {
    default: 'bg-water-100 text-water-700 shadow-sm disabled:bg-gray-200 disabled:text-gray-400',
    interactive: 'hover:bg-water-200 group-hover:bg-water-200 focus:ring-water-500',
  },
  outline: {
    default:
      'border-water-700 text-water-700 border shadow-sm disabled:border-gray-400 disabled:text-gray-400 disabled:bg-transparent',
    interactive:
      'hover:bg-water-200 hover:text-water-900 group-hover:bg-water-200 group-hover:text-water-900 focus:ring-water-500',
  },
}
