import { SortAlgorithm, FilterAlgorithm } from '../@types/algorithms'
import Fuse from 'fuse.js'

export default class ArrayDecorator {
  list: any[]

  constructor(list: any[]) {
    this.list = [...list]
  }

  sort(algo: (() => any) | undefined): ArrayDecorator {
    return new ArrayDecorator(this.list.slice().sort(algo))
  }

  reverse(): ArrayDecorator {
    return new ArrayDecorator([...this.list].reverse())
  }

  sortByRules(rules: SortAlgorithm | SortAlgorithm[] = []): ArrayDecorator {
    const wrappedRules = Array.isArray(rules) ? rules : [rules]
    return new ArrayDecorator(
      wrappedRules.reduce((list, rule) => list.slice().sort(rule), this.list)
    )
  }

  filterByRules(rules: FilterAlgorithm[]): ArrayDecorator {
    return new ArrayDecorator(rules.reduce((list, rule) => list.filter(rule), this.list))
  }

  filterBySearch(searchText: string, config: undefined): ArrayDecorator {
    if (this.list.length === 0) return this
    const firstItem = this.list[0]

    const searchConfig =
      config || (typeof firstItem === 'object' ? { keys: Object.keys(firstItem) } : {})

    return new ArrayDecorator(
      searchText
        ? new Fuse(this.list, searchConfig).search(searchText).map((item) => item.item)
        : this.list
    )
  }

  chunk(size: number): ArrayDecorator {
    return new ArrayDecorator(
      [...Array(Math.ceil(this.list.length / size))].map(() => this.list.splice(0, size))
    )
  }

  first(size: number): any {
    const list = this.list.slice()
    return typeof size === 'undefined' ? list[0] : list.slice(0, size)
  }

  last(size: number): any {
    const list = this.list.slice().reverse()
    return typeof size === 'undefined' ? list[0] : list.slice(0, size)
  }

  between(first: number, last: number): any[] {
    return this.list.slice(first, last)
  }

  format(): any[] {
    return this.list
  }
}
