<template>
  <pp-btn
    icon
    size="xs"
    variant="text"
    class="relative"
    @focus="expandTooltip = true"
    @blur="expandTooltip = false"
    @mouseenter="expandTooltip = true"
    @mouseleave="expandTooltip = false"
  >
    <svg
      stroke="currentColor"
      class="h-full w-full"
      viewBox="1.5 1.5 11 11"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7 2.1875C6.04818 2.1875 5.11773 2.46975 4.32632 2.99855C3.53491 3.52736 2.91808 4.27897 2.55383 5.15834C2.18959 6.0377 2.09428 7.00534 2.27997 7.93887C2.46566 8.8724 2.92401 9.72991 3.59705 10.403C4.27009 11.076 5.1276 11.5343 6.06113 11.72C6.99466 11.9057 7.9623 11.8104 8.84167 11.4462C9.72104 11.0819 10.4726 10.4651 11.0014 9.67368C11.5303 8.88227 11.8125 7.95182 11.8125 7C11.8125 5.72364 11.3055 4.49957 10.403 3.59705C9.50043 2.69453 8.27636 2.1875 7 2.1875Z"
        stroke-miterlimit="10"
      />
      <path
        d="M5.46875 5.53206C5.46875 5.53206 5.49172 5.05355 6.00387 4.64148C6.30766 4.39675 6.67187 4.32593 7 4.32101C7.29887 4.31718 7.56574 4.36667 7.72543 4.44269C7.99887 4.57284 8.53125 4.89058 8.53125 5.56624C8.53125 6.27718 8.06641 6.60011 7.53676 6.95531C7.00711 7.3105 6.86328 7.69605 6.86328 8.09445"
        stroke-miterlimit="10"
        stroke-linecap="round"
      />
      <path
        d="M6.83594 10.0627C7.13797 10.0627 7.38281 9.8179 7.38281 9.51587C7.38281 9.21384 7.13797 8.96899 6.83594 8.96899C6.53391 8.96899 6.28906 9.21384 6.28906 9.51587C6.28906 9.8179 6.53391 10.0627 6.83594 10.0627Z"
        fill="currentColor"
        stroke-width="0"
      />
    </svg>

    <pp-scale-transition
      :origin="direction === 'top' ? 'bottom' : 'top'"
      @after-enter="handleEnter"
      @before-leave="translateX = 0"
    >
      <div
        v-show="expandTooltip"
        class="absolute transform -translate-x-1/2 left-1/2 z-tooltip"
        :class="[yPosition]"
      >
        <svg
          class="w-5 absolute left-1/2 transform -translate-x-1/2 text-p-gray-50"
          :class="direction === 'top' ? 'top-full' : 'bottom-full rotate-180'"
          viewBox="0 0 12 7"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g filter="url(#filter0_d)">
            <path d="M6.00002 3L9.5 0H2.5L6.00002 3Z" fill="currentColor" />
          </g>
          <defs>
            <filter
              id="filter0_d"
              x="0.5"
              y="0"
              filterUnits="userSpaceOnUse"
              color-interpolation-filters="sRGB"
            >
              <feFlood flood-opacity="0" result="BackgroundImageFix" />
              <feColorMatrix
                in="SourceAlpha"
                type="matrix"
                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              />
              <feOffset dy="2" />
              <feGaussianBlur stdDeviation="1" />
              <feColorMatrix
                type="matrix"
                values="0 0 0 0 0 0 0 0 0 0.0160784 0 0 0 0 0.0235294 0 0 0 0.08 0"
              />
              <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
              <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
            </filter>
          </defs>
        </svg>

        <div :class="translatedPosition">
          <div
            ref="tooltip"
            class="bg-p-gray-50 shadow-md rounded-xl min-w-[176px] cursor-default select-text transition-transform duration-75"
            :style="`transform: translateX(${translateX}px)`"
          >
            <div class="relative">
              <div class="p-3 text-left font-normal whitespace-normal text-xs text-p-blue-500">
                <slot></slot>
              </div>
            </div>
          </div>
        </div>
      </div>
    </pp-scale-transition>
  </pp-btn>
</template>

<script>
  const tooltipWidth = 176
  const translateMap = {
    middle: '',
    left: '-translate-x-16',
    right: 'translate-x-16',
  }

  export default {
    props: {
      direction: { type: String, default: 'top' }, // top, bottom
      width: { type: [String, Number], default: tooltipWidth },
      translate: {
        type: String,
        default: 'middle',
        validator: (val) => typeof translateMap[val] === 'string',
      },
    },
    data() {
      return {
        expandTooltip: false,
        translateX: 0,
      }
    },
    computed: {
      yPosition() {
        return this.direction === 'top' ? 'bottom-full -translate-y-3' : 'top-full translate-y-3'
      },
      translatedPosition() {
        return translateMap[this.translate]
      },
    },
    methods: {
      handleEnter() {
        const tooltip = this.$refs.tooltip.getBoundingClientRect()
        const pageWidth = document.body.clientWidth

        const offsetAmount =
          tooltip.x < 0 // off to left
            ? 0 - tooltip.x // offset left
            : tooltip.x + tooltipWidth > pageWidth // off to right
            ? pageWidth - (tooltip.x + tooltipWidth) // offset right
            : 0 // no offset

        this.translateX = offsetAmount
      },
    },
  }
</script>
