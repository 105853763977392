<template>
  <div class="inline-flex items-center justify-center">
    <button
      class="
        h-5
        w-5
        border
        p-0.5
        rounded
        border-p-gray-300
        bg-white
        focus:outline-none focus:ring-2
        ring-offset-1 ring-water-600
        transition
      "
      :class="value ? 'bg-water-600' : ''"
      type="button"
      @click="$emit('input', !value)"
    >
      <pp-scale-transition>
        <svg
          v-if="value"
          xmlns="http://www.w3.org/2000/svg"
          class="h-full w-full text-white"
          viewBox="0 0 20 20"
          fill="currentColor"
        >
          <path
            fill-rule="evenodd"
            d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
            clip-rule="evenodd"
          />
        </svg>
      </pp-scale-transition>
    </button>

    <label
      v-if="$slots.default"
      @click="$emit('input', !value)"
      :for="`pp-input-${_uid}`"
      class="whitespace-nowrap ml-2 cursor-pointer"
    >
      <slot></slot>
    </label>

    <input :id="`pp-input-${_uid}`" type="checkbox" class="hidden" :value="value" />
  </div>
</template>

<script>
  import Vue from 'vue'

  export default Vue.extend({
    props: {
      value: { type: Boolean, required: true },
    },
  })
</script>
