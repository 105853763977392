<template>
  <component
    :is="tag"
    class="pp-tab"
    v-bind="$attrs"
    v-on="$listeners"
    type="button"
    :disabled="disabled"
    :class="[sizeClasses, colorClasses, fontWeightClasses]"
    :tabindex="active ? -1 : 0"
  >
    <slot></slot>

    <pp-scale-transition v-if="border !== 'none' && !disabled">
      <div v-show="active" class="absolute" :class="[borderClasses, borderColorClasses]"></div>
    </pp-scale-transition>
  </component>
</template>

<script>
  import Vue from 'vue'
  import HorizontalSizeMap from '@/ui-config/horizontal-size-map'
  import FixedSizeMap from '@/ui-config/fixed-size-map'
  import TabColorMap from '@/ui-config/tab-color-map'
  import ButtonColorMap from '@/ui-config/button-color-map'

  const BorderMap = {
    none: '',
    around: '',
    top: 'top-0 h-[2px] w-full',
    bottom: 'bottom-0 h-[2px] w-full',
    left: 'left-0 w-[2px] h-full',
    right: 'right-0 w-[2px] h-full',
  }

  export default Vue.extend({
    props: {
      color: { type: String, default: 'water' },
      variant: { type: String, default: 'fill' },
      active: { type: Boolean, default: false },
      size: { type: String, default: 'md' },
      border: { type: String, default: 'none' },
      tag: { type: String, default: 'button' },
      disabled: { type: Boolean, default: false },
      icon: { type: Boolean, default: false },
    },
    computed: {
      sizeClasses() {
        return (this.icon ? FixedSizeMap : HorizontalSizeMap)[this.size]
      },
      borderClasses() {
        return BorderMap[this.border]
      },
      colorClasses() {
        const { variant, color, active } = this
        return TabColorMap[color][variant][active ? 'active' : 'inactive']
      },
      fontWeightClasses() {
        return this.active && this.variant === 'text' ? 'font-semibold' : ''
      },
      borderColorClasses() {
        return ButtonColorMap[this.color].fill.default
      },
    },
  })
</script>
