<template>
  <component :is="tag" v-bind="$attrs" v-on="$listeners" class="table-row">
    <slot></slot>
  </component>
</template>

<script>
  import Vue from 'vue'

  export default Vue.extend({
    props: {
      tag: { type: String, default: 'tr' },
    },
  })
</script>
