<template>
  <transition
    v-on="$listeners"
    enter-active-class="transition"
    :leave-active-class="['transition', hideOnLeave ? 'hidden' : ''].join(' ')"
    enter-class="opacity-0"
    enter-to-class="opacity-100"
    leave-class="opacity-100"
    leave-to-class="opacity-0"
  >
    <slot></slot>
  </transition>
</template>

<script>
  import Vue from 'vue'

  export default Vue.extend({
    props: {
      hideOnLeave: { type: Boolean, default: false },
    },
  })
</script>
