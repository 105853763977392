import onboard from 'bnc-onboard'
import networkEnv from '@/app-config/networks'

export const NETWORK_ID = networkEnv.VUE_APP_ALLOWED_NETWORK_ID

export const RPC_URLS = [
  networkEnv.VUE_APP_RPC_NODE_1,
  networkEnv.VUE_APP_RPC_NODE_2,
  networkEnv.VUE_APP_RPC_NODE_3,
]

export const RPC_URL = RPC_URLS[Math.floor(Math.random() * RPC_URLS.length)]

const wallets = [
  { walletName: 'metamask', preferred: true },
  { walletName: 'imToken', rpcUrl: RPC_URL, preferred: true },
  { walletName: 'trust', rpcUrl: RPC_URL, preferred: true },
  { walletName: 'walletConnect', rpc: { [NETWORK_ID]: RPC_URL }, preferred: true },
  { walletName: 'coinbase', preferred: true },
  { walletName: 'walletLink', rpcUrl: RPC_URL, preferred: true },
]

const extraWallets = [
  { walletName: 'ledger', rpcUrl: RPC_URL, preferred: true },
  {
    walletName: 'trezor',
    appUrl: 'https://app.pendle.finance',
    email: 'hello@pendle.finance',
    rpcUrl: RPC_URL,
    preferred: true,
  },
]

export const WALLETS = NETWORK_ID == '43114' ? wallets : wallets.concat(extraWallets)

export const ONBOARD_SELECTED_WALLET_KEY = 'selectedWallet'

export const initOnboard = (subscriptions) => {
  return onboard({
    dappId: networkEnv.VUE_APP_BLOCK_NATIVE,
    networkId: NETWORK_ID,
    subscriptions,
    hideBranding: true,
    darkMode: true,
    walletSelect: { wallets: WALLETS },
    walletCheck: [
      { checkName: 'derivationPath' },
      { checkName: 'connect' },
      { checkName: 'accounts' },
    ],
  })
}
