import QueryCollection from '../QueryCollection'
import Market from '@/domains/entities/Market'
import Token from '@/domains/entities/Token'
import markets from '@/app-config/markets'
export default class MarketCollection extends QueryCollection {
  static primaryKey = 'address'

  static all() {
    return new MarketCollection(
      ...markets.map(
        (market) =>
          new Market({
            ...market,
            createdEpoch: market.created,
            expiryEpoch: market.expires,
            amms: market.tradablePlatforms,
            address: market.address.toLowerCase(),
            pair: market.pair.map((addr) => addr.toLowerCase()),
            tokenPair: market.pair.map((tokenAddr) => Token.query().find(tokenAddr)),
            expiresAt: new Date(market.expires * 1000),
            createdAt: new Date(market.created * 1000),
            pool: Token.query().find(market.address),
          })
      )
    )
  }
}
