<template>
  <span :title="`${value} ${currency}`">{{ formattedPrice }}</span>
</template>

<script>
  import Vue from 'vue'

  export default Vue.extend({
    props: {
      currency: { type: String, default: 'USD' },
      value: { type: [Number, String], default: 0 },
      locale: { type: String, default: 'en-US' },
      maxDecimal: { type: Number, default: 2 },
      minDecimal: { type: Number, default: 2 },
      notation: { type: String, default: 'standard' },
    },
    computed: {
      formattedPrice() {
        const { currency, value, locale, maxDecimal, minDecimal, notation } = this
        return new Intl.NumberFormat(locale, {
          style: 'currency',
          currency,
          maximumFractionDigits: maxDecimal,
          minimumFractionDigits: minDecimal,
          notation,
        }).format(value)
      },
    },
  })
</script>
