import { avalanche, decimals } from '../../../constants'

const pools = avalanche.POOLS
const expiry = avalanche.EXPIRY

export default [
  {
    address: pools.YT_JLP_WAVAX_PENDLE_28_DEC_2023_X_PENDLE,
    decimals: decimals.DECIMALS_18,
    name: 'YT-JLP(AVAX/PENDLE)-28Dec2023/PENDLE',
    symbol: 'YT-PA / PENDLE',
    logo: '/images/coins/avalanche/yt-pa_pendle.png',
    network: 'avalanche',
    genericName: 'Pendle LP',
    protocol: 'TraderJoe',
    expiry: expiry.TIME_28_DEC_2023,
    tokenCategories: ['lp'],
  },
  {
    address: pools.YT_QIUSDC_28_DEC_2023_X_USDC,
    decimals: decimals.DECIMALS_18,
    name: 'YT-qiUSDC-28Dec2023/USDC',
    symbol: 'YT-qiUSDC / USDC',
    logo: '/images/coins/avalanche/yt-qiusdc_usdc.png',
    network: 'avalanche',
    genericName: 'Pendle LP',
    protocol: 'BENQI',
    expiry: expiry.TIME_28_DEC_2023,
    tokenCategories: ['lp'],
  },
  {
    address: pools.YT_QIAVAX_28_DEC_2023_X_USDC,
    decimals: decimals.DECIMALS_18,
    name: 'YT-JLP(AVAX/USDC)-28Dec2023/USDC',
    symbol: 'YT-qiAVAX / USDC',
    logo: '/images/coins/avalanche/yt-qiavax_usdc.png',
    network: 'avalanche',
    genericName: 'Pendle LP',
    protocol: 'BENQI',
    expiry: expiry.TIME_28_DEC_2023,
    tokenCategories: ['lp'],
  },
  {
    address: pools.YT_XJOE_30_JUN_2022_X_USDC,
    decimals: decimals.DECIMALS_18,
    name: 'YT-xJOE-28Dec2023/USDC',
    symbol: 'YT-xJOE / USDC',
    logo: '/images/coins/avalanche/yt-xjoe_usdc.png',
    network: 'avalanche',
    genericName: 'Pendle LP',
    protocol: 'TraderJoe',
    expiry: expiry.TIME_30_JUN_2022,
    tokenCategories: ['lp'],
  },
  {
    address: pools.YT_WMEMO_24_FEB_2022_X_MIM,
    decimals: decimals.DECIMALS_18,
    name: 'YT-wMEMO-24FEB2022/MIM',
    symbol: 'YT-wMEMO / MIM',
    logo: '/images/coins/avalanche/yt-wmemo_mim.png',
    network: 'avalanche',
    genericName: 'Pendle LP',
    protocol: 'Wonderland',
    expiry: expiry.TIME_24_FEB_2022,
    tokenCategories: ['lp'],
  },
]
