<template>
  <pp-table>
    <thead>
      <slot name="headers"></slot>
    </thead>

    <tbody>
      <pp-tr v-if="groupedPaginatedData[0].length === 0">
        <pp-td colspan="100" class="text-center opacity-75">No data available.</pp-td>
      </pp-tr>

      <template v-else v-for="category in groupedPaginatedData[this.page - 1]">
        <pp-tr v-if="groupBy" :key="category.category" class="bg-p-blue-100">
          <pp-td :colspan="100">
            <div class="flex items-center">
              <span v-text="category.category" class="font-medium"></span>
              <pp-btn
                @click="$emit('update:groupBy', '')"
                variant="text"
                icon
                size="sm"
                class="ml-2"
              >
                <CloseIcon class="h-5 w-5" />
              </pp-btn>
            </div>
          </pp-td>
        </pp-tr>

        <pp-tr v-if="category.data.length === 0" :key="`${category.category}--empty-text`">
          <pp-td>No data available.</pp-td>
        </pp-tr>

        <slot v-else v-for="row in category.data" name="item" :item="row"></slot>
      </template>
    </tbody>
  </pp-table>
</template>

<script>
  import Vue from 'vue'
  import ArrayDecorator from '@/domains/ArrayDecorator'
  import CloseIcon from '../svg/solid/CloseIcon.vue'

  // const nestedAttr = 'token.address'
  const readNestedAttr = (nestedAttr, obj) =>
    nestedAttr.split('.').reduce((acc, attr) => acc?.[attr], obj)

  export default Vue.extend({
    components: { CloseIcon },
    props: {
      data: { type: Array, default: () => [] },
      search: { type: String, default: '' },
      searchConfig: { type: Object, default: undefined },
      rowsPerPage: { type: Number, default: undefined },
      page: { type: Number, default: 1 },
      groupBy: { type: String, default: '' },
      sortingRules: { type: Array, default: () => [] },
    },
    computed: {
      // activeSortingRules() {
      //   return this.sortingRules
      //     .filter((rule) => rule.sort.type !== 'none')
      //     .sort((a, b) => a.sort.activatedAt - b.sort.activatedAt)
      //     .map((rule) =>
      //       rule.sort.type === 'ascending'
      //         ? (a, b) =>
      //             a[rule.value] == b[rule.value] ? 0 : a[rule.value] > b[rule.value] ? 1 : -1
      //         : (a, b) =>
      //             a[rule.value] == b[rule.value] ? 0 : a[rule.value] < b[rule.value] ? 1 : -1
      //     )
      // },
      filteredBySearch() {
        return new ArrayDecorator(this.data).filterBySearch(this.search, this.searchConfig)
      },
      sortedByRules() {
        return this.filteredBySearch.sortByRules([
          ...this.sortingRules,
          (row) => (this.groupBy ? readNestedAttr(this.groupBy, row) : 1),
        ])
      },
      paginated() {
        return this.rowsPerPage
          ? this.sortedByRules.chunk(this.rowsPerPage).format()
          : [this.sortedByRules.format()]
      },
      groupedPaginatedData() {
        return this.paginated.map((rows) => {
          const groupedRows = rows.reduce((hash, row) => {
            const key = readNestedAttr(this.groupBy, row) || ''
            hash[key] = hash[key] ? [...hash[key], row] : [row]

            return hash
          }, {})

          return Object.keys(groupedRows).map((category) => ({
            category,
            data: groupedRows[category],
          }))
        })
      },
    },
  })
</script>
