import QueryCollection from '@/domains/QueryCollection'
import TokenIssuer from '@/domains/entities/TokenIssuer'
import yieldBearingAssets from '@/app-config/yield-bearing-assets'

export default class TokenIssuerCollection extends QueryCollection {
  static primaryKey = 'id'

  static all() {
    return new TokenIssuerCollection(
      ...yieldBearingAssets
        .map((asset) => ({
          ...asset,
          address: asset.address.toLowerCase(),
          underlyingTokenAddress: asset.underlyingTokenAddress.toLowerCase(),
          yieldBearingTokenAddress: asset.yieldBearingTokenAddress.toLowerCase(),
          rewardTokenAddresses: asset.rewardTokenAddresses.map((addr) => addr.toLowerCase()),
          mintables: asset.mintables.map((mintable) => ({
            ...mintable,
            yt: mintable.yt.toLowerCase(),
            ot: mintable.ot.toLowerCase(),
          })),
        }))
        .map((asset) => asset.mintables.map((mintable) => ({ ...mintable, ...asset })))
        .flat()
        .map(
          (asset) =>
            new TokenIssuer({
              ...asset,
              expiryEpoch: asset.expiry,
              otAddress: asset.ot,
              ytAddress: asset.yt,
            })
        )
    )
  }
}
