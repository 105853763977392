<template>
  <div
    v-bind="$attrs"
    v-on="$listeners"
    class="inline-flex items-center p-1 border border-water-500 rounded-lg hover:bg-blue-50 cursor-pointer"
  >
    <slot name="image">
      <img class="h-4 rounded-full" :alt="token.name" :src="token.img" />
    </slot>

    <div class="ml-1">
      <div class="text-sm" v-text="token.symbol" data-test="symbol"></div>
    </div>
  </div>
</template>

<script>
  export default {
    props: {
      token: { type: Object, required: true },
    },
  }
</script>
