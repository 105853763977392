<template>
  <div class="space-y-2 whitespace-nowrap p-4">
    <div class="text-water-600">
      <slot name="header">
        <div v-text="header"></div>
      </slot>
    </div>

    <div class="text-2xl font-medium">
      <slot name="content">
        <div v-text="content"></div>
      </slot>
    </div>

    <div class="text-water-600 text-sm">
      <slot name="subcontent">
        <div v-text="subcontent"></div>
      </slot>
    </div>
  </div>
</template>

<script>
  import Vue from 'vue'

  export default Vue.extend({
    props: {
      header: { type: String, default: '' },
      content: { type: String, default: '' },
      subcontent: { type: String, default: '' },
    },
  })
</script>
