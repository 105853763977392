import { Ot as SdkOT } from '@pendle/sdk'
import { sdkChainId } from '@/app-config/network'
import Token from '@/domains/entities/Token'
import Wallet from '@/domains/entities/Wallet'
import TokenAmount from '@/domains/entities/TokenAmount'

export default class OtCollectionContract {
  constructor({ otCollection, provider, signer }) {
    this.otCollection = otCollection
    this.provider = provider
    this.signer = signer
  }

  fetchRewards(wallet) {
    return new Promise((resolve, reject) => {
      SdkOT.methods({
        signer: this.signer,
        provider: this.provider,
        chainId: sdkChainId,
      })
        .fetchRewards(wallet)
        .then((response) => {
          resolve(
            response.map((reward) => ({
              origin: {
                token: Token.query().find(reward.address),
                source: new Wallet({ address: wallet }),
              },
              yields: reward.reward.map((tokenAmount) => ({
                yield: TokenAmount.fromSdkTokenAmount(tokenAmount),
                yieldType: 'reward',
                acquisitionMethod: 'accrue',
              })),
            }))
          )
        })
        .catch((error) => {
          reject(error)
        })
    })
  }
}
