import Vue from 'vue'
import App from './App2.vue'
import router from './router'
import store from './store'
import '@/assets/css/index.css'
import '@/plugins/register-global-states'
import * as Sentry from '@sentry/vue'
import { BrowserTracing } from '@sentry/tracing'

if (process.env.VUE_APP_ENV === 'production') {
  Sentry.init({
    Vue,
    dsn: 'https://67a896dab0b6484c98c6a37c35fbec23@o1048108.ingest.sentry.io/6025169',
    integrations: [
      new BrowserTracing({
        routingInstrumentation: Sentry.vueRouterInstrumentation(router),
        tracingOrigins: ['app.pendle.finance', /^\//],
      }),
    ],
    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 0.3,
    trackComponents: true,
  })
}

Vue.config.productionTip = false

const context = require.context('@/components/pendle', true, /^((?!index|\.unit\.).)*\.vue$/)

context.keys().forEach((file) => {
  const componentName = file.replace(/(\.\/|$)/g, '').split('.')[0]
  const component = context(file).default || context(file)
  Vue.component(componentName, component)
})

const createApp = () =>
  new Vue({
    router,
    store,
    render: (h) => h(App),
  }).$mount('#app')

if (window.location.search.includes('console=show')) {
  // @ts-ignore
  import('vconsole').then((response) => {
    new response.default()
    createApp()
  })
} else {
  setTimeout(() => {
    createApp()
  }, 500)
}
