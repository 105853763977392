import TokenAmount from '@/domains/entities/TokenAmount'
import { ethers } from 'ethers'
import { sdkChainId } from '@/app-config/network'
import Erc20Abi from '@/contracts/abis/ERC20'
import BigNumberjs from 'bignumber.js'

const uint256limit = new BigNumberjs(2).pow(new BigNumberjs(256)).minus(new BigNumberjs(1))

export default class TokenContract {
  constructor({ token, provider, signer }) {
    this.token = token
    this.provider = provider
    this.signer = signer
  }

  async balanceOf(walletAddress) {
    if (walletAddress) {
      // NOTE: special case for ETH. SDK v0.4.6 not supported as of 14/10/2021
      if (this.token.address === '0xeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeee') {
        const balance = await this.provider.getBalance(walletAddress)
        const ethBalance = ethers.utils.formatEther(balance)
        return new TokenAmount(this.token, ethBalance, false)
      }

      return new Promise((resolve, reject) => {
        TokenAmount.methods({
          signer: this.signer,
          provider: this.provider,
          chainId: sdkChainId,
        })
          .balancesOf({ tokens: [this.token], user: walletAddress })
          .then((response) => {
            resolve(response[0])
          })
          .catch((error) => {
            reject(error)
          })
      })
    } else {
      return Promise.resolve(new TokenAmount(this.token, 0, false))
    }
  }

  approve(spenderAddress, amount) {
    return new Promise((resolve, reject) => {
      this._contract()
        .approve(spenderAddress, amount)
        .then((response) => {
          response.wait(1).then(() => setTimeout(() => resolve(response), 2000))
        })
        .catch((error) => reject(error))
    })
  }

  allowance(walletAddress, spenderAddress) {
    if (walletAddress) {
      const skipApproval = ['all', spenderAddress].some((spender) =>
        this.token.ignoreApproval.includes(spender)
      )

      return skipApproval
        ? Promise.resolve(new TokenAmount(this.token, uint256limit.toString(), true))
        : new Promise((resolve, reject) => {
            this._contract()
              .allowance(walletAddress, spenderAddress)
              .then((response) => {
                resolve(new TokenAmount(this.token, response.toString(), true))
              })
              .catch((error) => reject(error))
          })
    } else {
      return Promise.resolve(new TokenAmount(this.token, 0, true))
    }
  }

  _contract() {
    return new ethers.Contract(this.token.address, Erc20Abi, this.signer || this.provider)
  }
}
