export default {
  fill: {
    inactive:
      'text-p-green-900 disabled:text-gray-400 disabled:bg-transparent hover:bg-p-green-200 focus:bg-p-green-200 focus:ring-p-green-500',
    active:
      'bg-p-green-700 text-white shadow-sm disabled:bg-gray-200 disabled:text-gray-400 focus:ring-0',
  },
  text: {
    inactive:
      'text-p-green-600 focus:text-p-green-600 hover:bg-p-green-200 focus:bg-p-green-200 disabled:text-gray-400 disabled:bg-transparent focus:ring-p-green-500',
    active:
      'bg-p-green-300 text-p-green-800 border-p-green-800 disabled:text-gray-400 focus:ring-0',
  },
  accent: {
    inactive:
      'text-p-green-900 bg-p-green-100 disabled:text-gray-400 disabled:bg-transparent hover:bg-p-green-200 focus:bg-p-green-200 focus:ring-p-green-500',
    active:
      'bg-p-green-700 text-white shadow-sm disabled:bg-gray-200 disabled:text-gray-400 focus:ring-0',
  },
}
