import { ethers } from 'ethers'
import { NETWORK_ID, RPC_URL, ONBOARD_SELECTED_WALLET_KEY, initOnboard } from '@/ui-config/defaults'

const defaultProvider = ethers.getDefaultProvider(RPC_URL)

const state = () => ({
  onboard: null,
  address: '',
  networkId: undefined,
  wallet: {},
  ready: false,
})

const getters = {
  identity(state) {
    const connected = state.wallet.provider
    const provider = connected
      ? new ethers.providers.Web3Provider(state.wallet.provider, 'any')
      : defaultProvider
    const signer = connected && provider.getSigner()
    return { provider, signer }
  },
  user(state) {
    const connected = state.wallet.provider
    const provider = connected
      ? new ethers.providers.Web3Provider(state.wallet.provider, 'any')
      : defaultProvider
    const signer = connected && provider.getSigner()
    return { provider, signer, address: state.address }
  },
  incorrectNetwork(state) {
    const connected = state.wallet.provider
    return connected ? NETWORK_ID !== state.networkId : false
  },
  connected(state) {
    return state.wallet.provider && state.ready
  },
  isMetaMask(state) {
    return state.wallet.name === 'MetaMask'
  },
}

const actions = {
  init({ commit, dispatch }) {
    const subscriptions = {
      address: (address) => {
        commit('SET_ADDRESS', address)
        // ! NOTE: address will be undefined when wallet is locked
        if (!address) commit('SET_READY', false)
      },
      network: (networkId) => {
        commit('SET_NETWORK_ID', networkId)
      },
      wallet: (wallet) => {
        if (wallet.provider) {
          commit('SET_WALLET', wallet)
          localStorage.setItem(ONBOARD_SELECTED_WALLET_KEY, wallet.name)
        } else {
          // ! when wallet is disconnected
          commit('SET_WALLET', {})
          localStorage.removeItem(ONBOARD_SELECTED_WALLET_KEY)
        }
      },
    }
    const onboard = initOnboard(subscriptions)
    commit('SET_ONBOARD', onboard)

    const previouslySelectedWallet = localStorage.getItem(ONBOARD_SELECTED_WALLET_KEY)
    if (!previouslySelectedWallet) return

    dispatch('setupWallet', previouslySelectedWallet)
  },
  connectWallet({ getters, dispatch }) {
    if (getters.connected) return

    const previouslySelectedWallet = localStorage.getItem(ONBOARD_SELECTED_WALLET_KEY)

    dispatch('setupWallet', previouslySelectedWallet)
  },
  async setupWallet({ commit, state }, walletName) {
    try {
      const selected = await state.onboard.walletSelect(walletName)

      if (selected) {
        const ready = await state.onboard.walletCheck()
        commit('SET_READY', ready)
      }
    } catch (error) {
      console.log(error)
    }
  },
  disconnectWallet({ state, getters }) {
    if (!getters.connected) return

    state.onboard.walletReset()
  },
}

const mutations = {
  SET_ONBOARD(state, onboard) {
    state.onboard = onboard
  },
  SET_ADDRESS(state, address) {
    state.address = address
  },
  SET_NETWORK_ID(state, networkId) {
    state.networkId = parseInt(networkId)
  },
  SET_WALLET(state, wallet) {
    state.wallet = wallet
  },
  SET_READY(state, ready) {
    state.ready = ready
  },
}

export default {
  namespaced: true,
  name: 'wallet',
  state,
  getters,
  actions,
  mutations,
}
