<template>
  <div class="flex flex-grow items-center px-2">
    <pp-token-icon :token="token" class="h-8 rounded-full"></pp-token-icon>

    <div class="ml-2 flex-grow">
      <pp-token-symbol :token="token" class="font-semibold"></pp-token-symbol>

      <pp-skeleton-loader v-if="secondaryTextLoading" class="h-4 w-8"></pp-skeleton-loader>
      <div v-else class="text-xs">{{ secondaryText }}</div>
    </div>

    <div v-if="variant === 'picker'">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        class="h-5 w-5"
        fill="none"
        viewBox="0 0 24 24"
        stroke="currentColor"
      >
        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 9l-7 7-7-7" />
      </svg>
    </div>
  </div>
</template>

<script>
  export default {
    props: {
      token: { type: Object, required: true },
      secondaryText: { type: String },
      secondaryTextLoading: { type: Boolean, default: false },
      variant: { type: String, default: 'fixed' },
    },
  }
</script>
