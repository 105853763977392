<template>
  <div class="py-2">
    <div class="bg-white shadow-pendle rounded-xl">
      <ul class="py-2 px-1">
        <li v-for="network in networkOptions" :key="network.name" class="p-1">
          <pp-btn
            :data-test="`${network.name}`"
            @click="handleNetworkChange(network.chainId)"
            size="xs"
            variant="text"
            class="flex w-full"
          >
            <img class="h-7 w-7" :src="network.src" />

            <span class="ml-2" v-text="network.name"></span>
          </pp-btn>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
  import { networkKey } from '@/app-config/networks'

  export default {
    data() {
      const networkOptions = [
        {
          name: 'Ethereum',
          chainId: 1,
          src: require('@/assets/images/coins/ETH.png'),
        },
        {
          name: 'Avalanche',
          chainId: 43114,
          src: require('@/assets/images/coins/avalanche.svg'),
        },
      ]

      if (process.env.NODE_ENV === 'development') {
        networkOptions.push({
          name: 'Mainnet Fork',
          chainId: 31337,
          src: require('@/assets/images/coins/ETH.png'),
        })
      }
      return {
        networkOptions,
      }
    },
    methods: {
      handleNetworkChange(chainId) {
        localStorage.setItem(networkKey, chainId)
        window.location.href = '/'
      },
    },
  }
</script>
