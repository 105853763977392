import { mainnet, decimals } from '../../../constants'

const tokens = mainnet.TOKENS

export default [
  {
    address: tokens.SLP_PENDLE_WETH,
    decimals: decimals.DECIMALS_18,
    name: 'PENDLE / ETH',
    symbol: 'PENDLE / ETH',
    logo: '/images/coins/mainnet/slp-pe.png',
    network: 'mainnet',
    genericName: 'Sushi LP',
    protocol: 'Sushiswap',
    sources: [`https://app.sushi.com/add/ETH/${tokens.PENDLE}`],
    tokenCategories: ['lp'],
  },
  {
    address: tokens.SLP_USDC_WETH,
    decimals: decimals.DECIMALS_18,
    name: 'ETH / USDC',
    symbol: 'ETH / USDC',
    logo: '/images/coins/mainnet/slp-ethusdc.png',
    network: 'mainnet',
    genericName: 'yieldBearing',
    protocol: 'Sushiswap',
    sources: [`https://app.sushi.com/add/ETH/${tokens.USDC}`],
    tokenCategories: ['lp'],
  },
]
