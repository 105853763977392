<template>
  <span>{{ formattedDate }}</span>
</template>

<script>
  import Vue from 'vue'

  export default Vue.extend({
    props: {
      value: { type: [Date, Number, String], default: undefined },
      locale: { type: String, default: 'en-US' },
      format: {
        type: String,
        default: 'medium',
        validator: (val) => ['full', 'long', 'medium', 'short'].indexOf(val) !== -1,
      },
    },
    computed: {
      formattedDate() {
        const { value, locale, format } = this
        return new Intl.DateTimeFormat(locale, { dateStyle: format, timeZone: 'UTC' }).format(
          new Date(value)
        )
      },
    },
  })
</script>
