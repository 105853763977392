<template>
  <span v-text="formattedNumber" :title="value"></span>
</template>

<script>
  import Vue from 'vue'
  import { formatNumber } from '@/assets/helpers'

  export default Vue.extend({
    props: {
      value: { type: undefined, required: true },
      maxDecimal: { type: Number, default: 3 },
      minDecimal: { type: Number, default: 3 },
      space: { type: Boolean, default: false },
      lowercase: { type: Boolean, default: false },
      humanize: { type: Boolean, default: true },
      currency: { type: String, default: undefined },
    },
    computed: {
      formattedNumber() {
        return formatNumber(this.value, this)
      },
    },
  })
</script>
