<template>
  <component :is="tag" class="pp-td" :class="alignment" v-bind="$attrs" v-on="$listeners">
    <slot></slot>
  </component>
</template>

<script>
  import Vue from 'vue'

  const alignMap = {
    center: 'text-center',
    right: 'text-right',
    left: 'text-left',
  }

  const vAlignMap = {
    top: 'align-top',
    middle: 'align-middle',
    bottom: 'align-bottom',
  }

  export default Vue.extend({
    props: {
      align: { type: String, default: 'left', validator: (val) => Object.keys(alignMap) !== -1 },
      valign: { type: String, default: 'top', validator: (val) => Object.keys(vAlignMap) !== -1 },
      tag: { type: String, default: 'td' },
    },
    computed: {
      alignment() {
        const { align, valign } = this
        return [alignMap[align], vAlignMap[valign]].join(' ')
      },
    },
  })
</script>
