<template>
  <span v-bind="$attrs" v-text="token.symbol"></span>
</template>

<script>
  export default {
    props: {
      token: { type: Object, required: true },
    },
  }
</script>
