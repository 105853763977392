<template>
  <span
    class="inline-flex rounded-full font-medium tracking-wide"
    :class="[sizeClasses, colorClasses]"
  >
    <slot></slot>
  </span>
</template>

<script>
  import Vue from 'vue'
  import ButtonColorMap from '@/ui-config/button-color-map'
  import HorizontalSizeMap from '@/ui-config/horizontal-size-map'

  export default Vue.extend({
    props: {
      color: { type: String, default: 'p-yellow' },
      variant: { type: String, default: 'accent' },
      size: { type: String, default: 'sm' },
    },
    computed: {
      colorClasses() {
        return ButtonColorMap[this.color][this.variant].default
      },
      sizeClasses() {
        return HorizontalSizeMap[this.size]
      },
    },
  })
</script>
