<template>
  <span>
    <pp-formatted-number :value="value" v-bind="$attrs"></pp-formatted-number>
    %
  </span>
</template>

<script>
  import Vue from 'vue'

  export default Vue.extend({
    props: {
      value: { type: Number, default: undefined },
    },
  })
</script>
