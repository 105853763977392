<template>
  <pp-btn tag="router-link" :to="to" variant="text">
    <svg
      xmlns="http://www.w3.org/2000/svg"
      class="h-5 w-5 mr-2"
      fill="none"
      viewBox="0 0 24 24"
      stroke="currentColor"
    >
      <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15 19l-7-7 7-7" />
    </svg>

    <span>Back</span>
  </pp-btn>
</template>

<script>
  export default {
    props: {
      to: { type: Object, required: true },
    },
  }
</script>
