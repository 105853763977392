import { avalanche } from '../../constants'

const tokens = avalanche.TOKENS
const pendle = avalanche.PENDLE
const forgeId = avalanche.FORGEID
const expiry = avalanche.EXPIRY

export default [
  {
    yieldBearingTokenAddress: tokens.QIUSDC,
    rewardTokenAddresses: [tokens.WAVAX, tokens.QI],
    underlyingTokenAddress: tokens.USDC,
    network: 'avalanche',
    address: pendle.ROUTER,
    forgeId: forgeId.BENQI,
    mintables: [
      {
        expiry: expiry.TIME_28_DEC_2023,
        yt: tokens.YT_QIUSDC_28_DEC_2023,
        ot: tokens.OT_QIUSDC_28_DEC_2023,
      },
    ],
  },
  {
    yieldBearingTokenAddress: tokens.QIAVAX,
    rewardTokenAddresses: [tokens.WAVAX, tokens.QI],
    underlyingTokenAddress: tokens.WAVAX,
    network: 'avalanche',
    address: pendle.ROUTER,
    forgeId: forgeId.BENQI,
    mintables: [
      {
        expiry: expiry.TIME_28_DEC_2023,
        yt: tokens.YT_QIAVAX_28_DEC_2023,
        ot: tokens.OT_QIAVAX_28_DEC_2023,
      },
    ],
  },
  {
    yieldBearingTokenAddress: tokens.JLP_WAVAX_PENDLE,
    rewardTokenAddresses: [],
    underlyingTokenAddress: tokens.JLP_WAVAX_PENDLE,
    network: 'avalanche',
    address: pendle.ROUTER,
    forgeId: forgeId.TRADERJOESIMPLE,
    mintables: [
      {
        expiry: expiry.TIME_28_DEC_2023,
        yt: tokens.YT_JLP_WAVAX_PENDLE_28_DEC_2023,
        ot: tokens.OT_JLP_WAVAX_PENDLE_28_DEC_2023,
      },
    ],
  },
  {
    yieldBearingTokenAddress: tokens.XJOE,
    rewardTokenAddresses: [tokens.JOE],
    underlyingTokenAddress: tokens.JOE,
    network: 'avalanche',
    address: pendle.ROUTER,
    forgeId: forgeId.XJOE,
    mintables: [
      {
        expiry: expiry.TIME_30_JUN_2022,
        yt: tokens.YT_XJOE_30_JUN_2022,
        ot: tokens.OT_XJOE_30_JUN_2022,
      },
    ],
  },
  {
    yieldBearingTokenAddress: tokens.WMEMO,
    rewardTokenAddresses: [tokens.WMEMO],
    underlyingTokenAddress: tokens.MEMO,
    network: 'avalanche',
    address: pendle.ROUTER,
    forgeId: forgeId.WONDERLAND,
    mintables: [
      {
        expiry: expiry.TIME_24_FEB_2022,
        yt: tokens.YT_WMEMO_24_FEB_2022,
        ot: tokens.OT_WMEMO_24_FEB_2022,
      },
    ],
  },
]
