import { avalanche, decimals } from '../../constants'

const tokens = avalanche.TOKENS
const expiry = avalanche.EXPIRY

export default [
  {
    address: tokens.OT_QIUSDC_28_DEC_2023,
    decimals: decimals.DECIMALS_6,
    name: 'OT-qiUSDC-28Dec2023',
    symbol: 'OT-qiUSDC',
    logo: '/images/coins/avalanche/ot-qiusdc.png',
    network: 'avalanche',
    genericName: 'OT',
    protocol: 'BENQI',
    expiry: expiry.TIME_28_DEC_2023,
    tokenCategories: ['ot'],
  },
  {
    address: tokens.OT_QIAVAX_28_DEC_2023,
    decimals: decimals.DECIMALS_18,
    name: 'OT-qiAVAX-28Dec2023',
    symbol: 'OT-qiAVAX',
    logo: '/images/coins/avalanche/ot-qiavax.png',
    network: 'avalanche',
    genericName: 'OT',
    protocol: 'BENQI',
    expiry: expiry.TIME_28_DEC_2023,
    tokenCategories: ['ot'],
  },
  {
    address: tokens.OT_JLP_WAVAX_PENDLE_28_DEC_2023,
    decimals: decimals.DECIMALS_18,
    name: 'OT-JLP(AVAX/PENDLE)-28Dec2023',
    symbol: 'OT-PA',
    logo: '/images/coins/avalanche/ot-joe-pa.png',
    network: 'avalanche',
    genericName: 'OT',
    protocol: 'TraderJoe',
    expiry: expiry.TIME_28_DEC_2023,
    tokenCategories: ['ot'],
  },
  {
    address: tokens.OT_XJOE_30_JUN_2022,
    decimals: decimals.DECIMALS_18,
    name: 'OT-xJOE-30Jun2022',
    symbol: 'OT-xJOE',
    logo: '/images/coins/avalanche/ot-xjoe.png',
    network: 'avalanche',
    genericName: 'OT',
    protocol: 'TraderJoe',
    expiry: expiry.TIME_30_JUN_2022,
    tokenCategories: ['ot'],
  },
  {
    address: tokens.OT_WMEMO_24_FEB_2022,
    decimals: decimals.DECIMALS_9,
    name: 'OT-wMEMO-24FEB2022',
    symbol: 'OT-wMEMO',
    logo: '/images/coins/avalanche/ot-wmemo.png',
    network: 'avalanche',
    genericName: 'OT',
    protocol: 'Wonderland',
    expiry: expiry.TIME_24_FEB_2022,
    tokenCategories: ['ot'],
  },
]
