<template>
  <span>{{ formattedDate }}</span>
</template>

<script>
  import Vue from 'vue'

  export default Vue.extend({
    props: {
      value: { type: [Date, Number, String], default: undefined },
      locale: { type: String, default: 'en-US' },
      format: {
        type: String,
        default: 'short',
        validator: (val) => ['full', 'short'].indexOf(val) !== -1,
      },
      timezone: { type: String, default: 'UTC' },
    },
    computed: {
      formattedDate() {
        const { value, locale, format, timezone } = this
        return new Intl.DateTimeFormat(locale, {
          hour: 'numeric',
          minute: 'numeric',
          timeZoneName: format,
          timeZone: timezone,
        }).format(new Date(value))
      },
    },
  })
</script>
