<template>
  <span class="pp-wallet-address" v-text="formattedAddress" :data-test="`address-${value}`"></span>
</template>

<script>
  import Vue from 'vue'

  export default Vue.extend({
    props: {
      value: { type: String, default: '' },
      front: { type: Number, default: 5 },
      back: { type: Number, default: 4 },
      uppercase: { type: Boolean, default: false },
    },
    computed: {
      formattedAddress() {
        const { value, back, front, uppercase } = this
        return (value ? `${value.slice(0, front)}...${value.slice(value.length - back)}` : '')[
          uppercase ? 'toUpperCase' : 'toLowerCase'
        ]()
      },
    },
  })
</script>

<style>
  .pp-wallet-address {
    font-variant-ligatures: no-contextual;
  }
</style>
