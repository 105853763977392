<template>
  <div>
    <div class="flex text-sm flex-wrap">
      <label :for="`input-${_uid}`" class="text-base">
        <slot name="label">
          {{ label }}
        </slot>
      </label>
      <div class="flex-grow ml-2"></div>

      <div class="inline-flex items-center">
        <span class="text-water-600">Balance:</span>

        <pp-skeleton-loader
          v-if="tokenBalance.is('loading')"
          class="inline-block w-24 ml-2"
        ></pp-skeleton-loader>

        <span
          v-if="tokenBalance.is('resolved')"
          class="ml-1 font-medium"
          :max-decimal="inputToken.decimals"
          :title="tokenBalance.response.formattedAmount()"
          v-text="new BigNumberjs(tokenBalance.response.formattedAmount()).toFormat()"
          data-test="balance"
        ></span>
      </div>
    </div>

    <div class="mt-2 relative">
      <div>
        <div
          class="group flex items-stretch focus-within:ring-2 focus-within:ring-offset-2 rounded-xl transition border"
          :class="[bgAndRingColor, borderColor]"
        >
          <div
            data-test="single-token-dropdown"
            v-if="showToken"
            class="bg-white rounded-l-xl w-44 border-r flex-shrink-0 flex items-center"
            :class="[borderColor, tokenOptions.length > 1 ? '' : 'px-2 py-1']"
          >
            <pp-select
              v-if="tokenOptions.length > 1"
              class="w-full"
              btn-class="text-left !px-2 !py-1 rounded-xl"
              :options="tokenOptions"
              :value="address"
              @input="$emit('update:address', $event)"
            >
              <pp-token-preview :token="inputToken"></pp-token-preview>

              <template v-slot:option="{ option, on, active }">
                <pp-tab class="block w-full text-left" v-on="on" :active="active">
                  <pp-token-preview :token="option.token"></pp-token-preview>
                </pp-tab>
              </template>
            </pp-select>

            <pp-token-preview v-else :token="inputToken"></pp-token-preview>
          </div>
          <numeric-input
            data-test="input"
            :id="`input-${_uid}`"
            :value="value"
            @input="$emit('input', $event)"
            @blur="interacted = true"
            @keydown="interacted = true"
            :maximum-decimal="inputToken.decimals"
            :disabled="disabled"
            v-bind="$attrs"
            class="bg-transparent outline-none px-4 py-2 h-12 block flex-grow font-medium min-w-1"
            placeholder="0.00"
          ></numeric-input>
        </div>

        <p v-if="interacted && error" class="p-1 text-sm text-p-red-600" v-text="error"></p>
      </div>

      <pp-btn
        @click="$emit('input', tokenBalance.response.formattedAmount())"
        data-test="max-input"
        type="button"
        class="absolute top-0 right-0 bg-white !py-0.5 border border-p-blue-300 disabled:border-p-gray-300 rounded-tr-xl rounded-bl-xl rounded-br-none rounded-tl-none"
        :disabled="disabled"
        variant="text"
        size="xs"
      >
        Max
      </pp-btn>
    </div>
  </div>
</template>

<script>
  import Vue from 'vue'
  import PromiseHandler, { createState } from '@/domains/PromiseHandler'
  import NumericInput from '@/components/forms/NumericInput.vue'
  import Token from '@/domains/entities/Token'
  import BigNumberjs from 'bignumber.js'
  import TokenAmount from '@/domains/entities/TokenAmount'

  export default Vue.extend({
    components: {
      NumericInput,
    },
    props: {
      value: { type: String, required: true },
      address: { type: String, required: true },
      rules: { type: Array, default: () => [] },
      balanceHolder: { type: Object, default: undefined },
      disabled: { type: Boolean, default: false },
      showToken: { type: Boolean, default: false },
      label: { type: String, default: 'Input' },
      validateBalance: { type: Boolean, default: true },
      addressOptions: { type: Array, default: () => [] },
    },
    inject: ['registerInput', 'unregisterInput'],
    data() {
      return {
        tokenBalance: createState(),
        form: null,
        interacted: false,
      }
    },
    watch: {
      value: {
        immediate: true,
        handler() {
          this.form?.updateError()
        },
      },
    },
    computed: {
      tokenOptions() {
        return [...new Set([...this.addressOptions, this.address])].map((addr) => {
          const token = Token.query().find(addr)
          return {
            token,
            value: addr,
            text: token.symbol,
          }
        })
      },
      inputToken() {
        return Token.query().find(this.address) || { decimals: 18 }
      },
      preferredBalanceHolder() {
        return (
          this.balanceHolder || this.inputToken.contract(this.$store.getters['wallet/identity'])
        )
      },
      defaultRules() {
        return [
          (val) =>
            new BigNumberjs(val || 0).lte(
              new BigNumberjs(this.tokenBalance.response.formattedAmount())
            ) || 'Amount exceeded available balance',
        ]
      },
      error() {
        return [...(this.validateBalance ? this.defaultRules : []), ...this.rules]
          .map((rule) => rule(this.value))
          .find((validation) => typeof validation === 'string')
      },
      borderColor() {
        return this.disabled
          ? 'border-p-gray-300'
          : this.error && this.interacted
          ? 'border-p-red-300'
          : 'border-p-blue-300'
      },
      bgAndRingColor() {
        return this.disabled
          ? 'bg-p-gray-100'
          : this.error && this.interacted
          ? 'focus-within:ring-p-red-600 bg-p-red-100'
          : 'focus-within:ring-p-blue-600 bg-p-blue-100'
      },
    },
    methods: {
      BigNumberjs,
      fetchTokenBalance() {
        new PromiseHandler(
          () => this.preferredBalanceHolder.balanceOf(this.$store.state.wallet.address),
          this.tokenBalance
        ).execute()
      },
    },
    mounted() {
      this.registerInput?.(this)
      this.form?.updateError()
    },
    updated() {
      this.form?.updateError()
    },
    beforeDestroy() {
      this.unregisterInput?.(this)
    },
    created() {
      this.tokenBalance.response = new TokenAmount(this.inputToken, 0)
      this.fetchTokenBalance()
    },
  })
</script>
