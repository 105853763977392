import { mainnet, decimals } from '../../../constants'

const pools = mainnet.POOLS
const expiry = mainnet.EXPIRY

export default [
  {
    address: pools.YT_SLP_PENDLE_WETH_29_DEC_2022_X_PENDLE,
    decimals: decimals.DECIMALS_18,
    name: 'YT-SLP(PENDLE/ETH)-29Dec2022/PENDLE',
    symbol: 'YT-PE / PENDLE',
    logo: '/images/coins/mainnet/yt-pe_pendle.png',
    network: 'mainnet',
    genericName: 'Pendle LP',
    protocol: 'Sushi',
    expiry: expiry.TIME_29_DEC_2022,
    tokenCategories: ['lp'],
  },
  {
    address: pools.YT_SLP_USDC_WETH_29_DEC_2022_X_USDC,
    decimals: decimals.DECIMALS_18,
    name: 'YT-SLP(ETH/USDC)-29Dec2022/USDC',
    symbol: 'YT-ETHUSDC / USDC',
    logo: '/images/coins/mainnet/yt-ethusdc_usdc.png',
    network: 'mainnet',
    genericName: 'Pendle LP',
    protocol: 'Sushi',
    expiry: expiry.TIME_29_DEC_2022,
    tokenCategories: ['lp'],
  },
  {
    address: pools.YT_AUSDC_30_DEC_2021_X_USDC,
    decimals: decimals.DECIMALS_18,
    name: 'YT-aUSDC-30DEC2021/USDC',
    symbol: 'YT-aUSDC / USDC',
    logo: '/images/coins/yt-ausdc_usdc.png',
    network: 'mainnet',
    genericName: 'Pendle LP',
    protocol: 'Aave-v2',
    expiry: expiry.TIME_30_DEC_2021,
    tokenCategories: ['lp'],
  },
  {
    address: pools.YT_AUSDC_29_DEC_2022_X_USDC,
    decimals: decimals.DECIMALS_18,
    name: 'YT-aUSDC-29DEC2022/USDC',
    symbol: 'YT-aUSDC / USDC',
    logo: '/images/coins/yt-ausdc_usdc.png',
    network: 'mainnet',
    genericName: 'Pendle LP',
    protocol: 'Aave-v2',
    expiry: expiry.TIME_29_DEC_2022,
    tokenCategories: ['lp'],
  },
  {
    address: pools.YT_CDAI_30_DEC_2021_X_USDC,
    decimals: decimals.DECIMALS_18,
    name: 'YT-cDAI-30DEC2021/USDC',
    symbol: 'YT-cDAI / USDC',
    logo: '/images/coins/yt-cdai_usdc.png',
    network: 'mainnet',
    genericName: 'Pendle LP',
    protocol: 'Compound',
    expiry: expiry.TIME_30_DEC_2021,
    tokenCategories: ['lp'],
  },
  {
    address: pools.YT_CDAI_29_DEC_2022_X_USDC,
    decimals: decimals.DECIMALS_18,
    name: 'YT-cDAI-29DEC2022/USDC',
    symbol: 'YT-cDAI / USDC',
    logo: '/images/coins/yt-cdai_usdc.png',
    network: 'mainnet',
    genericName: 'Pendle LP',
    protocol: 'Compound',
    expiry: expiry.TIME_29_DEC_2022,
    tokenCategories: ['lp'],
  },
  {
    address: pools.YT_WXBTRFLY_21_APR_2022_X_USDC,
    decimals: decimals.DECIMALS_18,
    name: 'YT-wxBTRFLY-21APR2022/USDC',
    symbol: 'YT-wxBTRFLY / USDC',
    logo: '/images/coins/mainnet/yt-wxbtrfly_usdc.png',
    network: 'mainnet',
    genericName: 'Pendle LP',
    protocol: 'Redacted Cartel',
    expiry: expiry.TIME_21_APR_2022,
    tokenCategories: ['lp'],
  },
]
