import tokens from '@/app-config/tokens'
import QueryCollection from '@/domains/QueryCollection'
import TokenCollectionContract from '@/domains/contracts/TokenCollectionContract'
import Token from '@/domains/entities/Token'

export default class TokenCollection extends QueryCollection {
  static primaryKey = 'address'

  static all() {
    return new TokenCollection(
      ...tokens.map(
        (token) =>
          new Token({
            ...token,
            address: token.address.toLowerCase(),
            img: token.logo ? require(`@/assets${token.logo}`) : token.img,
            expiresAt: token.expiry ? new Date(token.expiry * 1000) : undefined,
            sources: token.sources || [],
            ignoreApproval: token.ignoreApproval || [],
          })
      )
    )
  }

  contract({ provider, signer }) {
    return new TokenCollectionContract({ tokenCollection: this, provider, signer })
  }
}
