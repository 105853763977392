import NullPromise from '@/domains/NullPromise'
import * as Sentry from '@sentry/browser'

export const createState = (additionalVal = {}) => ({
  state: 'idle',
  nonce: 0,
  lastResolvedAt: null,
  lastRejectedAt: null,
  response: null,
  error: null,
  ...additionalVal,
  is(value) {
    const config = {
      'first-load': this.state === 'loading' && !this.lastResolvedAt,
      'subsequent-load': this.state === 'loading' && this.lastResolvedAt,
      'loaded-once': !!this.lastResolvedAt,
    }

    return value in config
      ? config[value]
      : value === this.state
  },
})
export default class PromiseHandler {
  constructor(handler, state = createState()) {
    this.handler = handler
    this.state = state
  }

  execute(config = {}) {
    const defaultOptions = { force: false, log: false, error: false, timeout: 0 }
    const options = { ...defaultOptions, ...config }
    // const executionNonce = this.state.nonce

    if (!options.force && this.state.state === 'loading') {
      return new NullPromise()
    } else {
      this.state.state = 'loading'
      const executionNonce = ++this.state.nonce

      return new Promise((resolve, reject) => {
        setTimeout(
          () => {
            this.handler()
              .then((response) => {
                if (options.error) throw 'Intentional promise rejection'
                if (options.log) console.log(response)

                if (executionNonce === this.state.nonce) {
                  this.state.state = 'resolved'
                  this.state.lastResolvedAt = new Date()
                  this.state.response = response
                }

                resolve(response)
              })
              .catch((error) => {
                Sentry.captureException(error)
                if (options.log) console.log(error)

                if (executionNonce === this.state.nonce) {
                  this.state.state = 'rejected'
                  this.state.lastRejectedAt = new Date()
                  this.state.error = error
                }

                reject(error)
              })

            // reject if the promise is loading for more than the specified timeout
            if (this.state.state === 'loading' && options.timeout > 0) {
              setTimeout(() => {
                const error = { message: 'Timeout' }
                this.state.state = 'rejected'
                this.state.lastRejectedAt = new Date()
                this.state.error = error

                reject(error)
              }, options.timeout)
            }
          },
          process.env.NODE_ENV === 'development' ? 1000 : 0
        )
      })
    }
  }
}
