<template>
  <component
    :is="tag"
    class="pp-btn"
    :class="[sizeClasses, colorClasses, iconClasses]"
    v-bind="$attrs"
    :disabled="loading || disabled"
    v-on="{ ...$listeners, click: (event) => (loading ? () => null : $emit('click', event)) }"
  >
    <pp-scale-transition hide-on-leave>
      <div class="flex items-center" v-if="loading">
        <loading-icon class="h-5 w-5"></loading-icon>
      </div>

      <div v-else class="flex items-center">
        <slot></slot>
      </div>
    </pp-scale-transition>
  </component>
</template>

<script>
  import Vue from 'vue'
  import ButtonColorMap from '@/ui-config/button-color-map'
  import HorizontalSizeMap from '@/ui-config/horizontal-size-map'
  import FixedSizeMap from '@/ui-config/fixed-size-map'
  import LoadingIcon from '@/components/svg/solid/LoadingIcon'

  export default Vue.extend({
    components: {
      LoadingIcon,
    },
    props: {
      color: { type: String, default: 'p-blue' },
      variant: { type: String, default: 'gradient' },
      active: { type: Boolean, default: false },
      size: { type: String, default: 'md' },
      icon: { type: Boolean, default: false },
      tag: { type: String, default: 'button' },
      loading: { type: Boolean, default: false },
      interactive: { type: Boolean, default: true },
      disabled: { type: Boolean, default: false },
    },
    computed: {
      colorClasses() {
        const { color, variant } = this
        const colorStyles = ButtonColorMap[color][variant]
        const classes = [colorStyles.default]
        if (this.interactive) classes.push(colorStyles.interactive)

        return classes.join(' ')
      },
      sizeClasses() {
        const { icon, size } = this
        return (icon ? FixedSizeMap : HorizontalSizeMap)[size]
      },
      iconClasses() {
        return this.icon ? 'rounded-full' : ''
      },
    },
  })
</script>
