export default {
  fill: {
    inactive:
      'text-p-blue-900 disabled:text-gray-400 disabled:bg-transparent hover:bg-p-blue-200 focus:bg-p-blue-200 focus:ring-p-blue-500',
    active:
      'bg-p-blue-700 text-white shadow-sm disabled:bg-gray-200 disabled:text-gray-400 focus:ring-0',
  },
  text: {
    inactive:
      'text-p-blue-600 focus:text-p-blue-600 hover:bg-p-blue-200 focus:bg-p-blue-200 disabled:text-gray-400 disabled:bg-transparent focus:ring-p-blue-500',
    active: 'bg-p-blue-300 text-p-blue-800 border-p-blue-800 disabled:text-gray-400 focus:ring-0',
  },
  accent: {
    inactive:
      'text-p-blue-900 bg-p-blue-100 disabled:text-gray-400 disabled:bg-transparent hover:bg-p-blue-200 focus:bg-p-blue-200 focus:ring-p-blue-500',
    active:
      'bg-p-blue-700 text-white shadow-sm disabled:bg-gray-200 disabled:text-gray-400 focus:ring-0',
  },
}
