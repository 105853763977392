<template>
  <div class="inline-flex items-center">
    <slot name="image">
      <img class="h-8 rounded-full" :alt="token.name" :src="token.img" />
    </slot>

    <div class="ml-2">
      <div class="font-semibold" v-text="token.symbol" data-test="symbol"></div>

      <div class="flex items-center">
        <div class="text-sm opacity-70">
          <pp-formatted-date
            v-if="subtitle === 'expiry' && token.expiry"
            :value="token.expiry * 1000"
            data-test="expiry"
          ></pp-formatted-date>
          <span v-else v-text="token[subtitle]"></span>
        </div>
        <slot name="status-indicator"></slot>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    props: {
      token: { type: Object, required: true },
      subtitle: { type: String, default: 'expiry' },
    },
  }
</script>
