import mainnetNetwork from './mainnet'
import localDevNetwork from './local'
import avalancheNetwork from './avalanche'

const networks = {
  1: mainnetNetwork,
  31337: localDevNetwork,
  43114: avalancheNetwork,
}

export const networkKey = 'preferred-network'

const validNetworks = Object.keys(networks)
const lastUsedNetwork = localStorage.getItem(networkKey)
export const currentNetwork = lastUsedNetwork
  ? validNetworks.includes(lastUsedNetwork)
    ? lastUsedNetwork
    : process.env.VUE_APP_ALLOWED_NETWORK_ID
  : process.env.VUE_APP_ALLOWED_NETWORK_ID

localStorage.setItem(networkKey, currentNetwork)

export default networks[currentNetwork]
