<template>
  <div class="shadow-md bg-white">
    <nav class="container mx-auto h-full">
      <navbar-desktop
        class="hidden lg:flex h-full"
        :internal-links="internalLinks"
      ></navbar-desktop>

      <navbar-mobile class="flex lg:hidden h-full" :internal-links="internalLinks"></navbar-mobile>
    </nav>
  </div>
</template>

<script>
  import NavbarDesktop from './navbar/NavbarDesktop.vue'
  import NavbarMobile from './navbar/NavbarMobile.vue'
  import networkEnv from '@/app-config/networks'

  const defaultLinks = [
    {
      title: 'Market',
      url: '/market',
    },
    {
      title: 'Dashboard',
      url: '/dashboard',
    },
    {
      title: 'Trade',
      url: '/trade',
    },
    {
      title: 'Farm',
      url: '/farm',
    },
  ]

  const avalancheLinks = [
    {
      title: 'Zap',
      url: '/zap',
    },
  ]

  export default {
    components: {
      NavbarDesktop,
      NavbarMobile,
    },
    computed: {
      internalLinks() {
        return networkEnv.VUE_APP_NETWORK_ID == 43114
          ? [...defaultLinks, ...avalancheLinks]
          : defaultLinks
      },
    },
  }
</script>
