<template>
  <div class="inline-flex items-center">
    <div class="w-16 flex flex-row-reverse">
      <img
        v-for="(token, index) in market.tokenPair.slice().reverse()"
        :key="token.address"
        :src="token.img"
        :alt="token.name"
        class="w-8 transform inline-block"
        :class="index === 0 ? '-translate-x-2' : ''"
      />
    </div>

    <div class="text-left">
      <div
        class="font-semibold"
        v-text="market.pool.symbol"
        :class="titleClass"
        data-test="symbol"
      ></div>
      <pp-formatted-date
        v-if="subtitle === 'expiration'"
        :class="subtitleClass"
        :value="market.expiresAt"
      ></pp-formatted-date>
      <div
        v-else
        class="text-water-600 text-sm"
        :class="subtitleClass"
        v-text="market.pool[subtitle]"
        data-test="platform"
      ></div>
    </div>
  </div>
</template>

<script>
  import Market from '@/domains/entities/Market'

  export default {
    props: {
      address: { type: String, required: true },
      subtitle: {
        type: String,
        default: 'protocol',
        validator: (val) => ['protocol', 'expiration'].indexOf(val) !== -1,
      },
      titleClass: { type: String, default: '' },
      subtitleClass: { type: String, default: '' },
    },
    computed: {
      market() {
        return Market.query().find(this.address)
      },
    },
  }
</script>
