export default {
  name: 'Avalanche',
  icon: require('@/assets/images/coins/avalanche.svg'),
  VUE_APP_ALLOWED_NETWORK_ID: 43114,
  VUE_APP_BLOCK_NATIVE: '67bf8c35-a57b-4313-aab7-faa573f552d3',
  VUE_APP_RPC_NODE_1: 'https://api.avax.network/ext/bc/C/rpc',
  VUE_APP_RPC_NODE_2: 'https://api.avax.network/ext/bc/C/rpc',
  VUE_APP_RPC_NODE_3: 'https://api.avax.network/ext/bc/C/rpc',
  VUE_APP_NETWORK_ID: 43114,
  VUE_APP_SUBGRAPH_URL: 'https://api.thegraph.com/subgraphs/name/ngfam/pendle-avalanche',
  VUE_APP_SUBGRAPH_2_URL: 'https://api.thegraph.com/subgraphs/name/ngfam/pendle-avalanche',
  VUE_APP_SUSHI_SUBGRAPH_URL: 'https://api.thegraph.com/subgraphs/name/sushiswap/exchange',
  VUE_APP_NETWORK_ENV: 'avalanche',
  VUE_APP_ENV: 'production',
}
