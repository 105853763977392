export default {
  fill: {
    default: 'bg-p-red-600 text-white shadow-sm disabled:bg-gray-300 disabled:text-gray-400',
    interactive:
      'hover:bg-p-red-700 hover:text-white group-hover:bg-p-red-700 group-hover:text-white focus:ring-p-red-500',
  },
  gradient: {
    default:
      'duration-500 bg-gradient-to-r from-p-red-500 via-p-red-700 to-p-red-500 bg-pos-0 bg-size-200 disabled:from-gray-200 disabled:via-gray-200 disabled:to-gray-200 text-white shadow-sm  disabled:text-gray-400',
    interactive: 'hover:bg-pos-100 focus:bg-pos-100 group-hover:bg-pos-100 focus:ring-p-red-500',
  },
  text: {
    default: 'text-p-red-700 disabled:text-gray-400 hover:bg-transparent',
    interactive:
      'hover:bg-p-red-100 hover:shadow group-hover:bg-p-red-100 group-hover:shadow focus:ring-p-red-500',
  },
  accent: {
    default: 'bg-p-red-100 text-p-red-700 shadow-sm disabled:bg-gray-200 disabled:text-gray-400',
    interactive: 'hover:bg-p-red-200 group-hover:bg-p-red-200 focus:ring-p-red-500',
  },
  outline: {
    default:
      'border-p-red-700 text-p-red-700 border shadow-sm disabled:border-gray-400 disabled:text-gray-400 disabled:bg-transparent',
    interactive:
      'hover:bg-p-red-200 hover:text-p-red-900 group-hover:bg-p-red-200 group-hover:text-p-red-900 focus:ring-p-red-500',
  },
}
