import Market from './Market'
import PendleMarketCollection from '@/domains/collections/PendleMarketCollection'
import YT from './YT'
import OT from './OT'
import TokenIssuer from './TokenIssuer'
import PendleMarketContract from '@/domains/contracts/PendleMarketContract'
import { currentNetwork } from '@/app-config/networks'

export default class PendleMarket extends Market {
  static query() {
    return PendleMarketCollection.all()
  }

  get tokenIssuer() {
    return TokenIssuer.query().findBy(
      (press) =>
        press.expiryEpoch === this.expiryEpoch &&
        (press.otAddress === this.ot?.address || press.ytAddress === this.yt?.address)
    )
  }

  get createdAt() {
    return new Date(this.createdEpoch * 1000)
  }

  get expiresAt() {
    return new Date(this.expiryEpoch * 1000)
  }

  get freezesAt() {
    // TODO: This will break if we add new markets
    const secondsToFreeze =
      (1 /
        (currentNetwork == '43114' || this.pool.name.toLowerCase().includes('wxbtrfly')
          ? 360
          : 20)) *
      (this.expiryEpoch - this.createdEpoch)
    const frozenEpoch = this.expiryEpoch - secondsToFreeze
    return new Date(frozenEpoch * 1000)
  }

  get yt() {
    return YT.query().findBy('address', this.pair)
  }

  get ot() {
    return OT.query().findBy('address', this.pair)
  }

  get liquidityTypes() {
    return this.yt ? ['single', 'dual'] : ['dual']
  }

  get status() {
    if (new Date() > this.expiresAt) {
      return 'expired'
    } else if (new Date() > this.freezesAt) {
      return 'frozen'
    }
    return 'active'
  }

  statusIs(status) {
    return {
      active: this.status !== 'frozen' && this.status !== 'expired',
      inactive: this.status === 'frozen' || this.status === 'expired',
      frozen: this.status === 'frozen',
      expired: this.status === 'expired',
    }[status]
  }

  contract({ signer, provider }) {
    return new PendleMarketContract({ signer, provider, market: this })
  }
}
