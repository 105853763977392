<template>
  <div class="prose prose-water" v-bind="$attrs" v-on="$listeners">
    <slot></slot>
  </div>
</template>

<script>
  import Vue from 'vue'

  export default Vue.extend({
    props: {
      tag: { type: String, default: 'div' },
    },
  })
</script>
