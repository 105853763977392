import { sdkChainId } from '@/app-config/network'
import { UniForkMarket } from '@pendle/sdk'

export default class MarketContract {
  constructor({ signer, provider, market }) {
    this.signer = signer
    this.provider = provider
    this.market = market
  }

  addDualDetails({ tokenAmount, slippage }) {
    return this._sdkMarketMethods().addDualDetails(tokenAmount, parseFloat(slippage) / 100)
  }

  addDual({ tokenAmounts, slippage }) {
    return new Promise((resolve, reject) => {
      this._sdkMarketMethods()
        .addDual(tokenAmounts, parseFloat(slippage) / 100)
        .then((response) => response.wait(1).then(() => setTimeout(() => resolve(response), 2000)))
        .catch((error) => reject(error))
    })
  }

  removeDualDetails({ percentage, slippage }) {
    return this._sdkMarketMethods().removeDualDetails(
      parseFloat(percentage) / 100,
      parseFloat(slippage) / 100
    )
  }

  removeDual({ percentage, slippage }) {
    return new Promise((resolve, reject) => {
      this._sdkMarketMethods()
        .removeDual(parseFloat(percentage) / 100, parseFloat(slippage) / 100)
        .then((response) => response.wait(1).then(() => setTimeout(() => resolve(response), 2000)))
        .catch((error) => reject(error))
    })
  }

  _sdkMarketMethods() {
    const market = UniForkMarket.find(this.market.address, sdkChainId)

    return market.methods({
      signer: this.signer,
      provider: this.provider,
      chainId: sdkChainId,
    })
  }
}
