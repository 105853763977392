<template>
  <div class="inline-block">
    <pp-formatted-number
      :value="tokenAmount.formattedAmount()"
      v-bind="$attrs"
    ></pp-formatted-number>
    <pp-token-symbol class="ml-1" :token="tokenAmount.token"></pp-token-symbol>
  </div>
</template>

<script>
  import Vue from 'vue'

  export default Vue.extend({
    props: {
      tokenAmount: { type: Object, required: true },
    },
  })
</script>
