export default {
  name: 'Mainnet Fork',
  icon: require('@/assets/images/coins/ETH.png'),
  VUE_APP_ALLOWED_NETWORK_ID: 31337,
  VUE_APP_BLOCK_NATIVE: '67bf8c35-a57b-4313-aab7-faa573f552d3',
  VUE_APP_RPC_NODE_1: 'http://127.0.0.1:8545/',
  VUE_APP_RPC_NODE_2: 'http://127.0.0.1:8545/',
  VUE_APP_RPC_NODE_3: 'http://127.0.0.1:8545/',
  VUE_APP_NETWORK_ID: 31337,
  VUE_APP_SUBGRAPH_URL: 'https://api.thegraph.com/subgraphs/name/ngfam/pendle',
  VUE_APP_SUBGRAPH_2_URL: 'https://api.thegraph.com/subgraphs/name/ngfam/pendle',
  VUE_APP_SUSHI_SUBGRAPH_URL: 'https://api.thegraph.com/subgraphs/name/sushiswap/exchange',
  VUE_APP_NETWORK_ENV: 'local',
  VUE_APP_ENV: 'production',
}
