import { mainnet } from '../../constants'

const tokens = mainnet.TOKENS
const pendle = mainnet.PENDLE
const forgeId = mainnet.FORGEID
const expiry = mainnet.EXPIRY

export default [
  {
    yieldBearingTokenAddress: tokens.AUSDC,
    rewardTokenAddresses: [tokens.STKAAVE],
    underlyingTokenAddress: tokens.USDC,
    network: 'mainnet',
    address: pendle.ROUTER,
    forgeId: forgeId.AAVE_V2,
    mintables: [
      {
        expiry: expiry.TIME_30_DEC_2021,
        yt: tokens.YT_AUSDC_30_DEC_2021,
        ot: tokens.OT_AUSDC_30_DEC_2021,
      },
      {
        expiry: expiry.TIME_29_DEC_2022,
        yt: tokens.YT_AUSDC_29_DEC_2022,
        ot: tokens.OT_AUSDC_29_DEC_2022,
      },
    ],
  },
  {
    yieldBearingTokenAddress: tokens.CDAI,
    rewardTokenAddresses: [tokens.COMP],
    underlyingTokenAddress: tokens.DAI,
    network: 'mainnet',
    address: pendle.ROUTER,
    forgeId: forgeId.COMPOUND_V2,
    mintables: [
      {
        expiry: expiry.TIME_30_DEC_2021,
        yt: tokens.YT_CDAI_30_DEC_2021,
        ot: tokens.OT_CDAI_30_DEC_2021,
      },
      {
        expiry: expiry.TIME_29_DEC_2022,
        yt: tokens.YT_CDAI_29_DEC_2022,
        ot: tokens.OT_CDAI_29_DEC_2022,
      },
    ],
  },
  {
    yieldBearingTokenAddress: tokens.SLP_PENDLE_WETH,
    rewardTokenAddresses: [],
    underlyingTokenAddress: tokens.SLP_PENDLE_WETH,
    network: 'mainnet',
    address: pendle.ROUTER,
    forgeId: forgeId.SUSHISWAPSIMPLE,
    mintables: [
      {
        expiry: expiry.TIME_29_DEC_2022,
        yt: tokens.YT_SLP_PENDLE_WETH_29_DEC_2022,
        ot: tokens.OT_SLP_PENDLE_WETH_29_DEC_2022,
      },
    ],
  },
  {
    yieldBearingTokenAddress: tokens.SLP_USDC_WETH,
    rewardTokenAddresses: [],
    underlyingTokenAddress: tokens.SLP_USDC_WETH,
    network: 'mainnet',
    address: pendle.ROUTER,
    forgeId: forgeId.SUSHISWAPCOMPLEX,
    mintables: [
      {
        expiry: expiry.TIME_29_DEC_2022,
        yt: tokens.YT_SLP_USDC_WETH_29_DEC_2022,
        ot: tokens.OT_SLP_USDC_WETH_29_DEC_2022,
      },
    ],
  },
  {
    yieldBearingTokenAddress: tokens.WXBTRFLY,
    rewardTokenAddresses: [],
    underlyingTokenAddress: tokens.XBTRFLY,
    network: 'mainnet',
    address: pendle.ROUTER,
    forgeId: forgeId.REDACTED,
    mintables: [
      {
        expiry: expiry.TIME_21_APR_2022,
        yt: tokens.YT_WXBTRFLY_21_APR_2022,
        ot: tokens.OT_WXBTRFLY_21_APR_2022,
      },
    ],
  },
]
