<template>
  <div class="px-4 py-6">
    <div class="flex items-start">
      <div class="flex flex-grow">
        <div v-if="category" class="pt-1 pr-2 flex items-start">
          <svg
            v-if="category === 'success'"
            class="h-5 w-5"
            viewBox="0 0 1792 1792"
            fill="#44C997"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M1299 813l-422 422q-19 19-45 19t-45-19L493 941q-19-19-19-45t19-45l102-102q19-19 45-19t45 19l147 147 275-275q19-19 45-19t45 19l102 102q19 19 19 45t-19 45zm141 83q0-148-73-273t-198-198-273-73-273 73-198 198-73 273 73 273 198 198 273 73 273-73 198-198 73-273zm224 0q0 209-103 385.5T1281.5 1561 896 1664t-385.5-103T231 1281.5 128 896t103-385.5T510.5 231 896 128t385.5 103T1561 510.5 1664 896z"
            />
          </svg>
          <svg
            v-else
            class="h-5 w-5"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 1792 1792"
            fill="#ff6363"
          >
            <path
              d="M1277 1122q0-26-19-45l-181-181 181-181q19-19 19-45 0-27-19-46l-90-90q-19-19-46-19-26 0-45 19L896 715 715 534q-19-19-45-19-27 0-46 19l-90 90q-19 19-19 46 0 26 19 45l181 181-181 181q-19 19-19 45 0 27 19 46l90 90q19 19 46 19 26 0 45-19l181-181 181 181q19 19 45 19 27 0 46-19l90-90q19-19 19-46zm387-226q0 209-103 385.5T1281.5 1561 896 1664t-385.5-103T231 1281.5 128 896t103-385.5T510.5 231 896 128t385.5 103T1561 510.5 1664 896z"
            />
          </svg>
        </div>

        <div>
          <span class="text-lg font-semibold">{{ title }}</span>
          <pp-relative-time :value="date" format="human" class="text-sm text-p-gray-500 pl-4" />
          <div v-if="text" class="pt-1">{{ text }}</div>
          <div>
            <a
              v-if="action.url && action.urlText"
              :href="action.url"
              target="_blank"
              class="block text-sm text-p-blue-400 hover:underline"
            >
              {{ action.urlText }}
            </a>
          </div>
        </div>
      </div>

      <div>
        <pp-btn icon variant="text" size="sm" @click="clearSingleNotification(id)">
          <svg
            class="h-full w-full"
            viewBox="0 0 8 8"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              stroke="currentColor"
              d="M6.25 1.75L1.75 6.25"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              stroke="currentColor"
              d="M6.25 6.25L1.75 1.75"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
        </pp-btn>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    props: {
      id: { type: String, required: true },
      title: { type: String, required: true },
      text: String,
      category: String,
      action: Object,
      createdAt: String,
    },
    methods: {
      clearSingleNotification(id) {
        this.$emit('clearSingleNotification', id)
      },
    },
    computed: {
      date() {
        return new Date(this.createdAt)
      },
    },
  }
</script>
