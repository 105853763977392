<template>
  <div class="p-2 flex flex-col items-start bg-white shadow-pendle rounded-2xl border">
    <div class="border-b p-6 flex justify-between items-center w-full">
      <h4 class="font-semibold text-2xl">Notifications</h4>
      <base-button
        class="
          text-sm
          font-normal
          underline
          p-1
          hover:bg-p-blue-200
          focus:bg-p-blue-200 focus:outline-none
        "
        @click="clearAllNotifications"
      >
        clear
      </base-button>
    </div>
    <div class="w-full max-h-96 overflow-y-auto divide-y">
      <div
        v-if="computedNotifications.length === 0"
        class="px-8 my-6 text-center text-sm text-p-blue-500"
      >
        You have no notifications.
      </div>
      <template v-else>
        <Notification
          v-for="notification in computedNotifications"
          :key="notification.id"
          @clearSingleNotification="(id) => clearSingleNotification(id)"
          v-bind="notification"
        ></Notification>
      </template>
    </div>
  </div>
</template>

<script>
  import Vue from 'vue'

  import BaseButton from '@/components/base/BaseButton.vue'
  import Notification from '@/components/notifications/Notification.vue'

  export default Vue.extend({
    components: {
      BaseButton,
      Notification,
    },
    methods: {
      clearAllNotifications() {
        this.$notification.clearAll()
      },
      clearSingleNotification(id) {
        this.$notification.clear(id)
      },
    },
    computed: {
      computedNotifications() {
        return this.$notification.reactive.notifications
      },
    },
  })
</script>
