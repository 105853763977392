<template>
  <button
    class="focus:outline-none inline-flex items-center space-x-1 text-left flex-wrap"
    type="button"
    @click="activePrice = activePrice === 0 ? 1 : 0"
  >
    <svg
      xmlns="http://www.w3.org/2000/svg"
      class="h-5 w-5 inline-block"
      viewBox="0 0 20 20"
      fill="currentColor"
    >
      <path
        d="M8 5a1 1 0 100 2h5.586l-1.293 1.293a1 1 0 001.414 1.414l3-3a1 1 0 000-1.414l-3-3a1 1 0 10-1.414 1.414L13.586 5H8zM12 15a1 1 0 100-2H6.414l1.293-1.293a1 1 0 10-1.414-1.414l-3 3a1 1 0 000 1.414l3 3a1 1 0 001.414-1.414L6.414 15H12z"
      />
    </svg>

    <div class="inline">1 {{ itemPrices[activePrice === 0 ? 1 : 0].unit }}</div>

    <span>=</span>

    <div class="inline">
      <pp-formatted-number
        class="font-semibold"
        :value="itemPrices[activePrice].amount"
        data-test="rate"
      ></pp-formatted-number>
      {{ itemPrices[activePrice].unit }}
    </div>
  </button>
</template>

<script>
  import Vue from 'vue'

  export default Vue.extend({
    props: {
      pair: { type: Array, required: true }, // [ { unit, amount }, { unit, amount } ]
    },
    data() {
      return {
        activePrice: 0,
      }
    },
    computed: {
      itemPrices() {
        const price1 = this.pair[1].amount / this.pair[0].amount
        const price2 = this.pair[0].amount / this.pair[1].amount

        return [
          {
            unit: this.pair[1].unit,
            amount: isFinite(price1) ? price1 : 0,
          },
          {
            unit: this.pair[0].unit,
            amount: isFinite(price2) ? price2 : 0,
          },
        ]
      },
    },
  })
</script>
