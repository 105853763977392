import { mainnet, decimals } from '../../constants'

const tokens = mainnet.TOKENS
const expiry = mainnet.EXPIRY

export default [
  {
    address: tokens.OT_AUSDC_30_DEC_2021,
    decimals: decimals.DECIMALS_6,
    name: 'OT-aUSDC-30Dec2021',
    symbol: 'OT-aUSDC',
    logo: '/images/coins/mainnet/ot-ausdc.png',
    network: 'mainnet',
    genericName: 'OT',
    protocol: 'Aave-v2',
    expiry: expiry.TIME_30_DEC_2021,
    otRewards: 'stkAAVE',
    tokenCategories: ['ot'],
  },
  {
    address: tokens.OT_AUSDC_29_DEC_2022,
    decimals: decimals.DECIMALS_6,
    name: 'OT-aUSDC-29Dec2022',
    symbol: 'OT-aUSDC',
    logo: '/images/coins/mainnet/ot-ausdc.png',
    network: 'mainnet',
    genericName: 'OT',
    protocol: 'Aave-v2',
    expiry: expiry.TIME_29_DEC_2022,
    otRewards: 'stkAAVE',
    tokenCategories: ['ot'],
  },
  {
    address: tokens.OT_CDAI_30_DEC_2021,
    decimals: decimals.DECIMALS_8,
    name: 'OT-cDAI-30Dec2021',
    symbol: 'OT-cDAI',
    logo: '/images/coins/mainnet/ot-cdai.png',
    network: 'mainnet',
    genericName: 'OT',
    protocol: 'Compound',
    expiry: expiry.TIME_30_DEC_2021,
    otRewards: 'COMP',
    tokenCategories: ['ot'],
  },
  {
    address: tokens.OT_CDAI_29_DEC_2022,
    decimals: decimals.DECIMALS_8,
    name: 'OT-cDAI-29Dec2022',
    symbol: 'OT-cDAI',
    logo: '/images/coins/mainnet/ot-cdai.png',
    network: 'mainnet',
    genericName: 'OT',
    protocol: 'Compound',
    expiry: expiry.TIME_29_DEC_2022,
    otRewards: 'COMP',
    tokenCategories: ['ot'],
  },
  {
    address: tokens.OT_SLP_PENDLE_WETH_29_DEC_2022,
    decimals: decimals.DECIMALS_18,
    name: 'OT-SLP(PENDLE/ETH)-29Dec2022',
    symbol: 'OT-PE',
    logo: '/images/coins/mainnet/ot-pe.png',
    network: 'mainnet',
    genericName: 'OT',
    protocol: 'Sushiswap',
    expiry: expiry.TIME_29_DEC_2022,
    otRewards: false,
    tokenCategories: ['ot'],
  },
  {
    address: tokens.OT_SLP_USDC_WETH_29_DEC_2022,
    decimals: decimals.DECIMALS_18,
    name: 'OT-SLP(ETH/USDC)-29Dec2022',
    symbol: 'OT-ETHUSDC',
    logo: '/images/coins/mainnet/ot-ethusdc.png',
    network: 'mainnet',
    genericName: 'OT',
    protocol: 'Sushiswap',
    expiry: expiry.TIME_29_DEC_2022,
    otRewards: 'SUSHI',
    tokenCategories: ['ot'],
  },
  {
    address: tokens.OT_WXBTRFLY_21_APR_2022,
    decimals: decimals.DECIMALS_9,
    name: 'OT-wxBTRFLY-21APR2022',
    symbol: 'OT-wxBTRFLY',
    logo: '/images/coins/mainnet/ot-wxbtrfly.png',
    network: 'mainnet',
    genericName: 'OT',
    protocol: 'Redacted Cartel',
    expiry: expiry.TIME_21_APR_2022,
    otRewards: 'SUSHI',
    tokenCategories: ['ot'],
  },
]
