export default class NullPromise {
  then() {
    return this // noop
  }

  catch() {
    return this // noop
  }

  finally() {
    return this // noop
  }
}
