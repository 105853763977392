<template>
  <a
    :href="link"
    target="_blank"
    class="
      inline-flex
      items-center
      text-sm
      outline-none
      focus:ring-2
      ring-offset-4 ring-water-600
      rounded
      transition
    "
  >
    Get {{ token.symbol }}

    <svg
      xmlns="http://www.w3.org/2000/svg"
      class="ml-1 h-5 w-5"
      viewBox="0 0 20 20"
      fill="currentColor"
    >
      <path
        d="M11 3a1 1 0 100 2h2.586l-6.293 6.293a1 1 0 101.414 1.414L15 6.414V9a1 1 0 102 0V4a1 1 0 00-1-1h-5z"
      />
      <path
        d="M5 5a2 2 0 00-2 2v8a2 2 0 002 2h8a2 2 0 002-2v-3a1 1 0 10-2 0v3H5V7h3a1 1 0 000-2H5z"
      />
    </svg>
  </a>
</template>

<script>
  import Token from '@/domains/entities/Token'

  export default {
    props: {
      address: { type: String, required: true },
    },
    computed: {
      token() {
        return Token.query().find(this.address)
      },
      link() {
        return this.token.sources[0]
      },
    },
  }
</script>
