import networkEnv from '@/app-config/networks'

const chainId = {
  avalanche: 43114,
  mainnet: 1,
  local: 31337,
}

export const sdkChainId = networkEnv.VUE_APP_ALLOWED_NETWORK_ID == chainId.local
  ? chainId.mainnet
  : Number(networkEnv.VUE_APP_ALLOWED_NETWORK_ID)

export default chainId
