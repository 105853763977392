import BigNumber from 'bignumber.js'

export default class Valuation {
  constructor({ currency, amount }) {
    this.currency = currency.toUpperCase()
    this.amount = amount
  }

  plus(valuation) {
    const { currency, amount } = this
    if (currency !== valuation.currency) throw 'Cannot perform arithmetic across different currency'

    return new Valuation({
      currency,
      amount: new BigNumber(amount).plus(new BigNumber(valuation.amount)).toFixed(),
    })
  }

  minus(valuation) {
    const { currency, amount } = this
    if (currency !== valuation.currency) throw 'Cannot perform arithmetic across different currency'

    return new Valuation({
      currency,
      amount: new BigNumber(amount).plus(new BigNumber(valuation.amount)).toFixed(),
    })
  }
}
