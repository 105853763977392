import Market from './Market'
import TokenIssuer from './TokenIssuer'
import PendleAmmMarketCollection from '@/domains/collections/PendleAmmMarketCollection'
import PendleAmmMarketContract from '@/domains/contracts/PendleAmmMarketContract'
import YT from './YT'
import { PendleMarket as SdkPendleMarket, Token as SdkToken } from '@pendle/sdk'
import { currentNetwork } from '@/app-config/networks'

export default class PendleAmmMarket extends Market {
  get tokenIssuer() {
    return TokenIssuer.query().findBy(
      (press) =>
        press.expiryEpoch === this.expiryEpoch &&
        [press.otAddress, press.ytAddress].includes(this.yt.address)
    )
  }

  get createdAt() {
    return new Date(this.createdEpoch * 1000)
  }

  get expiresAt() {
    return new Date(this.expiryEpoch * 1000)
  }

  get freezesAt() {
    // TODO: This will break if we add new markets
    const secondsToFreeze =
      (1 /
        (currentNetwork == '43114' || this.pool.name.toLowerCase().includes('wxbtrfly')
          ? 360
          : 20)) *
      (this.expiryEpoch - this.createdEpoch)
    const frozenEpoch = this.expiryEpoch - secondsToFreeze
    return new Date(frozenEpoch * 1000)
  }

  get yt() {
    return YT.query().findBy('address', this.pair)
  }

  get liquidityTypes() {
    return ['single', 'dual']
  }

  get status() {
    if (new Date() > this.expiresAt) {
      return 'expired'
    } else if (new Date() > this.freezesAt) {
      return 'frozen'
    }
    return 'active'
  }

  statusIs(status) {
    return {
      active: this.status !== 'frozen' && this.status !== 'expired',
      inactive: this.status === 'frozen' || this.status === 'expired',
      frozen: this.status === 'frozen',
      expired: this.status === 'expired',
    }[status]
  }

  contract({ signer, provider }) {
    return new PendleAmmMarketContract({ signer, provider, market: this })
  }

  static query() {
    return PendleAmmMarketCollection.all()
  }

  #sdkMarket() {
    return new SdkPendleMarket(
      this.address,
      this.tokenPair
        .toArray()
        .map((token) => new SdkToken(token.address, token.decimals, token.expiry))
    )
  }
}
