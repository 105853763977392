<template>
  <div>
    <div class="flex justify-between">
      <div>{{ label }}</div>

      <div class="flex items-center text-sm text-water-600">
        <div>Balance:</div>
        <pp-skeleton-loader
          v-if="balanceState.is('loading')"
          class="inline-block w-24 ml-1"
        ></pp-skeleton-loader>

        <span
          v-if="balanceState.is('resolved') || balanceState.is('rejected')"
          class="ml-1 font-medium"
          v-text="balance"
          data-test="balance"
        ></span>
      </div>
    </div>

    <div class="mt-2 relative">
      <div
        class="flex h-12 rounded-xl focus-within:ring-2 focus-within:ring-offset-2 border divide-x"
        :class="[bgAndRingColor]"
      >
        <div
          v-if="variant === 'picker'"
          class="flex items-center min-w-44 rounded-l-xl bg-white hover:bg-p-blue-200 hover:cursor-pointer"
          @click="openDialog = true"
        >
          <pp-token-input-preview :token="inputToken" :variant="variant"></pp-token-input-preview>

          <v-easy-dialog v-model="openDialog" width="420px">
            <template>
              <select-token-dialog
                @select="handleTokenSelect"
                @close="openDialog = false"
                :token-options="tokenOptions"
                :common-options="commonOptions"
              ></select-token-dialog>
            </template>
          </v-easy-dialog>
        </div>

        <div v-else class="flex items-center min-w-44 rounded-l-xl bg-white">
          <pp-token-input-preview
            :token="inputToken"
            :secondary-text="secondaryText"
            :secondary-text-loading="secondaryTextLoading"
          ></pp-token-input-preview>
        </div>

        <div class="flex-grow rounded-r-xl">
          <numeric-input
            :value="value"
            @input="$emit('input', $event)"
            @blur="interacted = true"
            @keydown="interacted = true"
            :maximum-decimal="inputToken.decimals"
            :disabled="disabled"
            v-bind="$attrs"
            class="bg-transparent outline-none h-full w-full px-4 py-2 rounded-r-xl"
            placeholder="0.00"
            ref="inputRef"
          ></numeric-input>
        </div>
      </div>

      <pp-btn
        @click="$emit('input', balance)"
        type="button"
        class="absolute top-0 right-0 bg-white !py-0.5 border border-p-blue-300 disabled:border-p-gray-300 rounded-tr-xl rounded-bl-xl rounded-br-none rounded-tl-none"
        :disabled="disabled || balanceState.is('loading')"
        variant="text"
        size="xs"
      >
        Max
      </pp-btn>
    </div>

    <p v-if="interacted && error" class="p-1 text-sm text-p-red-600" v-text="error"></p>
  </div>
</template>

<script>
  import NumericInput from '@/components/forms/NumericInput.vue'
  import PromiseHandler, { createState } from '@/domains/PromiseHandler'
  import BigNumberjs from 'bignumber.js'
  import VEasyDialog from 'v-easy-dialog'
  import SelectTokenDialog from '@/components/dialogs/SelectTokenDialog.vue'
  import TokenAmount from '@/domains/entities/TokenAmount'

  export default {
    components: {
      NumericInput,
      VEasyDialog,
      SelectTokenDialog,
    },
    props: {
      value: { type: String, required: true },
      variant: { type: String, default: 'fixed' }, // fixed | picker
      secondaryText: { type: String },
      secondaryTextLoading: { type: Boolean },
      label: { type: String, default: '' },
      disabled: { type: Boolean, default: false },
      validateBalance: { type: Boolean, default: true },
      rules: { type: Array, default: () => [] },
      tokenOptions: { type: Array, default: () => [] },
      commonOptions: { type: Array, default: () => [] },
      inputToken: { type: Object, required: true },
    },
    inject: ['registerInput', 'unregisterInput'],
    data() {
      return {
        form: null,
        openDialog: false,
        interacted: false,
        balanceState: createState({
          response: new TokenAmount(this.$props.inputToken, 0),
        }),
      }
    },
    computed: {
      balance() {
        return this.balanceState.response.formattedAmount()
      },
      defaultRules() {
        return [
          (val) =>
            new BigNumberjs(val || 0).lte(new BigNumberjs(this.balance)) ||
            'Amount exceeded available balance',
        ]
      },
      error() {
        return [...(this.validateBalance ? this.defaultRules : []), ...this.rules]
          .map((rule) => rule(this.value))
          .find((validation) => typeof validation === 'string')
      },
      bgAndRingColor() {
        return this.disabled
          ? 'bg-p-gray-100'
          : this.error && this.interacted
          ? 'focus-within:ring-p-red-600 bg-p-red-100  border-p-red-300 divide-p-red-300'
          : 'focus-within:ring-p-blue-600 bg-p-blue-100  border-p-blue-300 divide-p-blue-300'
      },
    },
    methods: {
      fetchTokenBalance() {
        const { address } = this.$store.getters['wallet/user']
        new PromiseHandler(
          () => this.inputToken.contract(this.$store.getters['wallet/identity']).balanceOf(address),
          this.balanceState
        ).execute()
      },
      handleTokenSelect(token) {
        this.$emit('select', token)
        this.openDialog = false
      },
    },
    watch: {
      value: {
        immediate: false,
        handler() {
          this.interacted = true
        },
      },
      inputToken: {
        immediate: true,
        handler() {
          this.fetchTokenBalance()
        },
      },
    },
    mounted() {
      this.registerInput?.(this)
      this.form?.updateError()
    },
    updated() {
      this.form?.updateError()
    },
    beforeDestroy() {
      this.unregisterInput?.(this)
    },
  }
</script>
