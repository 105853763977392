<template>
  <div>
    <pp-connect-wallet-btn v-if="!$store.state.wallet.address"></pp-connect-wallet-btn>
    <pp-btn
      v-else-if="!hasApproval"
      @click="approveToken"
      class="flex w-full"
      type="button"
      size="xl"
      :disabled="disabled || !$store.state.wallet.address"
      :loading="tokenAllowance.state === 'loading' || tokenApproval.state === 'loading'"
      data-test="approve-btn"
    >
      Approve {{ spendingAmount.token.symbol }}
    </pp-btn>

    <slot :approved="hasApproval"></slot>
  </div>
</template>

<script>
  import Vue from 'vue'
  import TokenAmount from '@/domains/entities/TokenAmount'
  import PromiseHandler, { createState } from '@/domains/PromiseHandler'
  import BigNumberjs from 'bignumber.js'
  import { generateEtherscanTxUrl } from '@/assets/helpers'

  const uint256limit = new BigNumberjs(2).pow(new BigNumberjs(256)).minus(new BigNumberjs(1))

  export default Vue.extend({
    props: {
      spenderAddress: { type: String, required: true },
      spendingAmount: { type: Object, required: true },
      disabled: { type: Boolean, default: false },
    },
    data() {
      return {
        tokenAllowance: createState({
          response: new TokenAmount(this.$props.spendingAmount.token, 0),
        }),
        tokenApproval: createState(),
      }
    },
    computed: {
      tokenContract() {
        return this.spendingAmount.token.contract(this.$store.getters['wallet/identity'])
      },
      hasApproval() {
        const approval =
          this.tokenAllowance.response.formattedAmount() == 0
            ? false
            : this.tokenAllowance.response.gte(this.spendingAmount)
        this.$emit('update:approval', approval)
        return approval
      },
    },
    methods: {
      fetchTokenAllowance() {
        new PromiseHandler(
          () => this.tokenContract.allowance(this.$store.state.wallet.address, this.spenderAddress),
          this.tokenAllowance
        ).execute()
      },
      approveToken() {
        new PromiseHandler(
          () => this.tokenContract.approve(this.spenderAddress, uint256limit.toFixed()),
          this.tokenApproval
        )
          .execute()
          .then((response) => {
            this.fetchTokenAllowance()

            this.$notification.success({
              title: 'Approve',
              text: `Approved ${this.spendingAmount.token.name}`,
              action: {
                url: generateEtherscanTxUrl(response.transactionHash),
                urlText: 'View on explorer',
              },
            })
          })
          .catch(() => {
            this.$notification.error({
              title: 'Approve',
              text: `Unable to approve ${this.spendingAmount.token.name}`,
            })
          })
      },
    },
    created() {
      this.fetchTokenAllowance()
    },
  })
</script>
