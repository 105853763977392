<template>
  <div>
    <div>
      <div class="text-sm">Slippage Tolerance</div>

      <div class="pt-1">
        <pp-simple-data>
          <template #left>
            <div class="space-x-1">
              <pp-tab
                variant="accent"
                :active="parseFloat(localValue) === parseFloat(preset.value)"
                v-for="preset in presetList"
                :key="preset.text"
                v-text="preset.text"
                @click="localValue = preset.value"
              ></pp-tab>
            </div>
          </template>

          <template #right>
            <div
              class="
                px-4
                py-1.5
                bg-p-blue-100
                inline-block
                rounded-xl
                focus-within:ring-2 focus-within:ring-p-blue-600
                transition
                ring-offset-2
              "
            >
              <numeric-input
                data-test="slippage-input"
                v-model="localValue"
                :maximum-decimal="2"
                placeholder="10.00"
                class="bg-transparent focus:outline-none w-12"
              ></numeric-input>
              %
            </div>
          </template>
        </pp-simple-data>
      </div>
    </div>

    <div class="mt-4">
      <pp-btn
        @click="$emit('input', localValue)"
        :disabled="value === localValue"
        class="w-full"
        data-test="slippage-confirm"
      >
        Confirm
      </pp-btn>
    </div>
  </div>
</template>

<script>
  import NumericInput from './NumericInput.vue'
  const presetList = [
    {
      text: '0.1%',
      value: '0.1',
    },
    {
      text: '0.5%',
      value: '0.5',
    },
    {
      text: '1.0%',
      value: '1',
    },
  ]
  export default {
    props: {
      value: { type: String, required: true },
    },
    components: {
      NumericInput,
    },
    watch: {
      value(newVal) {
        this.localValue = newVal
      },
    },
    data() {
      return {
        presetList,
        localValue: this.value,
      }
    },
  }
</script>
