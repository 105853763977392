<template>
  <div>
    <div class="fixed z-alert inset-0 pointer-events-none">
      <div class="relative min-w-full min-h-full pointer-events-none">
        <div class="absolute top-16 right-8 z-alert pointer-events-auto">
          <NotificationPopup
            class="mt-3"
            v-for="notification in visiblePopups"
            :key="notification.id"
            v-bind="notification"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import Vue from 'vue'
  import NotificationPopup from './NotificationPopup.vue'

  const POPUP_DURATION = 5000 // in milliseconds

  function getRecentNotifications(notifications) {
    return notifications.filter(notif => {
      const notifAge = new Date().getTime() - new Date(notif.createdAt).getTime()
      return notifAge < POPUP_DURATION
    })
  }

  export default Vue.extend({
    components: {
      NotificationPopup,
    },
    data: () => ({
      visiblePopups: [],
    }),
    watch: {
      computedNotifications: {
        handler(updatedNotifs) {
          this.visiblePopups = getRecentNotifications(updatedNotifs)
        },
      },
      visiblePopups: {
        handler(updatedList, oldList) {
          const shouldCreateNewInterval = oldList.length === 0 && updatedList.length === 1
          if (shouldCreateNewInterval) {
            const lifeSpanCheckInterval = setInterval(
              () => {
                this.visiblePopups = getRecentNotifications(this.computedNotifications)
                if (this.visiblePopups.length === 0) {
                  clearInterval(lifeSpanCheckInterval)
                }
              },
              100,
            )
          }
        },
      },
    },
    computed: {
      computedNotifications() {
        return this.$notification.reactive.notifications
      }
    },
  })
</script>
