<template>
  <th class="pp-th" :class="[filler ? 'p-0' : '', alignment]" v-bind="$attrs" v-on="$listeners">
    <slot></slot>
  </th>
</template>

<script>
  import Vue from 'vue'

  const alignMap = {
    center: 'text-center',
    right: 'text-right',
    left: 'text-left',
  }

  const vAlignMap = {
    top: 'align-top',
    middle: 'align-middle',
    bottom: 'align-bottom',
  }

  export default Vue.extend({
    props: {
      align: { type: String, default: 'left', validator: (val) => Object.keys(alignMap) !== -1 },
      filler: { type: Boolean, default: false },
      valign: { type: String, default: 'top', validator: (val) => Object.keys(vAlignMap) !== -1 },
    },
    computed: {
      alignment() {
        const { align, valign } = this
        return [alignMap[align], vAlignMap[valign]].join(' ')
      },
    },
  })
</script>
