<template>
  <pp-tab
    @click="$emit('input', value)"
    v-on="$listeners"
    :active="active"
    class="rounded-lg w-full text-left"
    :color="color"
    :disabled="disabled"
  >
    <div v-if="img" class="w-6 flex-shrink-0">
      <img class="w-6 rounded-full object-cover" :src="img" :alt="alt" />
    </div>

    <div class="flex-grow" :class="img ? 'ml-2' : ''">
      <div v-text="text"></div>
    </div>

    <div class="pl-1 w-6 flex-shrink-0">
      <svg
        v-if="active"
        xmlns="http://www.w3.org/2000/svg"
        class="h-5 w-5"
        viewBox="0 0 20 20"
        fill="currentColor"
      >
        <path
          fill-rule="evenodd"
          d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
          clip-rule="evenodd"
        />
      </svg>
    </div>
  </pp-tab>
</template>

<script>
  import Vue from 'vue'
  import TabColorMap from '@/ui-config/tab-color-map'

  export default Vue.extend({
    props: {
      color: { type: String, default: 'p-blue' },
      variant: { type: String, default: 'fill' },
      size: { type: String, default: 'md' },
      active: { type: Boolean, default: false },
      img: { type: String, default: undefined },
      alt: { type: String, default: undefined },
      text: { type: String, default: '' },
      value: { type: undefined, required: true },
      disabled: { type: Boolean, required: false },
    },
    data() {
      return {}
    },
  })
</script>
