import { sdkChainId } from '@/app-config/network'
import { PendleMarket } from '@pendle/sdk'
import MarketContract from '@/domains/contracts/MarketContract'

export default class PendleAmmMarketContract extends MarketContract {
  readMarketDetails() {
    return this._sdkMarketMethods().readMarketDetails()
  }

  swapExactInDetails({ amount, slippage }) {
    return this._sdkMarketMethods().swapExactInDetails(amount, parseFloat(slippage) / 100)
  }

  swapExactOutDetails({ amount, slippage }) {
    return this._sdkMarketMethods().swapExactOutDetails(amount, parseFloat(slippage) / 100)
  }

  swapExactIn({ amount, slippage }) {
    return new Promise((resolve, reject) => {
      this._sdkMarketMethods()
        .swapExactIn(amount, parseFloat(slippage) / 100)
        .then((response) => response.wait(1).then(() => setTimeout(() => resolve(response), 2000)))
        .catch((error) => reject(error))
    })
  }

  swapExactOut({ amount, slippage }) {
    return new Promise((resolve, reject) => {
      this._sdkMarketMethods()
        .swapExactOut(amount, parseFloat(slippage) / 100)
        .then((response) => response.wait(1).then(() => setTimeout(() => resolve(response), 2000)))
        .catch((error) => reject(error))
    })
  }

  addSingleDetails({ tokenAmount }) {
    return this._sdkMarketMethods().addSingleDetails(tokenAmount)
  }

  addSingle({ tokenAmount, slippage }) {
    return new Promise((resolve, reject) => {
      this._sdkMarketMethods()
        .addSingle(tokenAmount, parseFloat(slippage) / 100)
        .then((response) => response.wait(1).then(() => setTimeout(() => resolve(response), 2000)))
        .catch((error) => reject(error))
    })
  }

  removeSingleDetails({ percentage, outToken, slippage }) {
    return this._sdkMarketMethods().removeSingleDetails(
      parseFloat(percentage) / 100,
      outToken,
      slippage
    )
  }

  removeSingle({ percentage, outToken, slippage }) {
    return new Promise((resolve, reject) => {
      this._sdkMarketMethods()
        .removeSingle(parseFloat(percentage) / 100, outToken, parseFloat(slippage) / 100)
        .then((response) => response.wait(1).then(() => setTimeout(() => resolve(response), 2000)))
        .catch((error) => reject(error))
    })
  }

  getSwapFeeApr() {
    return this._sdkMarketMethods().getSwapFeeApr()
  }

  _sdkMarketMethods() {
    const market = PendleMarket.find(this.market.address, sdkChainId)

    return market.methods({
      signer: this.signer,
      provider: this.provider,
      chainId: sdkChainId,
    })
  }
}
