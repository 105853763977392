<template>
  <div class="border-t bg-white">
    <div class="container px-2 sm:px-4 md:px-8 mx-auto">
      <div class="h-16 flex items-center space-x-2 sm:space-x-3 bg-white pl-0 sm:pl-2 p-2">
        <div class="flex-grow"></div>
        <NavbarActions direction="up" />
      </div>
    </div>
  </div>
</template>

<script>
  import NavbarActions from '@/components/singles/navbar/NavbarActions.vue'

  export default {
    components: {
      NavbarActions,
    },
    data() {
      return {
        showNotifications: false,
        showSocials: false,
      }
    },
    methods: {
      handleNotificationFocusOut(event) {
        const { currentTarget, relatedTarget } = event
        if (currentTarget.contains(relatedTarget)) return
        this.showNotifications = false
      },
      handleSocialsFocusOut(event) {
        const { currentTarget, relatedTarget } = event
        if (currentTarget.contains(relatedTarget)) return
        this.showSocials = false
      },
    },
  }
</script>
