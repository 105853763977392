import { TokenAmount as SdkTokenAmount, Sdk } from '@pendle/sdk'
import Token from '@/domains/entities/Token'
import BigNumberjs from 'bignumber.js'
import Valuation from '@/domains/Valuation'
import { sdkChainId } from '@/app-config/network'

export default class TokenAmount extends SdkTokenAmount {
  static fromSdkTokenAmount(tokenAmount) {
    return new TokenAmount(
      Token.query().find(tokenAmount.token.address),
      tokenAmount.rawAmount(),
      true
    )
  }

  plus(tokenAmount) {
    if (this.token.address !== tokenAmount.token.address) {
      throw 'Cannot perform arithmetic across different token address'
    }

    return new TokenAmount(
      this.token,
      new BigNumberjs(this.rawAmount())
        .plus(TokenAmount.fromSdkTokenAmount(tokenAmount).rawAmount())
        .toFixed()
    )
  }

  minus(tokenAmount) {
    if (this.token.address !== tokenAmount.token.address) {
      throw 'Cannot perform arithmetic across different token address'
    }

    return new TokenAmount(
      this.token,
      new BigNumberjs(this.rawAmount())
        .minus(TokenAmount.fromSdkTokenAmount(tokenAmount).rawAmount())
        .toFixed()
    )
  }

  gte(tokenAmount) {
    return new BigNumberjs(this.rawAmount()).gte(new BigNumberjs(tokenAmount.rawAmount()))
  }

  gt(tokenAmount) {
    return new BigNumberjs(this.rawAmount()).gt(new BigNumberjs(tokenAmount.rawAmount()))
  }

  lte(tokenAmount) {
    return new BigNumberjs(this.rawAmount()).lte(new BigNumberjs(tokenAmount.rawAmount()))
  }

  lt(tokenAmount) {
    return new BigNumberjs(this.rawAmount()).lt(new BigNumberjs(tokenAmount.rawAmount()))
  }

  contract({ signer, provider }) {
    return {
      fetchValuation: () =>
        new Promise((resolve, reject) => {
          new Sdk({ signer, provider, chainId: sdkChainId })
            .fetchValuations([this], sdkChainId)
            .then((responses) => {
              resolve(new Valuation(responses[0]))
            })
            .catch((error) => reject(error))
        }),
    }
  }
}
