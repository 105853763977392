<template>
  <div class="bg-white rounded-2xl overflow-auto">
    <div class="flex flex-row px-4 pt-4 justify-between items-center">
      <div class="font-semibold">Select a token</div>
      <pp-close-button @click="$emit('close')"></pp-close-button>
    </div>

    <div class="mt-3 px-4">
      <input
        type="text"
        placeholder="Search name or paste address"
        class="h-12 w-full px-4 py-2 rounded-xl bg-water-200"
        v-model="query"
        @input="handleInput"
        @keydown.down="onArrowDown"
        @keydown.up="onArrowUp"
        @keydown.enter="onEnter"
        ref="inputRef"
      />
    </div>

    <div class="mt-4 px-4">
      <div class="text-sm">Common tokens</div>

      <div class="flex flex-wrap mt-2">
        <pp-token-chip
          v-for="token in commonOptions"
          :key="token.address"
          :token="token"
          class="mr-1 mb-1"
          @click="$emit('select', token)"
        ></pp-token-chip>
      </div>
    </div>

    <div class="mt-3 h-px bg-gray-200"></div>

    <div class="h-[50vh] overflow-y-auto">
      <ul v-if="tokens.length > 0">
        <li
          v-for="(token, index) in tokens"
          :key="token.address"
          :id="`num-${index}`"
          class="px-4 py-3"
          :class="[styles, { 'is-active': index === arrowCounter }]"
          @click="$emit('select', token)"
          @mousemove="() => onMouseOver(index)"
        >
          <pp-token-preview-balance :token="token"></pp-token-preview-balance>
        </li>
      </ul>

      <div v-else class="py-4 text-center text-sm">No results found.</div>
    </div>
  </div>
</template>

<script>
  import ArrayDecorator from '@/domains/ArrayDecorator'

  export default {
    props: {
      tokenOptions: { type: Array, default: () => [] },
      commonOptions: { type: Array, default: () => [] },
    },
    data() {
      return {
        tokens: this.$props.tokenOptions,
        query: '',
        arrowCounter: -1,
        keyboardInteracted: false,
      }
    },
    watch: {
      tokenOptions() {
        this.tokens = this.tokenOptions
        this.handleInput()
      },
    },
    computed: {
      styles() {
        return this.keyboardInteracted ? '' : 'hover:bg-water-100 hover:cursor-pointer'
      },
    },
    methods: {
      handleInput() {
        this.$nextTick(() => {
          this.arrowCounter = 0
          const searchText = this.query.trim()
          const tokens = new ArrayDecorator(this.tokenOptions)
          const filtered = tokens
            .filterBySearch(searchText, {
              keys: ['symbol', 'name', 'address'],
              threshold: 0.2,
            })
            .format()
          this.tokens = filtered
        })
      },
      onArrowDown() {
        if (this.arrowCounter < this.tokens.length - 1) {
          this.arrowCounter = this.arrowCounter + 1
          this.fixScrolling()
        }
        this.keyboardInteracted = true
      },
      onArrowUp() {
        if (this.arrowCounter > 0) {
          this.arrowCounter = this.arrowCounter - 1
          this.fixScrolling()
        }
        this.keyboardInteracted = true
      },
      onEnter() {
        const token = this.tokens[this.arrowCounter]
        if (token) {
          this.$emit('select', token)
          this.arrowCounter = -1
        }
      },
      fixScrolling() {
        const el = this.$el.querySelector(`#num-${this.arrowCounter}`)
        if (el) {
          el.scrollIntoView({ behavior: 'smooth', block: 'center' })
        }
      },
      onMouseOver(index) {
        this.arrowCounter = index
        this.keyboardInteracted = false
      },
    },
    mounted() {
      setTimeout(() => this.$refs.inputRef.focus(), 200)
    },
  }
</script>

<style scoped>
  .is-active {
    @apply bg-p-blue-100;
  }
</style>
