import avax from './avalanche.json'
import main from './mainnet.json'

export const avalanche = {
  TOKENS: {
    NATIVE: '0xeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeee',
    USDC: avax.TOKEN_USDC,
    PENDLE: avax.TOKEN_PENDLE,
    QIUSDC: avax.TOKEN_QIUSDC,
    QIAVAX: avax.TOKEN_QIAVAX,
    AVAX: '0xeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeee',
    WAVAX: avax.TOKEN_WAVAX,
    JOE: avax.TOKEN_JOE,
    XJOE: avax.TOKEN_XJOE,
    QI: avax.TOKEN_QI,
    MEMO: avax.TOKEN_MEMO,
    WMEMO: avax.TOKEN_WMEMO,
    MIM: avax.TOKEN_MIM,
    TIME: avax.TOKEN_TIME,
    USDT: '0xc7198437980c041c805a1edcba50c1ce5db95118',
    DAI: '0xd586e7f844cea2f87f50152665bcbc2c279d8d70',
    WBTC: '0x50b7545627a5162f82a992c33b87adc75187b218',
    WETH: '0x49d5c2bdffac6ce2bfdb6640f4f80f226bc10bab',
    BNB: '0x264c1383ea520f73dd837f915ef3a732e204a493',
    AAVE: '0x63a72806098bd3d9520cc43356dd78afe5d386d9',
    OT_QIUSDC_28_DEC_2023: avax.TOKEN_OT_QIUSDC_28_DEC_2023,
    OT_QIAVAX_28_DEC_2023: avax.TOKEN_OT_QIAVAX_28_DEC_2023,
    OT_JLP_WAVAX_PENDLE_28_DEC_2023: avax.TOKEN_OT_JLP_WAVAX_PENDLE_28_DEC_2023,
    OT_XJOE_30_JUN_2022: avax.TOKEN_OT_XJOE_30_JUN_2022,
    OT_WMEMO_24_FEB_2022: avax.TOKEN_OT_WMEMO_24_FEB_2022,
    YT_QIUSDC_28_DEC_2023: avax.TOKEN_YT_QIUSDC_28_DEC_2023,
    YT_QIAVAX_28_DEC_2023: avax.TOKEN_YT_QIAVAX_28_DEC_2023,
    YT_JLP_WAVAX_PENDLE_28_DEC_2023: avax.TOKEN_YT_JLP_WAVAX_PENDLE_28_DEC_2023,
    YT_XJOE_30_JUN_2022: avax.TOKEN_YT_XJOE_30_JUN_2022,
    YT_WMEMO_24_FEB_2022: avax.TOKEN_YT_WMEMO_24_FEB_2022,
    JLP_WAVAX_PENDLE: avax.TOKEN_JLP_WAVAX_PENDLE,
  },
  POOLS: {
    OT_JLP_WAVAX_PENDLE_28_DEC_2023_X_PENDLE: avax.POOL_OT_JLP_WAVAX_PENDLE_28_DEC_2023_X_PENDLE,
    OT_QIUSDC_28_DEC_2023_X_USDC: avax.POOL_OT_QIUSDC_28_DEC_2023_X_USDC,
    OT_QIAVAX_28_DEC_2023_X_USDC: avax.POOL_OT_QIAVAX_28_DEC_2023_X_USDC,
    OT_XJOE_30_JUN_2022_X_USDC: avax.POOL_OT_XJOE_30_JUN_2022_X_USDC,
    OT_WMEMO_24_FEB_2022_X_MIM: avax.POOL_OT_WMEMO_24_FEB_2022_X_MIM,
    YT_JLP_WAVAX_PENDLE_28_DEC_2023_X_PENDLE: avax.POOL_YT_JLP_WAVAX_PENDLE_28_DEC_2023_X_PENDLE,
    YT_QIUSDC_28_DEC_2023_X_USDC: avax.POOL_YT_QIUSDC_28_DEC_2023_X_USDC,
    YT_QIAVAX_28_DEC_2023_X_USDC: avax.POOL_YT_QIAVAX_28_DEC_2023_X_USDC,
    YT_XJOE_30_JUN_2022_X_USDC: avax.POOL_YT_XJOE_30_JUN_2022_X_USDC,
    YT_WMEMO_24_FEB_2022_X_MIM: avax.POOL_YT_WMEMO_24_FEB_2022_X_MIM,
  },
  LIQ: {
    OT_JLP_WAVAX_PENDLE_28_DEC_2023_X_PENDLE: avax.LIQ_OT_JLP_WAVAX_PENDLE_28_DEC_2023_X_PENDLE,
    OT_QIUSDC_28_DEC_2023_X_USDC: avax.LIQ_OT_QIUSDC_28_DEC_2023_X_USDC,
    OT_QIAVAX_28_DEC_2023_X_USDC: avax.LIQ_OT_QIAVAX_28_DEC_2023_X_USDC,
    OT_XJOE_30_JUN_2022_X_USDC: avax.LIQ_OT_XJOE_30_JUN_2022_X_USDC,
    OT_WMEMO_24_FEB_2022_X_MIM: avax.LIQ_OT_WMEMO_24_FEB_2022_X_MIM,
    YT_JLP_WAVAX_PENDLE_X_PENDLE: avax.LIQ_YT_JLP_WAVAX_PENDLE_X_PENDLE,
    YT_QIUSDC_X_USDC: avax.LIQ_YT_QIUSDC_X_USDC,
    YT_QIAVAX_X_USDC: avax.LIQ_YT_QIAVAX_X_USDC,
    YT_XJOE_X_USDC: avax.LIQ_YT_XJOE_X_USDC,
    YT_WMEMO_X_MIM: avax.LIQ_YT_WMEMO_X_MIM,
  },
  PENDLE: {
    ROUTER: avax.PENDLE_ROUTER,
    OT_SPENDER_ADDRESS: '0x60ae616a2155ee3d9a68541ba4544862310933d4',
    ZAP_SPENDER_ADDRESS: '0x40484fc214885e071980e1df660b2da8df734b45',
  },
  FORGEID: {
    BENQI: avax.FORGEID_BENQI,
    TRADERJOESIMPLE: avax.FORGEID_TRADERJOESIMPLE,
    XJOE: avax.FORGEID_XJOE,
    WONDERLAND: avax.FORGEID_WONDERLAND,
  },
  EXPIRY: {
    TIME_28_DEC_2023: avax.TIME_28_DEC_2023,
    TIME_30_JUN_2022: avax.TIME_30_JUN_2022,
    TIME_24_FEB_2022: avax.TIME_24_FEB_2022,
  },
}

export const mainnet = {
  TOKENS: {
    NATIVE: '0xeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeee',
    USDC: main.TOKEN_USDC,
    DAI: main.TOKEN_DAI,
    AUSDC: main.TOKEN_AUSDC,
    CDAI: main.TOKEN_CDAI,
    STKAAVE: main.TOKEN_STKAAVE,
    SUSHI: main.TOKEN_SUSHI,
    COMP: main.TOKEN_COMP,
    PENDLE: main.TOKEN_PENDLE,
    ETH: '0xeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeee',
    WETH: main.TOKEN_WETH,
    XBTRFLY: main.TOKEN_XBTRFLY,
    WXBTRFLY: main.TOKEN_WXBTRFLY,
    OT_AUSDC_30_DEC_2021: main.TOKEN_OT_AUSDC_30_DEC_2021,
    OT_AUSDC_29_DEC_2022: main.TOKEN_OT_AUSDC_29_DEC_2022,
    OT_CDAI_30_DEC_2021: main.TOKEN_OT_CDAI_30_DEC_2021,
    OT_CDAI_29_DEC_2022: main.TOKEN_OT_CDAI_29_DEC_2022,
    OT_SLP_PENDLE_WETH_29_DEC_2022: main.TOKEN_OT_SLP_PENDLE_WETH_29_DEC_2022,
    OT_SLP_USDC_WETH_29_DEC_2022: main.TOKEN_OT_SLP_USDC_WETH_29_DEC_2022,
    OT_WXBTRFLY_21_APR_2022: main.TOKEN_OT_WXBTRFLY_21_APR_2022,
    YT_AUSDC_30_DEC_2021: main.TOKEN_YT_AUSDC_30_DEC_2021,
    YT_AUSDC_29_DEC_2022: main.TOKEN_YT_AUSDC_29_DEC_2022,
    YT_CDAI_30_DEC_2021: main.TOKEN_YT_CDAI_30_DEC_2021,
    YT_CDAI_29_DEC_2022: main.TOKEN_YT_CDAI_29_DEC_2022,
    YT_SLP_PENDLE_WETH_29_DEC_2022: main.TOKEN_YT_SLP_PENDLE_WETH_29_DEC_2022,
    YT_SLP_USDC_WETH_29_DEC_2022: main.TOKEN_YT_SLP_USDC_WETH_29_DEC_2022,
    YT_WXBTRFLY_21_APR_2022: main.TOKEN_YT_WXBTRFLY_21_APR_2022,
    SLP_PENDLE_WETH: main.TOKEN_SLP_PENDLE_WETH,
    SLP_USDC_WETH: main.TOKEN_SLP_USDC_WETH,
  },
  POOLS: {
    OT_SLP_PENDLE_WETH_29_DEC_2022_X_PENDLE: main.POOL_OT_SLP_PENDLE_WETH_29_DEC_2022_X_PENDLE,
    OT_SLP_USDC_WETH_29_DEC_2022_X_USDC: main.POOL_OT_SLP_USDC_WETH_29_DEC_2022_X_USDC,
    OT_AUSDC_30_DEC_2021_X_USDC: main.POOL_OT_AUSDC_30_DEC_2021_X_USDC,
    OT_AUSDC_29_DEC_2022_X_USDC: main.POOL_OT_AUSDC_29_DEC_2022_X_USDC,
    OT_CDAI_30_DEC_2021_X_USDC: main.POOL_OT_CDAI_30_DEC_2021_X_USDC,
    OT_CDAI_29_DEC_2022_X_USDC: main.POOL_OT_CDAI_29_DEC_2022_X_USDC,
    OT_WXBTRFLY_21_APR_2022_X_USDC: main.POOL_OT_WXBTRFLY_21_APR_2022_X_USDC,
    YT_SLP_PENDLE_WETH_29_DEC_2022_X_PENDLE: main.POOL_YT_SLP_PENDLE_WETH_29_DEC_2022_X_PENDLE,
    YT_SLP_USDC_WETH_29_DEC_2022_X_USDC: main.POOL_YT_SLP_USDC_WETH_29_DEC_2022_X_USDC,
    YT_AUSDC_30_DEC_2021_X_USDC: main.POOL_YT_AUSDC_30_DEC_2021_X_USDC,
    YT_AUSDC_29_DEC_2022_X_USDC: main.POOL_YT_AUSDC_29_DEC_2022_X_USDC,
    YT_CDAI_30_DEC_2021_X_USDC: main.POOL_YT_CDAI_30_DEC_2021_X_USDC,
    YT_CDAI_29_DEC_2022_X_USDC: main.POOL_YT_CDAI_29_DEC_2022_X_USDC,
    YT_WXBTRFLY_21_APR_2022_X_USDC: main.POOL_YT_WXBTRFLY_21_APR_2022_X_USDC,
  },
  LIQ: {
    OT_SLP_PENDLE_WETH_29_DEC_2022_X_PENDLE: main.LIQ_OT_SLP_PENDLE_WETH_29_DEC_2022_X_PENDLE,
    OT_SLP_USDC_WETH_29_DEC_2022_X_USDC: main.LIQ_OT_SLP_USDC_WETH_29_DEC_2022_X_USDC,
    OT_AUDSC_30_DEC_2021_X_USDC: main.LIQ_OT_AUSDC_30_DEC_2021_X_USDC,
    OT_AUDSC_29_DEC_2022_X_USDC: main.LIQ_OT_AUSDC_29_DEC_2022_X_USDC,
    OT_CDAI_30_DEC_2021_X_USDC: main.LIQ_OT_CDAI_30_DEC_2021_X_USDC,
    OT_CDAI_29_DEC_2022_X_USDC: main.LIQ_OT_CDAI_29_DEC_2022_X_USDC,
    YT_SLP_PENDLE_WETH_X_PENDLE: main.LIQ_YT_SLP_PENDLE_WETH_X_PENDLE,
    YT_SLP_USDC_WETH_X_USDC: main.LIQ_YT_SLP_USDC_WETH_X_USDC,
    PENDLE_AAVE_LIQUIDITY_MINING: main.PENDLE_AAVE_LIQUIDITY_MINING,
    PENDLE_COMPOUND_LIQUIDITY_MINING: main.PENDLE_COMPOUND_LIQUIDITY_MINING,
    PENDLE: '0x07282F2CEEbD7a65451Fcd268b364300D9e6D7f5',
  },
  PENDLE: {
    ROUTER: main.PENDLE_ROUTER,
    OT_SPENDER_ADDRESS: '0xd9e1ce17f2641f24ae83637ab66a2cca9c378b9f',
  },
  FORGEID: {
    AAVE_V2: main.FORGEID_AAVEV2,
    COMPOUND: 'Compound',
    COMPOUND_V2: main.FORGEID_COMPOUNDV2,
    SUSHISWAPSIMPLE: main.FORGEID_SUSHISWAPSIMPLE,
    SUSHISWAPCOMPLEX: main.FORGEID_SUSHISWAPCOMPLEX,
    REDACTED: main.FORGEID_REDACTED,
  },
  EXPIRY: {
    TIME_29_DEC_2022: main.TIME_29_DEC_2022,
    TIME_30_DEC_2021: main.TIME_30_DEC_2021,
    TIME_21_APR_2022: main.TIME_21_APR_2022,
  },
}

export const decimals = {
  DECIMALS_6: 6,
  DECIMALS_8: 8,
  DECIMALS_9: 9,
  DECIMALS_18: 18,
}
