import { sdkChainId } from '@/app-config/network'
import MarketContract from './MarketContract'
import { Market } from '@pendle/sdk'

export default class PendleMarketContract extends MarketContract {
  getSwapFeeApr() {
    return this._sdkPendleMarketMethods().getSwapFeeApr()
  }

  readMarketDetails() {
    return Promise.all([
      this._sdkPendleMarketMethods().readMarketDetails(),
      this.getPrincipalValuationPerYT(),
      this.getUnderlyingYieldRate(),
    ])
      .then(([markedDetails, principalValuationPerYT, underlyingYieldRate]) => ({
        ...markedDetails,
        otherDetails: {
          ...markedDetails.otherDetails,
          underlyingYieldRate,
          underlyingPrice: principalValuationPerYT.valuation,
        },
      }))
  }

  getPrincipalValuationPerYT() {
    return this._yieldContract().getPrincipalValuationPerYT()
  }

  getUnderlyingYieldRate() {
    return this._yieldContract().getUnderlyingYieldRate()
  }

  _sdkPendleMarketMethods() {
    const market = Market.find(this.market.address, sdkChainId)

    return market.methods({
      signer: this.signer,
      provider: this.provider,
      chainId: sdkChainId,
    })
  }

  _yieldContract() {
    const market = Market.find(this.market.address, sdkChainId)

    return market.yieldContract(sdkChainId).methods({
      signer: this.signer,
      provider: this.provider,
      chainId: sdkChainId,
    })
  }
}
