import Vue from 'vue'
import VueRouter, { RouteConfig } from 'vue-router'
import PendleAmmMarket from '@/domains/entities/PendleAmmMarket'
import PendleMarket from '@/domains/entities/PendleMarket'
import networkEnv from '@/app-config/networks'

const markets = PendleAmmMarket.query()

const defaultTradePageRedirect = (tokenAddress1: string, tokenAddress2: string) => ({
  name: 'Mint',
  params: { tokenAddress1, tokenAddress2 },
})

Vue.use(VueRouter)

const routes: Array<RouteConfig> = [
  {
    path: '/',
    name: 'Home',
    redirect: () => ({ name: 'Market' }),
    meta: {
      description:
        "With Pendle, you're in charge. You get more options to manage your yield to maximize your profits. Trade on Pendle now.",
      img: require('@/assets/images/brand/meta-bg.jpg'),
    },
  },
  {
    path: '/market',
    name: 'Market',
    component: () => import(/* webpackChunkName: "Market" */ '../views/Market.vue'),
  },
  {
    path: '/dashboard',
    name: 'Dashboard',
    component: () => import(/* webpackChunkName: "Dashboard" */ '../views/dashboard/Index.vue'),
    meta: {
      description: '',
      img: '',
    },
  },
  {
    path: '/farm',
    name: 'Farm',
    component: () => import(/* webpackChunkName: "Stake" */ '../views/stake/Index.vue'),
    meta: {
      description: '',
      img: '',
    },
  },
  {
    path: '/stake',
    name: 'Stake',
    redirect: {
      name: 'Farm',
    },
  },
  {
    path: '/farm/:inputTokenAddress',
    name: 'StakeShow',
    redirect: () => ({ name: 'StakeIndex' }),
    component: () => import(/* webpackChunkName: "StakeShow" */ '../views/stake/Show.vue'),
    children: [
      {
        path: 'stake',
        name: 'StakeIndex',
        component: () =>
          import(/* webpackChunkName: "StakeIndex" */ '../views/stake/stake/Index.vue'),
        meta: {
          title: 'Stake',
          description: '',
          img: '',
        },
      },
      {
        path: 'liquidity',
        name: 'Liquidity',
        component: () =>
          import(/* webpackChunkName: "Liquidity" */ '../views/stake/liquidity/Index.vue'),
        redirect: (to) => ({ name: 'AddLiquidity', params: to.params }),
        children: [
          {
            path: 'add',
            name: 'AddLiquidity',
            component: () =>
              import(
                /* webpackChunkName: "AddLiquidity" */ '../views/stake/liquidity/add/Index.vue'
              ),
            redirect: (to) => ({ name: 'AddDualAssets', params: to.params }),
            children: [
              {
                path: 'single-asset',
                name: 'AddSingleAsset',
                component: () =>
                  import(
                    /* webpackChunkName: "AddSingleAsset" */ '../views/stake/liquidity/add/SingleAsset.vue'
                  ),
                meta: {
                  title: 'Add Liquidity',
                },
              },
              {
                path: 'dual-assets',
                name: 'AddDualAssets',
                component: () =>
                  import(
                    /* webpackChunkName: "AddDualAssets" */ '../views/stake/liquidity/add/DualAssets.vue'
                  ),
                meta: {
                  title: 'Add Liquidity',
                },
              },
            ],
          },
          {
            path: 'remove',
            name: 'RemoveLiquidity',
            component: () =>
              import(
                /* webpackChunkName: "RemoveLiquidity" */ '../views/stake/liquidity/remove/Index.vue'
              ),
            redirect: (to) => ({ name: 'RemoveDualAssets', params: to.params }),
            children: [
              {
                path: 'single-asset',
                name: 'RemoveSingleAsset',
                component: () =>
                  import(
                    /* webpackChunkName: "RemoveSingleAsset" */ '../views/stake/liquidity/remove/SingleAsset.vue'
                  ),
                meta: {
                  title: 'Remove Liquidity',
                },
              },
              {
                path: 'dual-assets',
                name: 'RemoveDualAssets',
                component: () =>
                  import(
                    /* webpackChunkName: "RemoveDualAssets" */ '../views/stake/liquidity/remove/DualAssets.vue'
                  ),
                meta: {
                  title: 'Remove Liquidity',
                },
              },
            ],
          },
        ],
      },
    ],
  },
  {
    path: '/trade',
    name: 'Trade',
    redirect: defaultTradePageRedirect(markets[0].pair[0], markets[0].pair[1]),
  },
  {
    path: '/trade/:tokenAddress1',
    name: 'TradeShowImproper',
    redirect: defaultTradePageRedirect(markets[0].pair[0], markets[0].pair[1]),
  },
  {
    path: '/trade/:tokenAddress1/:tokenAddress2',
    name: 'TradeShow',
    component: () => import(/* webpackChunkName: "TradeShow" */ '../views/trade/Show.vue'),
    meta: {
      title: 'Trade',
      description: '',
      img: '',
    },
    redirect: (to) => ({ name: 'Mint', params: to.params }),
    children: [
      {
        path: 'mint',
        name: 'MintIndex',
        component: () =>
          import(/* webpackChunkName: "MintIndex" */ '../views/trade/mint/Index.vue'),
        redirect: (to) => ({ name: 'Mint', params: to.params }),
        children: [
          {
            path: 'mint',
            name: 'Mint',
            component: () => import(/* webpackChunkName: "Mint" */ '../views/trade/mint/Mint.vue'),
          },
          {
            path: 'redeem',
            name: 'Redeem',
            component: () =>
              import(/* webpackChunkName: "Redeem" */ '../views/trade/mint/Redeem.vue'),
          },
        ],
      },
      {
        path: 'swap',
        name: 'Swap',
        component: () => import(/* webpackChunkName: "Swap" */ '../views/trade/Swap.vue'),
      },
    ],
  },
  {
    path: '*',
    name: 'NotFound',
    component: () => import(/* webpackChunkName: "NotFound" */ '../views/NotFound.vue'),
    meta: {
      title: '404 Not Found',
      description: '',
      img: '',
    },
  },
]

// avalanche specific routes, Avalanche chain id = 43114
if (networkEnv.VUE_APP_NETWORK_ID === 43114) {
  const avaxRoutes = [
    {
      path: '/zap',
      name: 'Zap',
      component: () => import(/* webpackChunkName: "Zap" */ '../views/zap/Index.vue'),
    },
    {
      path: '/zap/:yieldBearingAsset/:action/:inputType',
      name: 'ZapShow',
      component: () => import(/* webpackChunkName: "ZapShow" */ '../views/zap/Show.vue'),
      meta: {
        title: 'Zap',
      },
    },
  ]

  routes.splice(-1, 0, ...avaxRoutes)
}

const router = new VueRouter({
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
})

const metaAttrs = {
  title: ['', 'og:', 'twitter:', 'apple-mobile-web-app-'],
  description: ['', 'og:', 'twitter:'],
  img: ['og:', 'twitter:'],
}
router.beforeEach((to, from, next) => {
  // @ts-ignore
  const pageTitle = [to.meta.title || to.name, 'Pendle'].filter((s) => s).join(' | ')
  document.title = pageTitle

  Object.keys(metaAttrs).forEach((attr) => {
    // @ts-ignore
    metaAttrs[attr].forEach((prepend) => {
      const metaName = [prepend, attr].join('')
      // @ts-ignore
      const content = attr === 'title' ? pageTitle : to.meta[attr] || routes[0].meta[attr]
      document.querySelector(`meta[name="${metaName}"]`)?.setAttribute('content', content)
    })
  })

  next()
})

router.beforeEach((to, from, next) => {
  // If the market is expired prevent navigating to 'AddDualAssets', 'AddSingleAsset', 'RemoveSingleAsset'
  // Only 'RemoveDualAssets' is possible
  if (
    to.name !== 'AddDualAssets' &&
    to.name !== 'AddSingleAsset' &&
    to.name !== 'RemoveSingleAsset'
  ) {
    return next()
  }

  const market =
    PendleAmmMarket.query().findBy('address', to.params.inputTokenAddress) ||
    PendleMarket.query().findBy('address', to.params.inputTokenAddress)

  if (market.statusIs('inactive')) {
    return next({ name: 'RemoveDualAssets', params: to.params })
  }

  next()
})

router.afterEach((to, from) => {
  //  Track a specific Crazy Egg snapshot by name
  // eslint-disable-next-line
  var CE_SNAPSHOT_NAME = to.name
})

export default router
