export const AVALANCHE_MAINNET_PARAMS = {
  chainId: '0xA86A',
  chainName: 'Avalanche Mainnet C-Chain',
  nativeCurrency: {
    name: 'Avalanche',
    symbol: 'AVAX',
    decimals: 18,
  },
  rpcUrls: ['https://api.avax.network/ext/bc/C/rpc'],
  blockExplorerUrls: ['https://snowtrace.io/'],
}

export const ETH_FLASHBOT_PARAMS = {
  chainId: '0x1',
  chainName: 'Ethereum Flashbot',
  nativeCurrency: {
    name: 'Ether',
    symbol: 'ETH',
    decimals: 18,
  },
  rpcUrls: ['https://etherscan.io/'],
  blockExplorerUrls: ['https://etherscan.io/'],
}

export const ETH_FORK_PARAMS = {
  chainId: '0x7A69',
  chainName: 'ETH Fork',
  nativeCurrency: {
    name: 'Ether',
    symbol: 'ETH',
    decimals: 18,
  },
  rpcUrls: ['https://etherscan.io/'],
  blockExplorerUrls: ['https://etherscan.io/'],
}

export default { AVALANCHE_MAINNET_PARAMS, ETH_FORK_PARAMS }
