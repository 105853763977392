import MarketCollection from './MarketCollection'
import PendleAmmMarket from '@/domains/entities/PendleAmmMarket'
import YT from '@/domains/entities/YT'
import Token from '@/domains/entities/Token'
import markets from '@/app-config/markets'

export default class PendleAmmMarketCollection extends MarketCollection {
  static all() {
    return new PendleAmmMarketCollection(
      ...markets
        .map(
          (market) =>
            new PendleAmmMarket({
              ...market,
              createdEpoch: market.created,
              expiryEpoch: market.expires,
              amms: market.tradablePlatforms,
              address: market.address.toLowerCase(),
              pair: market.pair.map((addr) => addr.toLowerCase()),
              tokenPair: market.pair.map((tokenAddr) => Token.query().find(tokenAddr)),
              expiresAt: new Date(market.expires * 1000),
              createdAt: new Date(market.created * 1000),
              pool: Token.query().find(market.address),
            })
        )
        .filter((market) => market.amms?.includes('pendle'))
        .filter((market) => YT.query().findBy('address', market.pair))
    )
  }

  get otMarkets() {
    return this.filter((market) =>
      market.tokenPair.some((token) => token.tokenCategories.includes('ot'))
    )
  }
}
