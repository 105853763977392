<template>
  <component
    :is="tag"
    class="base-button"
    v-bind="{ type: 'button', ...$attrs }"
    v-on="$listeners"
  >
    <slot></slot>
  </component>
</template>

<script>
export default {
  name: 'BaseButton',
  props: {
    tag: { type: String, default: 'button' },
  },
}
</script>
