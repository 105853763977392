<template>
  <input
    type="tel"
    ref="input"
    v-bind="$attrs"
    v-on="{
      ...$listeners,
      keydown: handleKeydown,
      paste: handlePaste,
      input: (event) => handleEmitInput(event.target.value),
    }"
    :value="value"
  />
</template>

<script>
  export default {
    props: {
      maximumDecimal: { type: Number, default: 0 },
      value: { type: String, default: '' },
    },
    methods: {
      handlePaste(event) {
        const clipboardData = event.clipboardData.getData('text/plain')

        const parsedVal = this.maximumDecimal ? parseFloat(clipboardData) : parseInt(clipboardData)

        this.handleEmitInput((parsedVal.toFixed(18) || '').toString())
        event.preventDefault()
        this.$emit('paste', event)
      },
      handleKeydown(event) {
        const allowedChar = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9].map((num) => num.toString())
        if (this.maximumDecimal) allowedChar.push('.')

        if (event.ctrlKey || event.key.length > 1) return

        const isAllowed = allowedChar.some((num) => num === event.key)
        if (!isAllowed) event.preventDefault()
        this.$emit('keydown', event)
      },
      formatValue(value) {
        let formattedValue = value
          .split('.')
          .slice(0, this.maximumDecimal ? 2 : 1)
          .join('.')

        const [wholeNum, decimal] = formattedValue.split('.')

        if (decimal) {
          formattedValue = [wholeNum, decimal.slice(0, this.maximumDecimal)].join('.')
        }

        return formattedValue
      },
      handleEmitInput(value) {
        const formattedValue = this.formatValue(value)
        this.$refs.input.value = formattedValue
        this.$emit('input', formattedValue)
      },
    },
  }
</script>
