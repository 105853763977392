import { mainnet } from '../../constants'

const pools = mainnet.POOLS
const tokens = mainnet.TOKENS
const expiry = mainnet.EXPIRY
const pendle = mainnet.PENDLE

export default [
  {
    address: pools.OT_SLP_PENDLE_WETH_29_DEC_2022_X_PENDLE,
    pair: [tokens.OT_SLP_PENDLE_WETH_29_DEC_2022, tokens.PENDLE],
    created: 1629205198, // 18 Aug 2021
    expires: expiry.TIME_29_DEC_2022,
    tradablePlatforms: ['sushi'],
    network: 'mainnet',
    spenderAddress: pendle.OT_SPENDER_ADDRESS,
  },
  {
    address: pools.OT_SLP_USDC_WETH_29_DEC_2022_X_USDC,
    pair: [tokens.OT_SLP_USDC_WETH_29_DEC_2022, tokens.USDC],
    created: 1629205073, // 18 Aug 2021
    expires: expiry.TIME_29_DEC_2022,
    tradablePlatforms: ['sushi'],
    network: 'mainnet',
    spenderAddress: pendle.OT_SPENDER_ADDRESS,
  },
  {
    address: pools.OT_AUSDC_30_DEC_2021_X_USDC,
    pair: [tokens.OT_AUSDC_30_DEC_2021, tokens.USDC],
    created: 1625560609, // 6 July 2021
    expires: expiry.TIME_30_DEC_2021,
    tradablePlatforms: ['sushi'],
    network: 'mainnet',
    spenderAddress: pendle.OT_SPENDER_ADDRESS,
  },
  {
    address: pools.OT_AUSDC_29_DEC_2022_X_USDC,
    pair: [tokens.OT_AUSDC_29_DEC_2022, tokens.USDC],
    created: 1623748371, // 15 June 2021
    expires: expiry.TIME_29_DEC_2022,
    tradablePlatforms: ['sushi'],
    network: 'mainnet',
    spenderAddress: pendle.OT_SPENDER_ADDRESS,
  },
  {
    address: pools.OT_CDAI_30_DEC_2021_X_USDC,
    pair: [tokens.OT_CDAI_30_DEC_2021, tokens.USDC],
    created: 1625568774, // 6 July 2021
    expires: expiry.TIME_30_DEC_2021,
    tradablePlatforms: ['sushi'],
    network: 'mainnet',
    spenderAddress: pendle.OT_SPENDER_ADDRESS,
  },
  {
    address: pools.OT_CDAI_29_DEC_2022_X_USDC,
    pair: [tokens.OT_CDAI_29_DEC_2022, tokens.USDC],
    created: 1623884729, // 15 June 2021
    expires: expiry.TIME_29_DEC_2022,
    tradablePlatforms: ['sushi'],
    network: 'mainnet',
    spenderAddress: pendle.OT_SPENDER_ADDRESS,
  },
  {
    address: pools.OT_WXBTRFLY_21_APR_2022_X_USDC,
    pair: [tokens.OT_WXBTRFLY_21_APR_2022, tokens.USDC],
    created: 1645353082, // 20 Feb 2022
    expires: expiry.TIME_21_APR_2022,
    tradablePlatforms: ['sushi'],
    network: 'mainnet',
    spenderAddress: pendle.OT_SPENDER_ADDRESS,
  },
]
