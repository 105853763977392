import { YieldContract as SdkYieldContract, Token as SdkToken } from '@pendle/sdk'
import { sdkChainId } from '@/app-config/network'

export default class TokenIssuerContract {
  constructor({ signer, provider, tokenIssuer }) {
    this.signer = signer
    this.provider = provider
    this.tokenIssuer = tokenIssuer
  }

  mintDetails(mintAmount) {
    return this.#sdkYieldContractMethods().mintDetails(mintAmount)
  }

  mint(mintAmount) {
    return new Promise((resolve, reject) => {
      this.#sdkYieldContractMethods()
        .mint(mintAmount)
        .then((response) => response.wait(1).then(() => setTimeout(() => resolve(response), 2000)))
        .catch((error) => reject(error))
    })
  }

  redeemDetails(otAmount, userAddress) {
    return this.#sdkYieldContractMethods().redeemDetails(otAmount, userAddress)
  }

  redeem(otAmount) {
    return new Promise((resolve, reject) => {
      this.#sdkYieldContractMethods()
        .redeem(otAmount)
        .then((response) => response.wait(1).then(() => setTimeout(() => resolve(response), 2000)))
        .catch((error) => reject(error))
    })
  }

  #sdkYieldContractMethods() {
    const { expiryEpoch, forgeId, underlyingToken } = this.tokenIssuer
    const yieldContract = new SdkYieldContract(
      forgeId,
      new SdkToken(underlyingToken.address, underlyingToken.decimals),
      expiryEpoch,
      sdkChainId
    )

    return yieldContract.methods({
      signer: this.signer,
      provider: this.provider,
      chainId: sdkChainId,
    })
  }
}
