<template>
  <div class="flex items-center px-2">
    <div>
      <router-link to="/">
        <img
          class="h-10 w-10"
          :src="require('@/assets/brand/brandmark.svg')"
          title="Pendle"
          alt="Pendle's logo"
        />
      </router-link>
    </div>

    <div class="flex-grow"></div>

    <div class="space-x-3 flex items-center text-p-gray-300">
      <router-link
        v-for="link in internalLinks"
        :key="link.title"
        :to="link.url"
        v-text="link.title"
        class="
          text-sm
          sm:text-base
          inline-block
          text-water-500
          hover:text-water-700
          focus:text-water-700
          transition
        "
        :class="$route.path.includes(link.url) ? '!text-water-800 font-bold' : ''"
      ></router-link>
    </div>
  </div>
</template>

<script>
  export default {
    props: {
      internalLinks: { type: Array, required: true },
    },
    computed: {
      tradeLinks() {
        return this.internalLinks.filter((link) => link.tradePage)
      },
    },
  }
</script>
