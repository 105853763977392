import Vue from 'vue'

const localStorageKey = 'notifications-cache'

const readCache = () => {
  const cache = localStorage.getItem(localStorageKey)
  let notifications

  try {
    notifications = JSON.parse(cache) || []
  } catch {
    notifications = []
  }

  return notifications
}

const setCache = (data) => {
  localStorage.setItem(localStorageKey, JSON.stringify(data))
}

class NotificationContainer {
  constructor() {
    this.reactive = Vue.observable({
      notifications: readCache(),
    })
  }

  // const action = { text, url }

  success({ title, text, action = {} }) {
    this._addNotification({
      title,
      text,
      action,
      category: 'success',
    })
  }

  error({ title, text, action = {} }) {
    this._addNotification({
      title,
      text,
      action,
      category: 'error',
    })
  }

  info({ title, text, action = {} }) {
    this._addNotification({
      title,
      text,
      action,
      category: 'info',
    })
  }

  clear(id) {
    this.reactive.notifications = this.reactive.notifications.filter(
      (notification) => notification.id !== id
    )
    this._updateCache()
  }

  clearAll() {
    this.reactive.notifications = []
    this._updateCache()
  }

  _addNotification({ title, text, action = {}, category = 'info' }) {
    this.reactive.notifications = [
      {
        title,
        text,
        action,
        category,
        id: `${new Date().getTime()}${Math.random()}`,
        createdAt: new Date().toISOString(),
      },
      ...this.reactive.notifications.slice(0, 9),
    ]

    this._updateCache()
  }

  _updateCache() {
    setCache(this.reactive.notifications)
  }
}

export default NotificationContainer
