import { mainnet, decimals } from '../../constants'

const tokens = mainnet.TOKENS

export default [
  {
    address: tokens.USDC,
    decimals: decimals.DECIMALS_6,
    name: 'USDC',
    symbol: 'USDC',
    logo: '/images/coins/USDC.svg',
    network: 'mainnet',
    genericName: 'USDC',
    tokenCategories: ['generic'],
  },
  {
    address: tokens.AUSDC,
    decimals: decimals.DECIMALS_6,
    name: 'Aave-v2 USDC',
    symbol: 'aUSDC',
    logo: '/images/coins/ausdc.svg',
    network: 'mainnet',
    genericName: 'yieldBearing',
    protocol: 'Aave-v2',
    sources: [
      `https://app.aave.com/#/deposit/${tokens.USDC}-0xa0b86991c6218b36c1d19d4a2e9eb0ce3606eb480xb53c1a33016b2dc2ff3653530bff1848a515c8c5`,
    ],
  },
  {
    address: tokens.DAI,
    decimals: decimals.DECIMALS_18,
    name: 'DAI',
    symbol: 'DAI',
    logo: '/images/coins/DAI.png',
    network: 'mainnet',
    genericName: 'DAI',
    tokenCategories: ['generic'],
  },
  {
    address: tokens.CDAI,
    decimals: decimals.DECIMALS_8,
    name: 'Compound DAI',
    symbol: 'cDAI',
    logo: '/images/coins/cdai.svg',
    network: 'mainnet',
    genericName: 'yieldBearing',
    protocol: 'Compound',
    sources: ['https://app.compound.finance/'],
  },
  {
    address: tokens.STKAAVE,
    decimals: decimals.DECIMALS_18,
    name: 'stkAAVE',
    symbol: 'stkAAVE',
    logo: '/images/coins/stkaave.svg',
    network: 'mainnet',
    genericName: 'stkAAVE',
    tokenCategories: ['generic'],
    protocol: 'Aave-v2',
  },
  {
    address: tokens.SUSHI,
    decimals: decimals.DECIMALS_18,
    name: 'SUSHI',
    symbol: 'SUSHI',
    logo: '/images/coins/sushi.svg',
    network: 'mainnet',
    genericName: 'SUSHI',
    tokenCategories: ['generic'],
    protocol: 'Sushiswap',
  },
  {
    address: tokens.COMP,
    decimals: decimals.DECIMALS_18,
    name: 'COMP',
    symbol: 'COMP',
    logo: '/images/coins/comp.svg',
    network: 'mainnet',
    genericName: 'COMP',
    protocol: 'Compound',
    tokenCategories: ['generic'],
  },
  {
    address: tokens.PENDLE,
    decimals: decimals.DECIMALS_18,
    name: 'PENDLE',
    symbol: 'PENDLE',
    logo: '/images/coins/pendle.svg',
    network: 'mainnet',
    genericName: 'PENDLE',
    tokenCategories: ['generic'],
    protocol: 'Pendle',
    externalImg: 'https://app.pendle.finance/pendle_token.png',
    sources: [`https://app.sushi.com/swap?outputCurrency=${tokens.PENDLE}`],
  },
  {
    address: tokens.ETH,
    decimals: decimals.DECIMALS_18,
    name: 'Ethereum',
    symbol: 'ETH',
    logo: '/images/coins/ETH.png',
    network: 'mainnet',
    genericName: 'ETH',
    tokenCategories: ['generic'],
    ignoreApproval: ['all'],
  },
  {
    address: tokens.WETH,
    decimals: decimals.DECIMALS_18,
    name: 'Wrapped Ether',
    symbol: 'WETH',
    logo: '/images/coins/ETH.png',
    network: 'mainnet',
    genericName: 'WETH',
    tokenCategories: ['generic'],
  },
  {
    address: tokens.XBTRFLY,
    decimals: decimals.DECIMALS_9,
    name: 'xBTRFLY',
    symbol: 'xBTRFLY',
    logo: '/images/coins/mainnet/wxbtrfly.png',
    network: 'mainnet',
    genericName: 'xBTRFLY',
    protocol: 'Redacted Cartel',
    tokenCategories: ['generic'],
  },
  {
    address: tokens.WXBTRFLY,
    decimals: decimals.DECIMALS_18,
    name: 'wxBTRFLY',
    symbol: 'wxBTRFLY',
    logo: '/images/coins/mainnet/wxbtrfly.png',
    network: 'mainnet',
    genericName: 'wxBTRFLY',
    protocol: 'Redacted Cartel',
    tokenCategories: ['generic'],
  },
]
