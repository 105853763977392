<template>
  <div class="py-2">
    <div class="bg-p-blue-50 shadow-pendle rounded-xl">
      <ul class="py-2 px-1">
        <li v-for="social in socials" :key="social.title" class="p-1">
          <a
            class="
              py-1
              px-3
              text-sm
              flex-grow flex
              items-center
              rounded-lg
              hover:bg-p-blue-200
              focus:bg-p-blue-200 focus:outline-none
              transition-colors
              duration-150
              ease-in-out
            "
            :href="social.link"
            target="_blank"
          >
            <component :is="social.icon" class="h-7 w-7 text-p-blue-500 fill-current"></component>

            <span class="ml-3 inline-block" v-text="social.title"></span>
          </a>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
  import DocsIcon from '@/components/svg/DocsIcon'
  import TwitterIcon from '@/components/svg/TwitterIcon'
  import DiscordIcon from '@/components/svg/DiscordIcon'
  import MediumIcon from '@/components/svg/MediumIcon'
  import TelegramIcon from '@/components/svg/TelegramIcon'
  import GithubIcon from '@/components/svg/GithubIcon'
  import OlympusIcon from '@/components/svg/OlympusIcon.vue'

  export default {
    components: {
      DocsIcon,
      TwitterIcon,
      DiscordIcon,
      MediumIcon,
      TelegramIcon,
      GithubIcon,
      OlympusIcon,
    },
    data() {
      return {
        socials: [
          {
            title: 'Docs',
            icon: 'docs-icon',
            link: 'https://docs.pendle.finance',
          },
          {
            title: 'Twitter',
            icon: 'twitter-icon',
            link: 'https://twitter.com/pendle_fi',
          },
          {
            title: 'Discord',
            icon: 'discord-icon',
            link: 'https://discord.gg/EAujvncY2R',
          },
          {
            title: 'Medium',
            icon: 'medium-icon',
            link: 'https://medium.com/pendle',
          },
          {
            title: 'Telegram',
            icon: 'telegram-icon',
            link: 'https://t.me/pendlefinance',
          },
          {
            title: 'GitHub',
            icon: 'github-icon',
            link: 'https://github.com/pendle-finance',
          },
          {
            title: 'Bond',
            icon: 'olympus-icon',
            link: 'https://pro.olympusdao.finance/#/bond/pendle_eth_sushi',
          },
        ],
      }
    },
  }
</script>
