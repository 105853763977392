<template>
  <pp-fade-transition @after-enter="showContent = true" @after-leave="$emit('input', false)">
    <div
      v-if="showSelf"
      tabindex="-1"
      role="document"
      class="base-dialog--overlay"
      :class="overlayClass"
    >
      <button
        @click="showContent = false"
        tabindex="-1"
        class="fixed inset-0 h-full w-full focus:outline-none"
      ></button>

      <pp-scale-transition @after-leave="showSelf = false" @after-enter="attemptFocus">
        <div
          v-if="showContent"
          ref="contentHolder"
          @keydown.esc="showContent = false"
          @keydown.tab="handleTab"
          tabindex="-1"
          class="base-dialog--content-holder flex flex-grow"
          :class="contentHolderClass"
          :style="{ width, maxHeight, maxWidth }"
        >
          <slot :toggle="toggle"></slot>
        </div>
      </pp-scale-transition>
    </div>
  </pp-fade-transition>
</template>

<script>
  // Body Overflow should be managed by a global wrapping component because the global state watches over all opened dialogs.
  // In this case, By default, it is default.vue

  import { focusNext, focusPrev, focusablesWithin } from '@/utils/focus-trap'
  const appendToDialogs = (dialogs, val) => {
    const index = dialogs.findIndex((dialog) => dialog._uid === val._uid)
    return index > -1 ? dialogs : [...dialogs, val]
  }

  const removeFromDialogs = (dialogs, val) => dialogs.filter((dialog) => dialog._uid !== val._uid)

  export default {
    name: 'BaseDialog',
    props: {
      value: { type: Boolean, default: false },
      transition: { type: Object, default: () => ({ name: 'scale' }) },
      autofocus: { type: Boolean, default: false },
      width: { type: String, default: '600px' },
      maxHeight: { type: String, default: '90%' },
      maxWidth: { type: String, default: '90%' },
      removeCenter: { type: Boolean, default: false },
      contentHolderClass: { type: String, default: '' },
      overlayClass: { type: String, default: '' },
    },
    data() {
      return {
        showSelf: false,
        showContent: false,
      }
    },
    watch: {
      value: {
        immediate: true,
        handler(val) {
          const { openedDialogs } = this.$app.state
          if (val) {
            this.showSelf = val
            this.$app.state.openedDialogs = appendToDialogs(openedDialogs, this)
          } else {
            this.showContent = val
            this.$app.state.openedDialogs = removeFromDialogs(openedDialogs, this)
          }
        },
      },
      showSelf: {
        immediate: true,
        handler(newVal) {
          document.body.style.overflow = newVal ? 'hidden' : ''
        },
      },
    },
    methods: {
      handleTab() {
        if (!this.isActive) return

        const focusables = this.focusables()
        const currentFocusableIndex = focusables.indexOf(document.activeElement)
        const nextTarget = event.shiftKey
          ? focusNext(focusables, currentFocusableIndex)
          : focusPrev(focusables, currentFocusableIndex)

        event.preventDefault()
        focusables[nextTarget]?.focus()
      },
      attemptFocus() {
        this.$nextTick(() => {
          const focusables = this.focusables()

          focusables.length > 0 ? focusables[0].focus() : this.$refs.contentHolder.focus()
        })
      },
      focusables() {
        return focusablesWithin(this.$refs.contentHolder)
      },
      toggle() {
        const state = [true, false].includes(arguments[0]) ? arguments[0] : !this.showSelf

        if (state) {
          this.showSelf = state
        } else {
          this.showContent = state
        }
      },
    },
    computed: {
      isActive() {
        const { openedDialogs } = this.$app.state
        return openedDialogs[openedDialogs.length - 1]._uid === this._uid
      },
    },
  }
</script>
