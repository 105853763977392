import MarketCollection from './MarketCollection'
import PendleMarket from '@/domains/entities/PendleMarket'
import Token from '@/domains/entities/Token'
import markets from '@/app-config/markets'

export default class PendleMarketCollection extends MarketCollection {
  static all() {
    return new PendleMarketCollection(
      ...markets.map(
        (market) =>
          new PendleMarket({
            ...market,
            createdEpoch: market.created,
            expiryEpoch: market.expires,
            amms: market.tradablePlatforms,
            address: market.address.toLowerCase(),
            pair: market.pair.map((addr) => addr.toLowerCase()),
            tokenPair: market.pair.map((tokenAddr) => Token.query().find(tokenAddr)),
            expiresAt: new Date(market.expires * 1000),
            createdAt: new Date(market.created * 1000),
            pool: Token.query().find(market.address),
          })
      )
    )
  }

  get otMarkets() {
    return this.filter((market) =>
      market.tokenPair.some((token) => token.tokenCategories.includes('ot'))
    )
  }
}
