import Vue from 'vue'
import AppState from '@/states/app'
import NotificationContainer from '@/domains/entities/NotificationContainer'
// import WalletState from '@/states/wallet'

Vue.prototype.$app = AppState
Vue.prototype.$log = console.log
Vue.prototype.$notification = new NotificationContainer()

setInterval(() => (AppState.state.time = new Date()), 2000)
// Vue.prototype.$wallet = WalletState
