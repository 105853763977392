export default {
  fill: {
    inactive:
      'font-normal text-water-900 disabled:text-gray-400 disabled:bg-transparent hover:bg-water-200 focus:bg-water-200 focus:ring-water-500',
    active:
      'bg-water-700 text-white shadow-sm disabled:bg-gray-200 disabled:text-gray-400 focus:ring-0',
  },
  text: {
    inactive:
      'text-water-600 focus:text-water-600 hover:bg-water-200 focus:bg-water-200 disabled:text-gray-400 disabled:bg-transparent focus:ring-water-500',
    active: 'text-water-800 border-water-800 disabled:text-gray-400 focus:ring-0',
  },
  accent: {
    inactive:
      'text-water-900 bg-water-100 disabled:text-gray-400 disabled:bg-transparent hover:bg-water-200 focus:bg-water-200 focus:ring-water-500',
    active:
      'bg-water-700 text-white shadow-sm disabled:bg-gray-200 disabled:text-gray-400 focus:ring-0',
  },
}
